<template>
  <div>
    <!-- step 1 -->
      <Base-modal ref="refModalAgree" :modalOverlay="true" type="full-screen"  @exit="backStart()" :modal="true" :headingTitle="$t('Pactar')">
        <template #content>
            <div class="mx-2 flex flex-col justify-center sm:justify-start flex-wrap">
                <!-- informacion del cliente -->
                <div v-if="!objEmpty(agreement)" class="p-4  border border-gray-200 rounded-lg flex flex-col hover:border-gray-400">
                    <div class="text-black font-bold  text-lg mb-2">
                      {{$t('Informacion del pactado')}}
                    </div>
                    <div>
                        <div class=" flex flex-row flex-wrap py-1">
                            <div class="mr-1">
                                  <span class="font-normal">
                                   {{$t('Quien entrega')}}:
                                  </span>
                                  <span class="text-gray-600">
                                  {{agreement.who_delivers}}
                                  </span>
                            </div>
                            <div class="mx-1">
                                <span class="font-normal"> {{$t('Identificacion')}}: </span> <span class="text-gray-600">
                                  {{agreement.identificacion}}
                                  </span>
                            </div>
                        </div>
                        <div class=" flex flex-row flex-wrap py-1">
                            <div class="mr-1">
                                <span class="font-normal"> {{$t('Codigo postal')}}: </span> <span class="text-gray-600">
                                  {{agreement.postalCode}}</span>
                            </div>
                            <div class="mx-1">
                                <span class="font-normal"> {{$t('Provincia')}}: </span> <span class="text-gray-600">
                                  {{agreement.province.name}}</span>
                            </div>
                        </div>
                        <div class=" flex flex-row flex-wrap py-1">
                            <div class="mr-1">
                                <span class="font-normal"> {{$t('Localidad')}}: </span> <span class="text-gray-600">
                                  {{agreement.locality.name}}</span>
                            </div>
                            <div class="mx-1">
                                <span class="font-normal"> {{$t('Direccion')}}: </span> <span class="text-gray-600">
                                  {{agreement.street }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- buscar direccion en mapa -->
                <div class="p-4 mt-4 my-2 border border-gray-200 rounded-lg flex flex-col hover:border-gray-400 ">
                  <div class="flex flex-row justify-between items-center">
                    <span class="text-black font-bold  text-lg"> {{ $t('Ubicar dirección en el mapa') }} </span>
                    <span class="text-gray-500 text-sm">{{$t('Es importante esta información para llegar correctamente al domicilio')}}</span>
                  </div>
                  <div :class="['my-4 mx-auto sm:mx-0 ', emptyGeocoded ? 'w-72' : 'w-48']">
                    <BaseButton
                    icon="map"
                    iconColor="text-white"
                    textColor="text-white"
                    :background="emptyGeocoded ? 'bg-black' : 'bg-indigo-600 hover:bg-indigo-500'"
                    :text="!emptyGeocoded ? $t('Ver direccion') : $t('Buscar direccion en el mapa')"
                    @click="openGeocoded()"
                    />
                  </div>
                  <div v-if="!emptyGeocoded" class="p-4 my-2 border border-gray-200 rounded-lg flex flex-col hover:border-gray-400 ">
                    <span class="text-black text-sm">{{$t('Resultados')}}</span>
                    <span class="text-gray-500 text-sm">{{$t('Direccion')}}: {{geocoded.address}}</span>
                    <span class="text-gray-500 text-sm" v-if="geocoded.locality">{{$t('Localidad')}}: {{geocoded.locality}}</span>
                    <span class="text-gray-500 text-sm" v-if="geocoded.postalCode">{{$t('Codigo postal')}}: {{geocoded.postalCode}}</span>
                    <span class="text-black font-bold mt-2 text-sm"> {{$t('Completa los datos del pactado con esta informacion')}} </span>
                  </div>
                </div>

                <!-- datos del pactado -->
                <div v-show="touchSearchMap" class="agreeData p-4 mt-2 my-2 border border-gray-200 rounded-lg flex flex-col hover:border-gray-400 ">
                  <div class="text-black font-bold  text-lg mb-2 flex flex-row justify-between items-center">
                    <div>
                       {{$t('Datos del pactado')}}
                    </div>
                    <!-- guia cp -->
                    <div>
                      <BaseButton
                        icon="search"
                        iconColor="text-gray-500"
                        textColor="text-gray-500"
                        :text="$t('Guia codigos postales')"
                        @click="modalGuideCp = true"
                         />
                    </div>
                  </div>
                  <!-- location -->
                  <div class="flex flex-row flex-wrap justify-center sm:justify-start">
                    <!-- province -->
                    <div class="mr-2 my-1">
                      <BaseSelect
                      :placeholder="$t('Provincia')"
                      :options="provinces"
                      outline="blue"
                      trackByString="name"
                      :returnAfterMatch="true"
                      v-model="agree.province.value"
                      :remove="true"
                      />
                    </div>
                    <!-- locality -->
                    <div class="mr-2 my-1">
                      <BaseSelect
                      :placeholder="$t('Localidad')"
                      :options="localities"
                      :disabled="!agree.province.value"
                      outline="blue"
                      trackByString="name"
                      :returnAfterMatch="true"
                      v-model="agree.locality.value"
                      :error="agree.locality.error"
                      @error-handle="agree.locality.error = $event"
                      :remove="true"
                      />
                    </div>
                     <!-- postalCode -->
                    <div class="mr-2 my-1">
                      <BaseSelect
                      :placeholder="$t('Codigo postal')"
                      :options="postalCodes"
                      :disabled="!agree.locality.value"
                      outline="blue"
                      trackBy="postalCode"
                      label="postalCode"
                      :returnAfterMatch="true"
                      v-model="agree.postalCode.value"
                      :error="agree.postalCode.error"
                      @error-handle="agree.postalCode.error = $event"
                      ref="refPostalCode"
                      />
                    </div>
                  </div>
                  <div class="flex flex-row flex-wrap justify-center sm:justify-start">
                    <!-- direccion -->
                    <div class="mr-2 my-1">
                      <BaseInput
                      :placeholder="$t('Calle')"
                      outline="blue"
                      v-model:modelValue="agree.street.value"
                      :error="agree.street.error"
                      @error-handle="agree.street.error = $event"
                      />
                    </div>
                    <!-- entre calles-->
                    <div class="mr-2 my-1">
                      <BaseInput
                      :placeholder="$t('Entre calles')"
                      outline="blue"
                      v-model:modelValue="agree.between_streets.value"
                      :error="agree.between_streets.error"
                      @error-handle="agree.between_streets.error = $event"
                      />
                    </div>
                    <!-- observaciones -->
                    <div class="mr-2 my-1">
                      <BaseInput
                      :placeholder="$t('Entre calles / Observación')"
                      outline="blue"
                      v-model:modelValue="agree.observations.value"
                      :error="agree.observations.error"
                      @error-handle="agree.observations.error = $event"
                      />
                    </div>
                    <!-- latitud -->
                    <div class="mr-2 my-1">
                      <BaseInput
                      :placeholder="$t('Latitud')"
                      outline="blue"
                      v-model:modelValue="agree.lat.value"
                      :error="agree.lat.error"
                      @error-handle="agree.lat.error = $event"
                      />
                    </div>
                    <!-- longitud -->
                    <div class="mr-2 my-1">
                      <BaseInput
                      :placeholder="$t('Longitud')"
                      outline="blue"
                      v-model:modelValue="agree.lng.value"
                      :error="agree.lng.error"
                      @error-handle="agree.lng.error = $event"
                      />
                    </div>
                  </div>
                </div>

                <!-- siguiente -->
                <!-- SOLO TIENES ESTE ACOPLAMIENTO DE LA RUTA AQUI, PUEDE MODIFICARLO SI TE JODE -->
                <div class="flex flex-row flex-wrap">
                    <div v-if="readyFirstStep" class="my-2 w-44 mr-2">
                    <BaseButton
                    @click="goSecondStep()"
                    :loading="loading"
                    textColor="text-white"
                    customClass="border border-gray-200"
                    padding="p-1 px-2"
                    background="bg-black"
                    :text="$t('Siguiente')" />
                  </div>
                  <div class="my-2 mr-2 w-44">
                    <BaseButton
                      @click="backStart()"
                      :text="$t('Atras')"
                    />
                  </div>
                  <div class="my-2 w-44">
                    <BaseButton
                      @click="exitAgree()"
                      background="bg-red-400"
                      textColor="text-white"
                      :text="$t('Salir y borrar datos')"
                    />
                  </div>
                </div>

            </div>
        </template>
      </Base-modal>
      <!-- geocodificar direccion -->
      <Base-modal :modalOverlay="true" size="lg"  :cache="true"  @exit="modalGeocoded = false" :modal="modalGeocoded" headingTitle="Geocodificar">
          <template #content>
              <div >
                <!-- informacion cliente -->
                <div class="overflow-auto" style="max-height:450px;">
                  <div class="mx-2">
                    <div class=" flex flex-row flex-wrap py-1">
                        <div >
                            <span class="font-normal"> {{  $t('Provincia') }}: </span> <span class="text-gray-600">{{agreement?.province?.name}}</span>
                        </div>
                    </div>
                    <div class=" flex flex-row flex-wrap py-1">
                        <div class="mr-1">
                            <span class="font-normal"> {{$t('Localidad')}}: </span> <span class="text-gray-600">{{agreement?.locality?.name}}</span>
                        </div>
                        <div class="mx-1">
                            <span class="font-normal"> {{$t('Direccion')}}: </span> <span class="text-gray-600">{{agreement?.street}}</span>
                        </div>
                    </div>
                  </div>
                  <AutoCompleteMaps :display="modalGeocoded" ref="refAutoCompleteMaps"  @setLocation="setGeocoded($event)"/>
                </div>
                  <div class="m-2 w-32">
                    <BaseButton v-if="refAutoCompleteMaps?.isGeocoded || !emptyGeocoded" @click="modalGeocoded = false" background="bg-green-400" textColor="text-white" border="none" :text="$t('Siguiente')"/>
                  </div>
              </div>
          </template>
      </Base-modal>
      <!-- guia codigos postales -->
      <Base-modal :modalOverlay="true"  :cache="true"  @exit="modalGuideCp = false" :modal="modalGuideCp" :headingTitle="$t('Guia codigos postales')">
          <template #content>
              <div class="m-2">
                <PostalCodeGuide/>
              </div>
          </template>
      </Base-modal>
      <router-view></router-view>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  ref, reactive, computed, watch, nextTick, onMounted, onUnmounted,
} from 'vue';

import AutoCompleteMaps from '@/components/Google/AutoCompleteMaps.vue';
import PostalCodeGuide from '@/components/Form/PostalCodeGuide.vue';

// utilidades
import LocationRepository from '@/repositories/LocationRepository';
import { agreementShowRepo } from '@/repositories/Core/AgreementRepository';
import locationHook from '@/hooks/core/locationHook';
import { possibleCp, possibleLocation } from '@/services/Utils/Google';
import { smoothScroll } from '@/services/Utils/Doom';
import { objEmpty, hasLocalStorage } from '@/services/Utils/Objects';

export default {
  components: {
    AutoCompleteMaps,
    PostalCodeGuide,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const refModalAgree = ref(null);
    // const refLocality = ref(null);
    const refPostalCode = ref(null);
    const refAutoCompleteMaps = ref(null);

    const agree = reactive({
      country: {
        value: null,
      },
      province: {
        value: null,
      },
      locality: {
        value: null,
        error: '',
      },
      street: {
        value: '',
        error: '',
      },
      between_streets: {
        value: '',
        error: '',
      },
      observations: {
        value: '',
        error: '',
      },
      lat: {
        value: '',
        error: '',
      },
      lng: {
        value: '',
        error: '',
      },
      postalCode: {
        value: null,
        error: '',
      },
      identificacion: {
        value: null,
      },
      original_address: {
        value: '',
      },
    });
    const agreement = computed(() => store.state.agreement.agreement);

    const getAgree = async (agreement_id, identificacion) => {
      const agreementLs = await hasLocalStorage({ key: 'agreement' });
      if (!agreementLs) {
        const response = await agreementShowRepo(agreement_id, identificacion);
        const { agreement: agreementAlias } = response;
        if (agreementAlias) {
          agree.country.value = agreementAlias.country_id;
          agree.identificacion.value = agreementAlias.identificacion;
          agree.original_address.value = agreementAlias.original_address;
          if (!store.state.agreement.agreement) {
            await store.dispatch('agreement/setAgreement', agreementAlias);
          }
          await store.dispatch('agreement/completeAgreement', agreementAlias);
        } else {
          router.push({ name: 'agreements' });
        }
      } else {
        await store.dispatch('agreement/setAgreement', agreementLs);
        await store.dispatch('agreement/tryCompleteStoreFirstStepFromLs');
        await store.dispatch('agreement/tryCompleteStoreGeocodedFromLs');
        agree.country.value = agreement.value.country_id;
        agree.identificacion.value = agreement.value.identificacion;
        agree.original_address.value = agreement.value.original_address;
      }

      return agreementLs;
    };

    const postalCodes = ref([]);
    const getPostalCodes = async () => {
      const { value } = agree.locality;
      if (!value) return;
      const response = await LocationRepository.postalCodes({ locality: value });
      postalCodes.value = response;
    };
    const modalGuideCp = ref(false);
    watch(() => agree.locality.value, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        if (newVal && typeof newVal !== 'string') {
          getPostalCodes();
        }
      }
    });

    const readyFirstStep = computed(() => {
      if (
        // eslint-disable-next-line no-use-before-define
        !agree.province.value
          || (!agree.locality.value || (agree.locality.value && typeof agree.locality.value === 'string'))
          || agree.locality.error
          || !agree.street.value
          || !agree.observations.value
          || !agree.lat.value
          || !agree.lng.value
          || !agree.postalCode.value
          || (agree.postalCode.value && typeof agree.locality.value === 'string')
          || agree.postalCode.error
      ) {
        return false;
      }
      return true;
    });

    const touchSearchMap = ref(false); // esta es una bandera para que siempre abra el mapa antes de cualquier accion
    const modalGeocoded = ref(false);
    const openGeocoded = () => {
      modalGeocoded.value = true;
      if (!touchSearchMap.value) {
        touchSearchMap.value = true;
      }
    };
    const geocoded = reactive({
      latitude: '',
      longitude: '',
      address: '',
      postalCode: '',
      locality: '',
    });
    const setGeocoded = async (data) => {
      geocoded.latitude = data.latitude;
      geocoded.longitude = data.longitude;
      geocoded.address = data.address;
      agree.street.value = data.data.name;
      agree.lat.value = data.latitude;
      agree.lng.value = data.longitude;
      geocoded.locality = await possibleLocation(data.data);
      geocoded.postalCode = await possibleCp(data.data);
      // intento autocompletar localidad y codigo postal con los datos devueltos por la geocodificacion
      if (geocoded.locality) {
        // refLocality.value.clearSearch();
        // agree.locality.value = null;
        nextTick(() => {
          agree.locality.value = geocoded.locality;
        });
      }
      if (geocoded.postalCode) {
        // agree.postalCode.value = null;
        nextTick(() => {
          agree.postalCode.value = geocoded.postalCode;
        });
      }

      // Les seteo el error porque deben autocompletarse con el string antes seteado, y si no estan autocompletados es porque no consiguio el string en al lista
      // de opciones del Baselect
      if (!agree.between_streets.value) {
        agree.between_streets.error = 'Debes completar';
      }
      if (!agree.observations.value) {
        agree.observations.error = 'Debes completar';
      }
      if (agree.locality.value && typeof agree.locality.value === 'string') {
        agree.locality.error = 'Debes completar';
      }

      if (agree.postalCode.value && typeof agree.postalCode.value === 'string') {
        agree.postalCode.error = 'Debes completar';
      }

      smoothScroll('.agreeData', nextTick, 100, refModalAgree.value.$el.lastChild);
      await store.dispatch('agreement/setGecoded', geocoded);
    };
    const emptyGeocoded = computed(() => {
      if (geocoded.latitude && geocoded.longitude && geocoded.address) {
        return false;
      }
      return true;
    });

    const chosenCountry = computed(() => agree.country.value);
    const chosenProvince = computed(() => agree.province.value);
    const { provinces, localities } = locationHook({ chosenCountry, chosenProvince });

    const loading = ref(false);
    const goSecondStep = async () => {
      // cada vez que abro el modal de equipos, seteo los datos actuales del pactado en el store
      loading.value = true;
      await store.dispatch('agreement/setFirstStep', agree);
      router.push({ name: 'agreeSecondStepEdit' });
      loading.value = false;
    };

    const backStart = () => {
      store.dispatch('agreement/removeAgree');
      router.push({ name: 'agreements' });
    };

    const completeFirstStepFromStore = () => new Promise((resolve) => {
      touchSearchMap.value = true;
      Object.keys(store.state.agreement.agree.firstStep).forEach((key) => {
        if (store.state.agreement.agree.firstStep[key].value && agree[key]) {
          agree[key].value = store.state.agreement.agree.firstStep[key].value;
        }
      });
      resolve(agree);
    });

    const completeGeocodedFromStore = () => {
      const {
        latitude, longitude, address, postalCode, locality,
      } = store.state.agreement.agree.geocoded;
      geocoded.latitude = latitude;
      geocoded.longitude = longitude;
      geocoded.address = address;
      if (postalCode) {
        geocoded.postalCode = postalCode;
      }
      if (locality) {
        geocoded.locality = locality;
      }
      if (agree.street.value) {
        refAutoCompleteMaps.value.search = agree.street.value;
      }
    };
    // BOOTED
    onMounted(async () => {
      const { agreement_id, identificacion } = route.params;
      await getAgree(agreement_id, identificacion);
      if (store.getters['agreement/completeFirstStep']) {
        await completeFirstStepFromStore();
      } else {
        await store.dispatch('agreement/tryCompleteStoreFirstStepFromLs');
      }
      if (store.getters['agreement/completeGeocoded']) {
        completeGeocodedFromStore();
      }
    });

    const exitAgree = () => {
      backStart();
      store.dispatch('agreement/removeAgree');
    };

    onUnmounted(() => {
      store.dispatch('agreement/removeAgree');
    });

    return {
      exitAgree,
      backStart,
      agreement,
      geocoded,
      modalGeocoded,
      emptyGeocoded,
      setGeocoded,
      provinces,
      localities,
      agree,
      postalCodes,
      readyFirstStep,
      modalGuideCp,
      touchSearchMap,
      openGeocoded,
      refModalAgree,
      goSecondStep,
      refAutoCompleteMaps,
      route,
      loading,
      refPostalCode,
      objEmpty,
    };
  },
};
</script>

<style>
.geocode{
  width:100%;
}
@media (min-width: 720px){
  .geocode{
    width:650px;
  }
}
@media (min-width: 800px){
  .geocode{
    width:800px;
  }
}

</style>
