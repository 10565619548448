<template>
  <div>
        <input id="searchMapInput" v-model="search" class="mapControls" type="text" :placeholder="$t(placeholder)">
        <div id="map"></div>
        <div  id="geoData" class="bg-light my-2">
            <div class="p-1"> <span id="location-snap"></span></div>
        </div>
  </div>
</template>

<script>
import {
  ref, reactive, onMounted, watch, toRefs, computed,
} from 'vue';
import { empty, objEmpty } from '@/services/Utils/Objects';

const { google } = window;

export default {
  props: {
    placeholder: {
      type: String,
      default: 'Ingrese direccion',
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { display } = toRefs(props);
    const myPosition = reactive({
      lat: '',
      lng: '',
    });

    const location = reactive({
      latitude: '',
      longitude: '',
      address: '',
    });

    const search = ref('');
    const geocoding = ref({});

    const isGeocoded = computed(() => {
      if (!objEmpty(geocoding.value)) {
        return true;
      }
      return false;
    });

    const initMap = () => {
      emit('renderingMap', true);
      const map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: myPosition.lat, lng: myPosition.lng },
        zoom: 13,
      });

      const input = document.getElementById('searchMapInput');

      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.setFields(['geometry', 'formatted_address', 'name', 'url', 'icon', 'address_components']);
      autocomplete.bindTo('bounds', map);

      const infowindow = new google.maps.InfoWindow();
      const marker = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -29),
      });

      google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
        // this part runs when the mapobject is created and rendered
        emit('renderingMap', false);
      });

      autocomplete.addListener('place_changed', () => {
        infowindow.close();
        marker.setVisible(false);
        const place = autocomplete.getPlace();
        if (!place.geometry) return;
        /* If the place has a geometry, then present it on a map. */
        if (place.geometry?.viewport) {
          map.fitBounds(place.geometry?.viewport);
        } else {
          map.setCenter(place.geometry?.location);
          map.setZoom(17);
        }
        marker.setIcon(({
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(35, 35),
        }));

        // seteo la ubicacion de la seleccion en el mapa
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        let address = '';
        if (place.address_components) {
          address = [
            ((place.address_components[0] && place.address_components[0].short_name) || ''),
            ((place.address_components[1] && place.address_components[1].short_name) || ''),
            ((place.address_components[2] && place.address_components[2].short_name) || ''),
          ].join(' ');
        }
        infowindow.setContent(`<div><strong>${place.name}</strong><br>${address}`);
        infowindow.open(map, marker);

        /* Location details */
        location.latitude = place.geometry.location.lat();
        location.longitude = place.geometry.location.lng();
        location.address = place.formatted_address;
        location.data = place;

        document.getElementById('location-snap').innerHTML = place.formatted_address;

        emit('setLocation', location);
        geocoding.value = location;
        // document.getElementById('lat-span').innerHTML = place.geometry.location.lat();
        // document.getElementById('lon-span').innerHTML = place.geometry.location.lng();
      });
    };
    const GPS = () => {
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      };
      return new Promise(((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      }));
    };
    const getMyGeolocation = async () => {
      let myCoordinates;
      await GPS()
        .then((res) => {
          myCoordinates = {
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          };
        })
        .catch((err) => {
          myCoordinates = false;
          if (err.code === 1) {
            myCoordinates = {
              lat: -34.607831971928825,
              lng: -58.41476810199054,
            };
          }
        });
      return myCoordinates;
    };

    onMounted(() => {
      if (display.value) {
        getMyGeolocation()
          .then((res) => {
            myPosition.lat = res.lat;
            myPosition.lng = res.lng;
            initMap();
          });
      }
    });

    watch(() => search.value, (newVal) => {
      if (!newVal) {
        const emptyLocation = {
          latitude: '',
          longitude: '',
          address: '',
          data: {},
        };
        document.getElementById('location-snap').innerHTML = '';
        emit('setLocation', emptyLocation);
      }
    });

    watch(() => display.value, (newVal) => {
      if (newVal) {
        // pido la geoposicion cuando el componente este visible
        // y no tenga lat ni lng, para no geoposicionar a cada rato que recargue el navegador sin necesidad
        if (empty(myPosition.lat) || empty(myPosition.lat)) {
          getMyGeolocation()
            .then((res) => {
              myPosition.lat = res.lat;
              myPosition.lng = res.lng;
              initMap();
            })
            .catch(() => {
              // coordenadas por defecto (buenos aires)
              myPosition.lat = -34.607831971928825;
              myPosition.lng = -58.41476810199054;
            });
        }
      }
    });

    return { location, search, isGeocoded };
  },

};
</script>

<style >
        #map {
            width: 100%;
            height: 400px;
        }
        .mapControls {
            margin-top: 10px;
            border: 1px solid transparent;
            border-radius: 2px 0 0 2px;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            height: 32px;
            outline: none;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        }
        #searchMapInput {
            background-color: #fff;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 300;
            margin-left: 12px;
            padding: 0 11px 0 13px;
            text-overflow: ellipsis;
            width: 50%;
        }
        #searchMapInput:focus {
            border-color: #4d90fe;
        }
    </style>
