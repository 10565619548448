<template>
  <div>
    <Base-modal
      :modalOverlay="true"
      :modal="true"
      :headingTitle="$t('Equipos pactados por cliente')"
      @exit="backFirstStep()"
      size="lg"
    >
      <template #content>
        <div class=" mx-2">
          <div class="flex flex-col mt-0">
            <!-- agregar equipos -->
            <div v-if="extraEquipments.length > 0" class="my-1 mb-2 flex flex-row flex-wrap items-center">
              <div class="w-48">
                <BaseButton
                  @click="modalMoreEquipments = true"
                  padding="py-2 px-2"
                  background="bg-indigo-600 hover:bg-indigo-500"
                  textColor="text-white"
                  icon="plus"
                  iconColor="text-white"
                  text="Agregar equipos"
                />
              </div>
              <div class="mr-2 mt-2 flex items-center">
                <span class="text-gray-500 text-sm">
                  {{ $t('El cliente puede tener mas equipos en domicilios cercanos, es recomendable agregar todos los equipos posibles al pactado. Siempre y cuando el cliente este dispuesto a entregar los otros equipos de diferentes domilicios') }}
                </span>
              </div>
            </div>
            <!-- detalles para equipos extras -->
            <div class="flex flex-row flex-wrap mb-2">
              <div :class="[`${extraEquipments.length > 0 ? 'bg-red-500' : 'border border-gray-200'}`,' px-2 py-2 rounded mt-1 sm:mt-0 mb-1 mr-2 flex items-center']">
                <span :class="[`${extraEquipments.length > 0 ? 'text-white' : 'text-gray-500'}`,'mr-2  text-sm']">
                  {{ $t('Equipos disponibles para agregar') }}:
                  <strong v-if="extraEquipments" >{{ extraEquipments.length }}</strong></span
                >
              </div>
              <div class=" border border-gray-200 px-2 py-2 rounded mt-1 sm:mt-0 mb-1 mx-2 flex items-center"
              >
                <span class="mr-2 text-gray-500 text-sm">
                  {{ $t('Equipos en el pactado actual') }} :
                  <strong v-if="equipments && equipments.length > 0">{{ equipments.length }} </strong></span>
              </div>
            </div>

            <!-- equipos en el pactado -->
            <div v-if="equipments && equipments.length > 0" class="flex flex-row flex-wrap justify-center sm:justify-start">
              <div
                class="border border-gray-200 mb-1 px-2 py-2 flex flex-col mr-1 rounded-lg equipment-inside-agree"
                v-for="(equipment, index) in equipments"
                :key="index"
              >
                <!-- <div class="flex flex-row">
                            <span class="font-bold mr-1"> Ref: </span> <span>{{equipment.id}} </span>
                        </div> -->
                <div class="flex flex-row">
                  <div class="flex flex-col">
                    <div v-if="equipment.serie" class="flex flex-row flex-wrap">
                      <span class="font-bold mr-1"> {{ $t('Serie') }}: </span>
                      <span>{{ equipment.serie }} </span>
                    </div>
                    <div v-if="equipment.terminal" class="flex flex-row flex-wrap">
                      <span class="font-bold mr-1"> {{ $t('Terminal') }}: </span>
                      <span>{{ equipment.terminal }} </span>
                    </div>
                    <div v-if="equipment.localidad" class="flex flex-row flex-wrap">
                      <span class="font-bold mr-1"> {{ $t('Localidad') }}: </span>
                      <span>{{ equipment.localidad }} </span>
                    </div>
                    <div v-if="equipment.direccion" class="flex flex-row flex-wrap">
                      <span class="font-bold mr-1"> {{ $t('Direccion') }}: </span>
                      <span>{{ equipment.direccion }} </span>
                    </div>
                    <div v-if="equipment.nombre_cliente" class="flex flex-row flex-wrap">
                      <span class="font-bold mr-1"> {{ $t('Nombre') }}: </span>
                      <span>{{ equipment.nombre_cliente }} </span>
                    </div>
                    <div  class="flex flex-row flex-wrap">
                      <span class="font-bold mr-1"> Reff: </span>
                      <span>{{ equipment.id }} </span>
                    </div>
                  </div>
                  <div>
                    <font-awesome-icon @click="removeEquipment(equipment, index)" class="text-gray-500 cursor-pointer" icon="times"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- avanzar, atras -->
            <div class="flex flex-row flex-wrap">
              <div v-if="equipments.length > 0" class="my-2 w-44 mr-2"
              >
                <BaseButton
                  @click="goThirdStep()"
                  :loading="loading"
                  textColor="text-white"
                  background="bg-black"
                  :text="$t('Siguiente')"
                />
              </div>
              <div class="my-2 w-44 ">
                <BaseButton
                  @click="backFirstStep()"
                  :text="$t('Atras')"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Base-modal>

    <!-- equipos a pactar -->
    <Base-modal
      :modalOverlay="true"
      @exit="modalMoreEquipments = false"
      :modal="modalMoreEquipments"
      :headingTitle="$t('Mas equipos')"
      size="lg"
    >
      <template #content>
        <div class="mx-2 flex flex-row flex-wrap ">
          <div class=" border border-gray-200 px-2 py-2 flex flex-col mr-1 mb-2 rounded-lg equipment-out-agree"
            v-for="(equipment, index) in extraEquipments"
            :key="index"
          >
            <div class="flex flex-row">
              <div class="flex flex-col">
                <div v-if="equipment.serie" class="flex flex-row flex-wrap">
                  <span class="font-bold mr-1"> {{ $t('Serie') }}: </span>
                  <span>{{ equipment.serie }} </span>
                </div>
                <div v-if="equipment.terminal" class="flex flex-row flex-wrap">
                  <span class="font-bold mr-1"> {{ $t('Terminal') }}: </span>
                  <span>{{ equipment.terminal }} </span>
                </div>
                <div v-if="equipment.localidad" class="flex flex-row flex-wrap">
                  <span class="font-bold mr-1"> {{ $t('Localidad') }}: </span>
                  <span>{{ equipment.localidad }} </span>
                </div>
                <div v-if="equipment.direccion" class="flex flex-row flex-wrap">
                  <span class="font-bold mr-1"> {{ $t('Direccion') }}: </span>
                  <span>{{ equipment.direccion }} </span>
                </div>
                <div v-if="equipment.nombre_cliente" class="flex flex-row flex">
                  <span class="font-bold mr-1"> {{ $t('Nombre') }}: </span>
                  <span>{{ equipment.nombre_cliente }} </span>
                </div>
                <div  class="flex flex-row flex-wrap">
                  <span class="font-bold mr-1"> Reff: </span>
                  <span>{{ equipment.id }} </span>
                </div>
              </div>
              <div>
                <font-awesome-icon @click="addEquipment(equipment, index)" class="text-gray-500 cursor-pointer" icon="plus"/>
              </div>
            </div>
          </div>

        </div>
      </template>
    </Base-modal>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

// utilididaes
import { equipmentsToAgreedRepo } from '@/repositories/Core/AgreementRepository';
//
import { hasLocalStorage, objEmpty } from '@/services/Utils/Objects';
import coreHook from '@/hooks/core/coreHook';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { isEditMode } = coreHook();

    const identificacion = ref(null);
    const matchOriginalAdress = ref(null);

    const extraEquipments = ref([]);
    const modalMoreEquipments = ref(false);
    const equipments = computed(() => store.state.agreement.agree.secondStep.equipments);

    const getEquipments = async (params) => {
      // si es igual a cero, todavia no se han guardado los equipos
      if (equipments.value.length === 0) {
        const response = await equipmentsToAgreedRepo(params);
        const { prepared_equipments } = response;
        //    equipos: datos del cliente,  prepared_equipments: equipos disponbiles para recuperar
        if (prepared_equipments.length > 0) {
          await store.dispatch('agreement/setSecondStep', { equipments: prepared_equipments });
        }
      }
      return equipments.value;
    };

    const getMoreEquipments = async () => {
      // ya aqui tengo los id de los equipos escogidos
      const equipmentIds = store.state.agreement.agree.secondStep.equipments.map((val) => val.id).toString();
      const params = {
        withoutEquipments: equipmentIds,
        identificacion: identificacion.value,
        diffOriginalAddress: matchOriginalAdress.value,
      };
      const response = await equipmentsToAgreedRepo(params);
      const { equipments: equipmentsAlias } = response;
      if (equipmentsAlias.length > 0) {
        extraEquipments.value = equipmentsAlias;
      }
    };
    const addEquipment = async (equipment, index) => {
      const add = [...equipments.value, ...[equipment]];
      await store.dispatch('agreement/setSecondStep', { equipments: add });
      extraEquipments.value.splice(index, 1);
      if (extraEquipments.value.length === 0) {
        modalMoreEquipments.value = false;
      }
    };
    const removeEquipment = async (equipment, index) => {
      const remove = equipments.value.filter((val, i) => i !== index);
      await store.dispatch('agreement/setSecondStep', { equipments: remove });
      extraEquipments.value.unshift(equipment);
    };

    const backFirstStep = () => {
      if (!isEditMode.value) {
        router.push({ name: 'agreeFirstStep' });
      } else {
        router.push({ name: 'agreeFirstStepEdit' });
      }
    };

    const loading = ref(false);

    const goThirdStep = async () => {
      loading.value = true;
      if (!isEditMode.value) {
        router.push({ name: 'agreeThirdStep' });
      } else {
        router.push({ name: 'agreeThirdStepEdit' });
      }
      loading.value = false;
    };

    // BOOTED

    onMounted(async () => {
      if (!await hasLocalStorage({ key: 'agreeFirstStep' })) { // a esta altura debe haber algo en el localstorage, el primero que lo setea es la vista agree
        if (!isEditMode.value) {
          router.push({ name: 'agreeFirstStep' });
        } else {
          router.push({ name: 'agreeFirstStepEdit' });
        }
      }
      await store.dispatch('agreement/tryCompleteStoreSecondStepFromLs');

      if (!isEditMode.value) {
        const { identificacion: identificacionAlias, matchOriginalAdress: matchOriginalAdressAlias } = route.params;
        identificacion.value = identificacionAlias;
        matchOriginalAdress.value = matchOriginalAdressAlias;
      }
      if (objEmpty(store.state.agreement.agreement)) {
        await store.dispatch('agreement/tryCompleteStoreAgreementFromLs');
      } else {
        const { identificacion: identificacionAlias, original_address } = store.state.agreement.agreement;
        identificacion.value = identificacionAlias;
        matchOriginalAdress.value = original_address;
      }

      if (identificacion.value && matchOriginalAdress.value) {
        const params = { identificacion: identificacion.value, matchOriginalAdress: matchOriginalAdress.value };
        await getEquipments(params);
      }
      await getMoreEquipments();
    });

    return {
      equipments,
      extraEquipments,
      modalMoreEquipments,
      addEquipment,
      removeEquipment,
      backFirstStep,
      goThirdStep,
      loading,
    };
  },
};
</script>

<style>

.equipment-inside-agree {
  width: 85%;
}

.equipment-out-agree {
  width: 100%;
}
.modal-out-agree {
  width: 90%;
  overflow-y:auto;
}

@media (min-width: 620px) {
  .modal-out-agree {
    max-height: 600px;
    overflow-y:auto;
    overflow-x:hidden;
  }
  .main-modal-agree-equipments {
    max-height: 600px;
    overflow-y:auto;
    overflow-x:hidden;
  }
}

@media (min-width: 720px) {
  .main-modal-agree-equipments {
    width: 40rem !important;
  }
  .equipment-inside-agree {
    width: 300px;
  }

  .equipment-out-agree {
    width: 320px;
  }

  .modal-out-agree {
    width: 40rem;
  }
}

@media (min-width: 920px) {
  .main-modal-agree-equipments {
    width: 50rem !important;
  }

  .modal-out-agree {
    width: 45rem;
  }
}

@media (min-width: 1024px) {
  .main-modal-agree-equipments {
    width: 60rem !important;
  }
}
</style>
