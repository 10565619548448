<template>
  <div>
      <Base-modal :modalOverlay="true"  @exit="backRoute()" :modal="true" :headingTitle="headingTitle">
        <template #content>
            <div class="mx-2 flex flex-col justify-center sm:justify-start flex-wrap">

                  <!-- simpleAlert message -->
                  <div class="m-2 mx-2">
                    <SimpleAlert color="blue" border="border-t-4">
                      <template v-slot:text>
                        <p v-if="isTransfer" class="font-bold">Esta acción transfiere las coberturas de un usuario a otro. El usuario que transfiere se quedara sin las coberturas transferidas, las cuales pasaran a ser del usuario destino</p>
                        <p v-if="!isTransfer" class="font-bold">Esta acción copiara las coberturas de un usuario para compartirlas con el usuario destino, ambos mantendan las coberturas.</p>
                      </template>
                    </SimpleAlert>
                  </div>
                <div>
                  <!-- country -->
                    <div class="m-2">
                      <BaseSelect
                      placeholder="Pais"
                      outline="blue"
                      :options="countries"
                      v-model="coverage.country.value"
                      />
                    </div>
                </div>

                <div class=" flex flex-row justify-center sm:justify-start flex-wrap">
                    <!-- users from-->
                    <div class="m-2">
                      <BaseSelect
                      placeholder="Usuarios con cobertura"
                      :options="userWithCoverage"
                      label="name"
                      outline="blue"
                      v-model="coverage.from_id.value"
                      :error="coverage.from_id.error"
                      :disabled="!coverage.country.value"
                      :filters="['name']"
                      @error-handle="coverage.from_id.error = $event"
                      />
                    </div>
                    <!-- users to-->
                    <div class="m-2">
                      <BaseSelect
                      placeholder="Usuario destino"
                      :options="users"
                      label="fullName"
                      outline="blue"
                      v-model="coverage.to_id.value"
                      :error="coverage.to_id.error"
                      :disabled="!coverage.country.value"
                      :filters="['fullName','locality.name']"
                      @error-handle="coverage.to_id.error = $event"
                      >
                      <template v-slot:itemList="{item}">
                        <div class="item-select">
                          {{item.fullName}}
                        </div>
                      </template>
                      </BaseSelect>
                    </div>

                </div>

                <!-- botones accion -->
                <div v-if="coverage.from_id.value && coverage.to_id.value && !message" class="m-2  flex justify-center sm:justify-start flex-row flex-wrap">
                    <div class="mr-2 w-28">
                        <BaseButton @click="save()" :loading="loading" :disabled="loading || loading" background="bg-blue-400" textColor="text-white"  :text="$t('Guardar')" />
                    </div>
                </div>

                <!-- simpleAlert message -->
                <div v-if="message" class="m-2 mx-2">
                  <SimpleAlert color="green" border="border-b-4">
                    <template v-slot:text>
                      <p class="font-bold">{{message}}</p>
                    </template>
                  </SimpleAlert>
                </div>

            </div>
        </template>
      </Base-modal>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import {
  reactive, ref, watch, computed,
} from 'vue';
// utilidades
import locationHook from '@/hooks/core/locationHook';
import { usersInfoRepo } from '@/repositories/User/UserRepository';
import CoverageRepository from '@/repositories/Core/CoverageRepository';

export default {
  setup(_, { emit }) {
    const router = useRouter();
    const route = useRoute();

    const backRoute = () => {
      router.push({ name: 'Coverages' });
    };

    const coverage = reactive({
      from_id: {
        value: null,
        error: '',
      },
      to_id: {
        value: null,
        error: '',
      },
      country: {
        value: null,
        error: '',
      },
    });

    const currentCoverage = ref({});
    const errorHandle = (errors) => {
      if (errors) {
        Object.entries(errors).forEach((error) => {
          const [key, val] = error;
          if (coverage[key]) {
            const [message] = val;
            coverage[key].error = message;
          }
        });
      } else {
        Object.entries(coverage).forEach((values) => {
          const [key] = values;
          if (coverage[key]) {
            coverage[key].error = '';
          }
        });
      }
    };

    const users = ref([]);
    const getUsers = async (params) => {
      const response = await usersInfoRepo(params);
      users.value = response;
    };

    const userWithCoverage = ref([]);
    const getUserWithCoverage = async (params) => {
      const response = await CoverageRepository.userWithCoverage(params);
      userWithCoverage.value = response;
    };

    const chosenCountry = false; const chosenProvince = false;
    const { countries, getCountries } = locationHook({ chosenCountry, chosenProvince });
    getCountries();

    watch(() => coverage.country.value, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        getUsers({ country: newVal });
        getUserWithCoverage({ country: newVal });
      }
    });

    const isTransfer = computed(() => route.name === 'transferCoverage');

    const headingTitle = computed(() => {
      if (isTransfer.value) {
        return 'Transferir cobertura';
      }
      return 'Copiar cobertura';
    });
    const loading = ref(false);
    const message = ref('');
    const save = async () => {
      loading.value = true;
      const object = {
        from_id: coverage.from_id.value,
        to_id: coverage.to_id.value,
      };
      let response;
      if (isTransfer.value) {
        response = await CoverageRepository.transfer(object);
      } else {
        response = await CoverageRepository.copy(object);
      }
      if (response.errors) {
        errorHandle(response.errors);
      } else {
        if (isTransfer.value) {
          emit('updateResponse', response);
        } else {
          emit('addResponse', response);
        }
        message.value = 'Realizado correctamente';
        setTimeout(() => {
          message.value = '';
          backRoute();
        }, 1500);
      }

      loading.value = false;
    };

    return {
      backRoute, coverage, errorHandle, users, userWithCoverage, loading, message, currentCoverage, countries, save, headingTitle, isTransfer,
    };
  },
};
</script>

<style>

</style>
