import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import $http from '@/services/Api/AxiosInstance';
import { objEmpty, objEquals } from '@/services/Utils/Objects';

export default ({ chosenCountry, chosenProvince }) => {
  // Esta funcion mapea los estados del usuario vas utilizados
  // Utiliza el store vuex para ello.
  const store = useStore();

  // PAISES = importar countries, usualCountries e invocar a usualCountries();
  // PROVINCIAS = Para traer las provincias de un cierto pais => has un computed de chosenCountry, chosenCountry deben retornar el pais elegido, y cada vez que cambie el valor del pais, traera las provincias de este pais importando provinces
  //  LOCALIDADES = Para traer las localidades de un cierta provincia => has un computed de chosenProvince, chosenProvince deben retornar la provincia elegida, y cada vez que cambie el valor de la provincia, traera las localidades de esta provincia importando localities

  // si solo quieres una propiedad, solo paises
  // EJEMPLO
  // const chosenCountry = false; const chosenProvince = false;
  // const { countries, getCountries } = locationHook({ chosenCountry, chosenProvince });

  const countries = ref([]);
  const provinces = ref([]);
  const localities = ref([]);

  const getCountries = async () => {
    if (store.state.utilities.countries.length === 0) {
      await $http.get('v1/location/countries')
        .then((res) => {
          countries.value = res.data.countries;
          store.dispatch('utilities/setPropUtility', { key: 'countries', value: countries.value });
        })
        .catch(() => {
          console.error('No estas recibiendo los paises');
        });
    } else {
      countries.value = store.state.utilities.countries;
    }

    return countries.value;
  };

  const getProvincesByCountryId = async (params) => {
    await $http.get('v1/location/provincesByCountryId', { params })
      .then((res) => { provinces.value = res.data.provinces; })
      .catch(() => {
        console.error('No estas recibiendo las pronvincias');
      });
  };

  const getLocalitiesByProvinceId = async (params) => {
    if (typeof params.id === 'string') return;
    await $http.get('v1/location/localitiesByProvinceId', { params })
      .then((res) => { localities.value = res.data.localities; })
      .catch(() => {
        console.error('No estas recibiendo las localidades');
      });
  };

  watch(() => chosenCountry.value, (newVal, oldVal) => {
    if (newVal && (typeof newVal === 'number' || typeof newVal === 'string') && (newVal !== oldVal)) {
      getProvincesByCountryId({ id: newVal });
    } else if (newVal && typeof newVal === 'object' && !objEquals(newVal, oldVal) && !objEmpty(newVal)) {
      getProvincesByCountryId(newVal);
    }
  });

  watch(() => chosenProvince.value, (newVal, oldVal) => {
    if (newVal && (typeof newVal === 'number' || typeof newVal === 'string') && (newVal !== oldVal)) {
      getLocalitiesByProvinceId({ id: newVal });
    } else if (newVal && typeof newVal === 'object' && !objEquals(newVal, oldVal) && !objEmpty(newVal)) {
      getLocalitiesByProvinceId(newVal);
    }
  });

  return {
    getCountries, getProvincesByCountryId, getLocalitiesByProvinceId, countries, provinces, localities,
  };
};
