<template>
  <div>
      <Base-modal :modalOverlay="true"  type="full-screen" @exit="backRoute()" :modal="true" headingTitle="Crear cobertura de zona">
        <template #content>
            <div class="mx-2 flex flex-col justify-center sm:justify-start flex-wrap">
                <!-- type coverage -->
                <div class="flex flex-row justify-between">
                  <div class="m-2" style="max-width: 17.5rem;">
                      <BaseSelect
                      placeholder="Tipo de cobertura"
                      :options="COVERAGE_TYPE"
                      outline="blue"
                      v-model="coverage.coverage_type.value"
                      :error="coverage.coverage_type.error"
                      />
                  </div>

                  <!-- guia cp -->
                  <div class="m-2" style="max-width: 17.5rem;">
                    <BaseButton
                      icon="search"
                      iconColor="text-gray-500"
                      textColor="text-gray-500"
                      :text="$t('Guia codigos postales')"
                      @click="modalGuideCp = true"
                    />
                  </div>
                 </div>

                <!-- selects -->
                <div v-if="coverage.coverage_type.value" class=" flex flex-row justify-center sm:justify-start flex-wrap">
                    <!-- country -->
                    <div class="m-2">
                      <BaseSelect
                      placeholder="Pais"
                      outline="blue"
                      :options="countries"
                      v-model="coverage.country.value"
                      ref="refCountry"
                      />
                    </div>
                    <!-- users -->
                    <div v-if="isUser" class="m-2">
                      <BaseSelect
                      placeholder="Usuario"
                      :options="users"
                      label="fullName"
                      outline="blue"
                      v-model="coverage.user.value"
                      :error="coverage.user.error"
                      :disabled="!coverage.country.value"
                      :filters="['firstName','lastName','province.name', 'fullName']"
                      @error-handle="coverage.user.error = $event"
                      >
                      <template v-slot:itemList="{item}">
                        <div class="item-select ">
                          {{item.firstName}} {{item.lastName}} {{item.fantasyName ? `- ${item.fantasyName}`: '' }}
                          <strong class="text-sm">{{item.province?.name?.toLowerCase()}}</strong>
                        </div>
                      </template>
                      </BaseSelect>
                    </div>

                    <!-- province -->
                    <div class="m-2">
                        <BaseSelect
                        :placeholder="$t('Provincia')"
                        :options="provinces"
                        outline="blue"
                        ref="refProvince"
                        v-model="coverage.province.value"
                        :disabled="!coverage.country.value"
                        :error="coverage.province.error"
                        />
                    </div>
                    <!-- locality -->
                    <div class="m-2">
                        <BaseSelect
                        :placeholder="$t('Localidad')"
                        :options="localities"
                        outline="blue"
                        v-model="coverage.locality.value"
                        :disabled="!coverage.province.value"
                        :error="coverage.locality.error"
                        ref="refLocality"
                        />
                    </div>
                </div>
                    <!-- correo o terminal -->
                    <template v-if="!isUser && coverage.locality.value">
                      <div class="flex flex-col my-4">
                          <div class="font-bold mx-1 mb-2">
                            Debes ingresar la direccion y  coordenadas ingresando la direccion en google maps
                          </div>
                          <div class="m-2 max-w-sm">
                              <BaseInput
                              placeholder="Direccion"
                              outline="blue"
                              v-model:modelValue="coverage.address.value"
                              :error="coverage.address.error"
                              @error-handle="coverage.address.error = $event"
                              />
                          </div>
                          <div class="flex flex-row flex-wrap ">
                            <!-- lat -->
                            <div class="m-2">
                              <BaseInput
                              :placeholder="$t('Latitud')"
                              outline="blue"
                              v-model:modelValue="coverage.lat.value"
                              :error="coverage.lat.error"
                              @error-handle="coverage.lat.error = $event"
                              />
                            </div>
                            <!-- lng -->
                            <div class="m-2">
                              <BaseInput
                              :placeholder="$t('Longitud')"
                              outline="blue"
                              v-model:modelValue="coverage.lng.value"
                              :error="coverage.lng.error"
                              @error-handle="coverage.lng.error = $event"
                              />
                            </div>
                          </div>

                      </div>

                    </template>
                </div>
                <!-- switch postalCodes -->
                <template v-if="postalCodes && postalCodes.length > 0">
                  <div class="m-2 mx-3">
                    <Switch v-model="selectAll" label="Seleccionar todos"/>
                  </div>
                  <div class="flex flex-row justify-center sm:justify-start flex-wrap m-2 " >
                      <div v-for="item in postalCodes" :key="item.id" class="w-20 my-1 mx-1">
                      <Switch v-model="selectedPostalCodes" :value="item.postalCode" :label="item.postalCode"/>
                      </div>
                  </div>
                  <div v-if="postalCodeError" class="m-2 mx-2">
                    <SimpleAlert color="red" border="border-b-4">
                      <template v-slot:text>
                        <p class="font-bold">{{postalCodeError}}</p>
                      </template>
                    </SimpleAlert>
                  </div>
                </template>
                <div v-if="selectedPostalCodes.length > 0" class="m-2 w-32 bg-green-200">
                  <BaseButton :loading="loading" :disabled="loading" @click="save()" background="bg-blue-400"  textColor="text-white"  :text="$t('Guardar')" />
                </div>

                <!-- simpleAlert message -->
                <div v-if="message" class="m-2 mx-2">
                  <SimpleAlert color="green" border="border-b-4">
                    <template v-slot:text>
                      <p class="font-bold">{{message}}</p>
                    </template>
                  </SimpleAlert>
                </div>

        </template>
      </Base-modal>
      <!-- continuar asignando -->
      <Base-modal
      :footer="true"
      :modalOverlay="true"
      @exit="modalContinueWork = false"
      :modal="modalContinueWork"
      headingTitle="Seguir trabajando">
      <template v-slot:content>
        <div class="m-2">
          ¿Deseas continuar asignando zonas?
        </div>
      </template>
      <template v-slot:footer>
        <div class="m-2 flex flex-row">
          <button @click="continueWorking()"  class="bg-green-400 rounded text-center p-1 px-2 m-1 "  ><span class="text-sm text-white font-bold">Si</span></button>
          <button @click="backRoute()" class="bg-red-400 rounded text-center p-1 px-2 m-1 "  ><span class="text-sm text-white font-bold">No</span></button>
        </div>
      </template>
      </Base-modal>

      <Base-modal :modalOverlay="true"  :cache="true"  @exit="modalGuideCp = false" :modal="modalGuideCp" :headingTitle="$t('Guia codigos postales')">
          <template #content>
              <div class="m-2">
                <PostalCodeGuide/>
              </div>
          </template>
      </Base-modal>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import {
  reactive, computed, watch, ref,
} from 'vue';

import PostalCodeGuide from '@/components/Custom/PostalCodeGuide.vue';
import Switch from '@/components/Form/Switch.vue';

// utilidades
import locationHook from '@/hooks/core/locationHook';
import CoverageRepository from '@/repositories/Core/CoverageRepository';
import LocationRepository from '@/repositories/LocationRepository';
import { usersInfoRepo } from '@/repositories/User/UserRepository';
import { COVERAGE_TYPE, COVERAGE_TYPES } from '@/services/Utils/Utils';
import { ROLE as roles } from '@/services/Utils/Role';

export default {
  components: {
    Switch, PostalCodeGuide,
  },

  setup(_, { emit }) {
    const router = useRouter();

    const backRoute = () => {
      router.push({ name: 'Coverages' });
    };

    const modalGuideCp = ref(false);
    const coverage = reactive({
      country: {
        value: null,
        error: '',
      },
      province: {
        value: null,
        error: '',
      },
      locality: {
        value: null,
        error: '',
      },
      coverage_type: {
        value: null,
        error: '',
      },
      user: {
        value: null,
        error: '',
      },
      address: {
        value: '',
        error: '',
      },
      lat: {
        value: '',
        error: '',
      },
      lng: {
        value: '',
        error: '',
      },

    });

    const postalCodeError = ref('');
    const errorHandle = (errors) => {
      if (errors) {
        Object.entries(errors).forEach((error) => {
          const [key, val] = error;
          if (coverage[key]) {
            const [message] = val;
            coverage[key].error = message;
          }
        });
        const { postalCode } = errors;
        if (postalCode) {
          const [message] = postalCode;
          postalCodeError.value = message;
        }
      } else {
        Object.entries(coverage).forEach((values) => {
          const [key] = values;
          if (coverage[key]) {
            coverage[key].error = '';
          }
        });
        postalCodeError.value = '';
      }
    };

    const isUser = computed(() => {
      const { value } = coverage.coverage_type;
      if (value && (COVERAGE_TYPES.COLLECTOR === value || COVERAGE_TYPES.COMMERCE === value)) {
        return true;
      }
      return false;
    });

    const selectedPostalCodes = ref([]);
    const postalCodes = ref([]);
    const getPostalCodes = async () => {
      const params = {
        locality: coverage.locality.value,
        user: coverage.user.value,
      };
      let response;
      if (isUser.value) {
        response = await CoverageRepository.cleanCp(params);
        if (response.errors) {
          coverage.locality.value = null;
          errorHandle(response.errors);
        }
      } else {
        response = await LocationRepository.postalCodes(params);
      }
      postalCodes.value = response;
    };
    const localityObserve = computed(() => coverage.locality.value);
    // LIMPIANDO DATOS PARA OBLIGAR VOLVER A BUSCAR DATOS

    watch(() => localityObserve.value, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        getPostalCodes();
        selectedPostalCodes.value = [];
      }
    });
    const selectAll = ref(false);
    watch(() => selectAll.value, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        if (selectAll.value) {
          postalCodes.value.forEach((val) => {
            selectedPostalCodes.value.push(val.postalCode);
          });
          // selectedPostalCodes.value = postalCodes.value;
        } else {
          selectedPostalCodes.value = [];
        }
      }
    });

    watch(() => coverage.user.value, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        postalCodes.value = [];
        coverage.locality.value = null;
      }
    });

    watch(() => coverage.coverage_type.value, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        // cada vez que se cambie este valor limpio los codigos postales, country, localidad para obligarlo a buscar nuevamente esta informacion
        if (coverage.country.value) {
          coverage.country.value = null;
        }

        if (coverage.province.value) {
          coverage.province.value = null;
        }

        if (postalCodes.value.length > 0) {
          postalCodes.value = [];
          coverage.locality.value = null;
        }
      }
    });

    const chosenCountry = computed(() => coverage.country.value);
    const chosenProvince = computed(() => coverage.province.value);
    const {
      countries, getCountries, provinces, localities,
    } = locationHook({ chosenCountry, chosenProvince });
    getCountries();

    const users = ref([]);
    const getUsers = async (params) => {
      const response = await usersInfoRepo(params);
      users.value = response;
    };

    watch(() => chosenCountry.value, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        // TRAIGO LOS USUARIOS DE ESE PAIS, EN TEORIA YA TENDRIA QUE TENER DATOS EL CAMPO coverage_type
        const { value } = coverage.coverage_type;
        let role = null;
        switch (value) {
          case COVERAGE_TYPES.COLLECTOR:
            role = roles.COLLECTOR;
            break;
          case COVERAGE_TYPES.COMMERCE:
            role = roles.COMMERCE;
            break;
          default:
        }

        const params = {
          country: newVal,
          role,
        };
        getUsers(params);
      }
    });

    const loading = ref(false);
    const message = ref('');
    const modalContinueWork = ref(false);

    const save = async () => {
      loading.value = true;
      const object = {
        postalCode: selectedPostalCodes.value,
        country: coverage.country.value,
        province: coverage.province.value,
        locality: coverage.locality.value,
        coverage_type: coverage.coverage_type.value,
      };

      if (isUser.value) {
        object.user = coverage.user.value;
      } else {
        object.address = coverage.address.value;
        object.lat = coverage.lat.value;
        object.lng = coverage.lng.value;
      }

      const response = await CoverageRepository.save(object);
      if (response.errors) {
        errorHandle(response.errors);
      } else {
        emit('addResponse', response);
        modalContinueWork.value = true;
        message.value = 'Guardado correctamente';
        setTimeout(() => {
          message.value = '';
        }, 1800);
      }
      loading.value = false;
    };

    const continueWorking = () => {
      modalContinueWork.value = false;
      selectedPostalCodes.value = [];
      postalCodes.value = [];
      coverage.locality.value = null;
    };

    return {
      backRoute, COVERAGE_TYPE, coverage, countries, provinces, localities, postalCodes, selectedPostalCodes, users, isUser, selectAll, loading, save, postalCodeError, message, modalContinueWork, continueWorking, modalGuideCp,
    };
  },
};
</script>

<style>

</style>
