<template>
  <div>
    <Base-modal :modalOverlay="true"  type="full-screen"  @exit="backThirdStep()" :modal="true" :headingTitle="$t('Datos del pactado')">
        <template #content>
            <div class="flex flex-col  m-2">
                <!-- elegir point-->
              <div class="flex flex-col mb-2" >
                  <div class="mb-2">
                    <span class="text-gray-500 text-sm">
                    {{$t('Elija la sucursal')}} {{thirdStep.agreement_type === AGREEMENT_TYPES.CORREO ? 'Correo' : 'Terminal'}}
                    </span>
                  </div>
                  <div style="max-width:20rem;">
                    <BaseSelect
                      placeholder="Surcursal"
                      :options="thirdStep.agreement_type === AGREEMENT_TYPES.CORREO ? options.correo : options.terminal"
                      label="address"
                      outline="blue"
                      :modelValue="point.branch.value"
                      v-model:modelValue="point.branch.value"
                      :error="point.branch.error"
                      @error-handle="point.branch.error = $event"
                      :return-object="true"
                      >
                      <template v-slot:itemList="{item}">
                      <div class="item-select ">
                          <span>{{item.address}} </span>
                          <strong class="ml-1"> {{item.locality.name}}</strong>
                      </div>
                      </template>
                    </BaseSelect>
                  </div>
              </div>
              <!-- google iframe -->
              <div class="my-4 max-w-md">
                <GoogleIframeMap :lat="point.branch.value.lat" :lng="point.branch.value.lng" />
              </div>
              <!-- nro de telefono -->
              <div class="mt-2 flex flex-col">
                  <span class="text-gray-500 text-sm">
                      {{$t('Nro de telefono')}}
                  </span>
                  <div class="my-2 flex flex-row flex-wrap">
                    <div class="w-32 mr-4 mt-1 sm:mt-0">
                      <BaseSelect
                      :placeholder="$t('Codigo Pais')"
                      outline="blue"
                      :options="areaCodes"
                      label="prefix"
                      trackBy="prefix"
                      :modelValue="point.prefix.value"
                      @content-change="setAreaCode($event)"
                      :error="point.prefix.error"
                      @error-handle="point.prefix.error = $event"
                      :returnObject="true"
                      />
                    </div>
                    <div class="mt-1 sm:mt-0">
                      <BaseInput
                      type='number'
                      :placeholder="$t('Nro Telefono')"
                      outline="blue"
                      v-model:modelValue="point.phoneNumber.value"
                      :error="point.phoneNumber.error"
                      @error-handle="point.phoneNumber.error = $event"
                      :rules="[invalidPhoneNumber]"
                      />
                    </div>
                  </div>
              </div>

              <!-- email y quien entrega -->
              <div class="flex flex-row flex-wrap">
                <div class="flex flex-col mr-2">
                  <span class="text-gray-500 text-sm">
                      {{ $t('Email') }}
                  </span>
                  <div class="my-2">
                    <BaseInput
                    :placeholder="$t('Email')"
                    outline="blue"
                    v-model:modelValue="point.email.value"
                    :error="point.email.error"
                    @error-handle="point.email.error = $event"
                    />
                  </div>
                </div>
              </div>

              <!-- canal de contacto -->
              <div class="mt-2 flex flex-col">
                <span class="text-gray-500 text-sm">
                    {{$t('Como lo contacaron')}}
                </span>
                <div class="my-2 flex flex-row flex-wrap">
                  <div class="mr-4 mt-1 sm:mt-0">
                    <BaseSelect
                    :placeholder="$t('Canal de contacto')"
                    outline="blue"
                    :options="contact_channels"
                    v-model="point.contact_channel.value"
                    :error="point.contact_channel.error"
                    @error-handle="point.contact_channel.error = $event"
                    />
                  </div>
                </div>
              </div>

              <!-- avanzar, atras -->
              <div class="flex flex-row flex-wrap mt-2">
                <div class="my-2 w-44 mr-2">
                  <BaseButton
                  @click="goConfirm()"
                  :disabled="!completedSection"
                  textColor="text-white"
                  background="bg-black"
                  :text="$t('Siguiente')"
                  />
                </div>
                <div class="my-2 w-44">
                  <BaseButton
                  @click="backThirdStep()"
                  :text="$t('Atras')"
                  />
                </div>
              </div>
            </div>
        </template>
    </Base-modal>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  computed, reactive, onMounted,
} from 'vue';
import GoogleIframeMap from '@/components/Google/GoogleIframeMap.vue';
import coreHook from '@/hooks/core/coreHook';

// utilididaes
import { objEmpty, empty, hasLocalStorage } from '@/services/Utils/Objects';
import { AGREEMENT_TYPES } from '@/services/Utils/Utils';
import { optionsRepo } from '@/repositories/Core/AgreementRepository';
import usualHook from '@/hooks/core/usualHook';
import 'v-calendar/dist/style.css';

export default {
  components: {
    GoogleIframeMap,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { isEditMode } = coreHook();

    const point = reactive({
      branch: {
        value: {},
        error: '',
      },
      prefix: {
        value: {},
        error: '',
      },
      phoneNumber: {
        value: '',
        error: '',
      },
      email: {
        value: '',
        error: '',
      },
      contact_channel: {
        value: null,
        error: '',
      },

    });
    const options = computed(() => store.state.agreement.agree.options);

    const setAreaCode = (data) => {
      point.prefix.value = data;
      if (!empty(point.phoneNumber.value)) {
        /* eslint no-use-before-define: ["error", { "variables": false }] */
        const invalid = invalidPhoneNumber(point.phoneNumber.value);
        if (invalid) {
          point.phoneNumber.error = invalid;
        } else {
          point.phoneNumber.error = '';
        }
      }
    };

    const invalidPhoneNumber = (val) => {
      let invalid = false;
      const { lengthPhoneNumber } = point.prefix.value;
      if (val) {
        // if (val.length !== lengthPhoneNumber && visit.prefix.value.code !== 'BRA') {
        //   invalid = `El numero debe tener ${lengthPhoneNumber} digitos`;
        // } else {
        //   invalid = false;
        // }
        invalid = false;
      } else {
        invalid = false;
      }
      return invalid;
    };

    const {
      getAreaCodes, areaCodes, contact_channels, getContactChannels,
    } = usualHook();
    getAreaCodes();
    getContactChannels();

    const thirdStep = computed(() => store.state.agreement.agree.thirdStep);
    const getOptions = async () => {
      let params = {};

      const {
        country, locality, province, postalCode, identificacion,
      } = store.state.agreement.agree.firstStep;
      params = {
        country: country.value,
        province: province.value,
        locality: locality.value,
        postalCode: postalCode.value,
        identificacion: identificacion.value,
      };
      const response = await optionsRepo(params);
      store.dispatch('agreement/setPropAgree', { key: 'options', value: response.options });
    };

    const completedSection = computed(() => !objEmpty(point.branch.value)
              && !objEmpty(point.prefix.value)
              && point.phoneNumber.value
              && point.contact_channel.value);

    const goConfirm = async () => {
      await store.dispatch('agreement/setFourthStep', point);
      router.push({ name: `confirmAgree${isEditMode.value ? 'Edit' : ''}` });
    };

    const completeFourthStepFromStore = async () => {
      Object.keys(store.state.agreement.agree.fourthStep).forEach((key) => {
        if (point[key]) {
          if (key === 'branch') {
            // empty
          } else if (store.state.agreement.agree.fourthStep[key].value) {
            point[key].value = store.state.agreement.agree.fourthStep[key].value;
          }
        }
      });
      const fourthdStepLocalStorage = await hasLocalStorage({ key: 'agreeFourthStep' });
      const thirdStepLocalStorage = await hasLocalStorage({ key: 'agreeThirdStep' });
      const { agreement_type: agreementTypeLs } = thirdStepLocalStorage;
      const { agreement_type: agreementTypeBranch } = thirdStep.value;
      const { branch: branchLs } = fourthdStepLocalStorage;
      const { branch: branchStore } = store.state.agreement.agree.fourthStep;
      if (!objEmpty(store.state.agreement.agree.fourthStep.branch.value)
          && branchLs.id === branchStore.id
          && agreementTypeLs === agreementTypeBranch) {
        if (thirdStep.value.agreement_type === AGREEMENT_TYPES.CORREO) {
          if (options.value.correo.some((val) => val.id === store.state.agreement.agree.fourthStep.branch.value.id)) {
            point.branch.value = branchLs.value;
          }
        } else if (options.value.terminal.some((val) => val.id === store.state.agreement.agree.fourthStep.branch.value.id)) {
          point.branch.value = branchLs.value;
        }
      }
      return point;
    };

    onMounted(async () => {
      if (!hasLocalStorage({ key: 'agreeThirdStep' })) {
        router.push({ name: `agreeThirdStep${isEditMode.value ? 'Edit' : ''}` });
      }

      if (!store.getters['agreement/completeFirstStep']) {
        await store.dispatch('agreement/tryCompleteStoreFirstStepFromLs');
        if (objEmpty(options.value)) {
          await getOptions();
        }
      } else if (objEmpty(options.value)) {
        await getOptions();
      }

      if (!store.getters['agreement/completeFourthStep']) {
        await store.dispatch('agreement/tryCompleteStoreFourthStepFromLs');
      }

      // Necesito el store del tercer paso para poder mostrar el tipo de visita escogido
      if (!store.getters['agreement/completeThirdStep']) {
        await store.dispatch('agreement/tryCompleteStoreThirdStepFromLs');
      }

      // necesito completar el cliente para poder ver si tengo que completar el email y el numero de telefono
      if (!isEditMode.value) {
        if (!store.state.agreement.customer) {
          await store.dispatch('agreement/tryCompleteStoreCustomerFromLs');
        }
        if (store.state.agreement.customer && !objEmpty(store.state.agreement.customer)) {
          const {
            email, telefono_cel4, telefono_cel5, telefono_cel6,
          } = store.state.agreement.customer;
          if (email) {
            point.email.value = email;
          }
          if (telefono_cel4) {
            point.phoneNumber.value = telefono_cel4;
          } else if (telefono_cel5) {
            point.phoneNumber.value = telefono_cel5;
          } else if (telefono_cel6) {
            point.phoneNumber.value = telefono_cel6;
          }
        }
      } else { // aca esta editando
        if (!store.state.agreement.agreement) {
          await store.dispatch('agreement/tryCompleteStoreAgreementFromLs');
        }
        const { email, phoneNumber, prefix } = store.state.agreement.agreement;
        if (email) {
          point.email.value = email;
        }
        if (phoneNumber) {
          point.phoneNumber.value = phoneNumber;
        }
        if (prefix) {
          point.prefix.value = prefix.prefix;
        }
      }

      if (store.getters['agreement/completeFourthStep'] && !completedSection.value) {
        await completeFourthStepFromStore();
      }
      // DEBO COMPLETAR LOS CAMPOS
    });

    const backThirdStep = () => {
      router.push({ name: `agreeThirdStep${isEditMode.value ? 'Edit' : ''}` });
    };

    return {
      backThirdStep, thirdStep, AGREEMENT_TYPES, options, point, objEmpty, areaCodes, contact_channels, completedSection, goConfirm, setAreaCode, invalidPhoneNumber,
    };
  },

};
</script>
