import { objEmpty } from '@/services/Utils/Objects';
import { onlyNumber, removeAccents } from '@/services/Utils/Letters';

function possibleCp(geocode) {
  return new Promise((resolve) => {
    let postalCode = '';
    if (geocode && !objEmpty(geocode)) {
      const { address_components } = geocode;
      if (address_components.length > 0) {
        address_components.forEach((address) => {
          if (address.types && address.types.includes('postal_code')) {
            postalCode = address.long_name ?? address.short_name;
            postalCode = onlyNumber(postalCode);
          }
        });
      }
    }
    resolve(postalCode);
  });
}

function possibleLocation(geocode) {
  return new Promise((resolve) => {
    let locality = '';
    let findLocality;
    if (geocode && !objEmpty(geocode)) {
      const { address_components } = geocode;
      if (address_components.length > 0) {
        for (let i = 0; address_components.length >= i; i += 1) {
          const firstOption = ['sublocality_level_1', 'sublocality', 'political'].every((val) => address_components[i].types.indexOf(val) > -1);
          const SecondOption = ['administrative_area_level_2', 'political'].every((val) => address_components[i].types.indexOf(val) > -1);
          const ThirdOption = ['locality', 'political'].every((val) => address_components[i].types.indexOf(val) > -1);
          if (firstOption) {
            findLocality = address_components[i];
            break;
          } else if (SecondOption) {
            findLocality = address_components[i];
            break;
          } else if (ThirdOption) {
            findLocality = address_components[i];
            break;
          }
        }

        if (findLocality) {
          locality = findLocality.long_name ?? findLocality.short_name;
          locality = removeAccents(locality);
        }
      }
    }
    resolve(locality);
  });
}

export { possibleCp, possibleLocation };
