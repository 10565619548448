const schedule = [
  {
    id: 1, name: 'Mañana 9:00 - 13:00', from: '9:00:00', until: '13:00:00',
  },
  {
    id: 2, name: 'Tarde 13:00 - 17:00', from: '13:00:00', until: '17:00:00',
  },
  {
    id: 3, name: 'Todo el dia 9:00 - 17:00', from: '09:00:00', until: '17:00:00',
  },
  {
    id: 4, name: 'Tarde 17:00 - 20:00', from: '17:00:00', until: '20:00:00',
  },
];

export const SCHEDULES_TYPE = {
  MORNING: 1,
  AFTERNOON: 2,
  COMPLETE_DAY: 3,
};

const days = [
  { value: 2, title: 'Lunes - Horario', key: 'Monday' },
  { value: 3, title: 'Martes - Horario', key: 'Tuesday' },
  { value: 4, title: 'Miercoles - Horario', key: 'Wednesday' },
  { value: 5, title: 'Jueves - Horario', key: 'Thursday' },
  { value: 6, title: 'Viernes - Horario', key: 'Friday' },
  { value: 7, title: 'Sabado - Horario', key: 'Saturday' },
  { value: 1, title: 'Domingo - Horario', key: 'Sunday' },
];

export { schedule, days };
