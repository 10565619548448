<template>
  <div>
      <Navbar-Section  titleClass="ml-4 " :section="$t('Panel')" />
      <div class="m-2 flex flex-row flex-wrap ">
        <div
        v-for="(section, index) in sections" :key="index"
        class="flex flex-col  my-2 border border-gray-300 p-2 px-3 mr-3 rounded shadow">
          <div class="my-1 flex flex-row ">
            <div>{{section.title}}</div>
            <div class="mx-2"><font-awesome-icon class="text-gray-500" :icon="section.icon" /></div>
          </div>
          <div class="flex flex-row flex-wrap">
            <template 
             v-for="(item, i) in section.sections" :key="i"
            >
              <router-link
              v-if="item.name"
              :to="{name: item.name}"
              class="flex flex-col justify-center items-center border border-gray-300 px-6 py-2 rounded mr-2 my-1 cursor-pointer">
                <div class="text-gray-500">{{item.title}}</div>
              </router-link>
              <a
              v-else
              :href="item.href"
              class="flex flex-col justify-center items-center border border-gray-300 px-6 py-2 rounded mr-2 my-1 cursor-pointer">
                <div class="text-gray-500">{{item.title}}</div>
              </a>
            </template>
           
           
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {

  },
  computed: {
    ...mapState('auth', ['user', 'token']),
    sections(){
      return [
        {
          title: this.$t('Usuarios'),
          icon: 'user',
          sections: [
            { title: this.$t('Reclutamiento'), href: `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${this.token}&redirectTo=/usuarios/usuarios-operativos` },
          ],
        },
        {
          title: this.$t('Empresas'),
          icon: 'car-side',//faCarSide
          sections: [
            { title: this.$t('usuarios'), href: `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${this.token}&redirectTo=/usuarios/usuarios-empresas` },
          ],
        },
        {
          title: this.$t('Asignación'),
          icon: 'layer-group',
          sections: [
            { title: 'Zonas', name: 'Coverages' },
            { title: 'Zonas vacias', name: 'EmptyAreas' },
          ],
        },
        {
          title: this.$t('Call'),
          icon: 'phone',
          sections: [
            { title: this.$t('Pactados'), name: 'agreements' },
            { title: this.$t('Pactar'), name: 'agreeCustomer' },
          ],
        },
        {
          title: this.$t('Equipos'),
          icon: 'archive',
          sections: [
            { title: this.$t('Gestionar equipos'), name: 'customers' },
            { title: this.$t('Equipos gestionados'), name: 'historyEquipmentManagements' },
            { title: 'Clientes', name: 'historyEquipments' },
          ],
        },
        {
          title: this.$t('Configuracion'),
          icon: 'cog',
          sections: [
            { title: this.$t('Cambiar contraseña'), name: 'Settings' },
          ],
        },
      ]
    }
    
  },

};
</script>

<style>

</style>
