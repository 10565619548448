<template>
  <div class="my-2 flex flex-row flex-wrap justify-center">
        <div class="m-1">
            <BaseSelect
            placeholder="Pais"
            outline="blue"
            :options="countries"
            v-model="country.value"
            :remove="true"
            />
        </div>

        <div class="m-1">
            <BaseSelect
                placeholder="Ingrese busqueda"
                :options="options"
                label="postalCode"
                outline="blue"
                v-model:modelValue="search"
                type="fetch"
                :remove="true"
                >
                <template v-slot:itemList="{item}">
                    <div class="item-select">
                        {{item.postalCode}}  <strong>{{item.locality.name.toLowerCase()}}</strong>
                    </div>
                </template>
            </BaseSelect>
      </div>
  </div>
</template>

<script>

// utilidades
import LocationRepository from '@/repositories/LocationRepository';
import locationHook from '@/hooks/core/locationHook';

export default {
  components: {

  },

  data() {
    return {
      search: '',
      timer: null,
      options: [],
      country: {
        value: {},
      },
      params: {},
    };
  },
  methods: {
    getPostalCodes(val) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (this.country.value?.id) {
          this.params.country = this.country.value?.id;
        }
        this.params.search = val;
        const response = await LocationRepository.postalCodes(this.params);

        if (response.length > 0 && this.search) {
          this.options = response;
        }
      }, 400);
    },
  },
  watch: {
    search(val) {
      if (val && val.length > 3) {
        this.getPostalCodes(val);
      } else {
        clearTimeout(this.timer);
        this.options = [];
      }
    },
  },
  setup() {
    // const chosenCountry = instance?.proxy?.country?.value?.id ?? false;
    const chosenCountry = false;
    const chosenProvince = false;
    const { countries, getCountries } = locationHook({ chosenCountry, chosenProvince });
    getCountries();

    return { countries };
  },
};
</script>

<style>

</style>
