<template>
  <div>
    <Base-modal :modalOverlay="true"  type="full-screen"  @exit="backThirdStep()" :modal="true" :headingTitle="$t('Datos de visita')">
        <template #content>
            <div class="flex flex-col  m-2">
                <!-- elegir recoelctor -->
                    <div class="flex flex-col mb-2" v-if="thirdStep.agreement_type === AGREEMENT_TYPES.VISIT && thirdStep.all" >
                        <div class="mb-2">
                            <span class="text-gray-500 text-sm">
                            {{ $t('Elije recolector') }}
                            </span>
                        </div>
                        <div style="max-width:20rem;">
                            <BaseSelect
                              placeholder="Usuario"
                              :options="options.allCollectors"
                              label="firstName"
                              outline="blue"
                              :modelValue="visit.user.value"
                              @content-change="setUser($event)"
                              :error="visit.user.error"
                              :filters="['firstName','lastName','province.name']"
                              @error-handle="visit.user.error = $event"
                              :return-object="true"
                              >
                              <template v-slot:itemList="{item}">
                              <div class="item-select ">
                                  {{item.firstName}} {{item.lastName}}
                                  <strong class="text-sm">{{item.province?.name?.toLowerCase()}}</strong>
                              </div>
                              </template>
                            </BaseSelect>
                        </div>
                    </div>
                    <!-- pm -->
                    <div class="flex flex-row mb-2" v-else >
                        <div class="mb-2">
                            <span class="text-gray-500 text-sm">
                              {{ $t('Recolector') }}:
                            </span>
                        </div>
                        <div class="mb-2 mx-2">
                            <span class="text-gray-600 text-sm">
                             <strong> {{visit.user.value?.firstName?.toUpperCase()}} {{visit.user.value?.lastName?.toUpperCase()}}</strong>
                            </span>
                            <!-- <span v-else class="text-gray-600 text-sm">
                             <strong> {{options.pm?.firstName}} {{options.pm?.lastName}}</strong>
                            </span> -->
                        </div>
                    </div>
                    <!-- dias y horarios disponibles -->
                    <div  class="flex flex-row flex-wrap my-2 ">
                        <div class="flex flex-col">
                            <div  >
                                <span class="text-gray-500 text-sm">
                                {{ $t('Dias disponibles') }}
                                </span>
                                <!-- <span class="cursor-pointer mx-2"><font-awesome-icon @click="modalDay = !modalDay" icon="edit" /></span> -->
                            </div>
                            <div @click="openDayModal()"
                            :class="['cursor-pointer flex justify-center  flex-row border p-2  rounded-lg mt-2', visit.day.value ? 'border-blue-500' : 'border-red-600']">
                                {{visit.day.value ? reverseStr(visit.day.value) : $t('Elegir dia')}}
                            </div>
                        </div>
                        <div class="flex flex-col mx-2 sm:mx-6">
                            <div >
                                <span class="text-gray-500 text-sm">
                                {{ $t('Horario disponible') }}
                                </span>
                            </div>
                            <div :class="['cursor-pointer border p-2  rounded-lg mt-2', !objEmpty(visit.schedule.value) ? 'border-blue-500' : 'border-red-600']" >
                                {{!objEmpty(visit.schedule.value) ? $t(visit.schedule.value.name) : $t('Debes elegir el dia')}}
                            </div>

                        </div>
                    </div>
                    <!-- sin horarios -->
                    <div  v-if="emptyWorkSchedules">
                        <SimpleAlert color="white" border="border-b-4 my-4">
                            <template v-slot:text>
                            <p v-if="isZoneOperator || isAdmin" class="font-bold"> {{$t('El usuario no tiene horarios establecidos. Deberas actualizar los horarios del usuario para poder avanzar')}}</p>
                            <p v-else class="font-bold">{{ $t('El usuario no tiene horarios establecidos. Debes solicitar la actualizacion de horarios del usuario') }} </p>
                            </template>
                    </SimpleAlert>
                    </div>
                    <!-- nro de telefono -->
                    <div class="mt-2 flex flex-col">
                        <span class="text-gray-500 text-sm">
                            {{ $t('Nro de telefono') }}
                        </span>
                        <div class="my-2 flex flex-row flex-wrap">
                            <div class="w-32 mr-4 mt-1 sm:mt-0">
                              <BaseSelect
                              :placeholder="$t('Codigo Pais')"
                              outline="blue"
                              :options="areaCodes"
                              label="prefix"
                              trackBy="prefix"
                              :modelValue="visit.prefix.value"
                              @content-change="setAreaCode($event)"
                              :error="visit.prefix.error"
                              @error-handle="visit.prefix.error = $event"
                              :returnObject="true"
                              />
                            </div>
                            <div class="mt-1 sm:mt-0">
                              <BaseInput
                              type='number'
                              :placeholder="$t('Nro Telefono')"
                              outline="blue"
                              v-model:modelValue="visit.phoneNumber.value"
                              :error="visit.phoneNumber.error"
                              @error-handle="visit.phoneNumber.error = $event"
                              :rules="[invalidPhoneNumber]"
                              />
                            </div>
                        </div>
                    </div>

                    <!-- email y quien entrega -->
                    <div class="flex flex-row flex-wrap">
                        <div class="flex flex-col mr-2">
                            <span class="text-gray-500 text-sm">
                                {{ $t('Email') }}
                            </span>
                            <div class="my-2">
                              <BaseInput
                              :placeholder="$t('Email')"
                              outline="blue"
                              v-model:modelValue="visit.email.value"
                              :error="visit.email.error"
                              @error-handle="visit.email.error = $event"
                              />
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <span class="text-gray-500 text-sm">
                                {{ $t('Quien entrega') }}
                            </span>
                            <div class="my-2">
                              <BaseInput
                              :placeholder="$t('Quien entrega')"
                              outline="blue"
                              v-model:modelValue="visit.who_delivers.value"
                              :error="visit.who_delivers.error"
                              @error-handle="visit.who_delivers.error = $event"
                              />
                            </div>
                        </div>
                    </div>

                    <!-- canal de contacto -->
                    <div class="mt-2 flex flex-col">
                        <span class="text-gray-500 text-sm">
                            {{ $t('Como lo contacaron') }}
                        </span>
                        <div class="my-2 flex flex-row flex-wrap">
                            <div class="mr-4 mt-1 sm:mt-0">
                              <BaseSelect
                              :placeholder="$t('Canal de contacto')"
                              outline="blue"
                              :options="contact_channels"
                              v-model="visit.contact_channel.value"
                              :error="visit.contact_channel.error"
                              @error-handle="visit.contact_channel.error = $event"
                              />
                            </div>
                        </div>
                    </div>

                    <!-- avanzar, atras -->
                    <div class="flex flex-row flex-wrap mt-2">
                      <div class="my-2 w-44 mr-2">
                        <BaseButton
                        @click="goConfirm()"
                        :disabled="!completedSection"
                        textColor="text-white"
                        background="bg-black"
                        :text="$t('Siguiente')"
                        />
                      </div>
                      <div class="my-2 w-44">
                        <BaseButton
                        @click="backThirdStep()"
                        :text="$t('Atras')"
                        />
                      </div>
                    </div>
            </div>
        </template>
    </Base-modal>
    <Base-modal :modalOverlay="true" :cache="true" @exit="modalDay = false" :modal="modalDay" headingTitle="Dias">
        <template #content>
          <div class="flex flex-col mx-2 mb-3">
              <div class="mb-2">
                <span class="text-gray-500 text-sm">
                    {{ $t('Elige un dia') }}
                </span>
              </div>
              <div class="flex justify-center">
                <DatePicker
                :model-config="{type: 'string',mask: 'YYYY-MM-DD'}"
                v-model="visit.day.value"
                :disabled-dates='{ weekdays: disabledDays}'
                :min-date='new Date()'
                :max-date='new Date().setDate(new Date().getDate() + 15)'
                />
              </div>
            </div>
      </template>
    </Base-modal>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  computed, reactive, onMounted, ref, watch,
} from 'vue';
import { DatePicker } from 'v-calendar';

// utilididaes
import {
  objEmpty, empty, hasLocalStorage,
} from '@/services/Utils/Objects';
import { AGREEMENT_TYPES } from '@/services/Utils/Utils';
import { reverseStr } from '@/services/Utils/Formatters';
import { schedule } from '@/services/Utils/Schedule';
import { optionsRepo } from '@/repositories/Core/AgreementRepository';
import UserRepository from '@/repositories/User/UserRepository';
import usualHook from '@/hooks/core/usualHook';
import coreHook from '@/hooks/core/coreHook';
import 'v-calendar/dist/style.css';

export default {
  components: {
    DatePicker,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { isZoneOperator, isAdmin, isEditMode } = coreHook();

    const visit = reactive({
      user: {
        value: {},
        error: '',
      },
      day: {
        value: null,
        error: '',
      },
      schedule: {
        value: null,
        error: '',
      },
      prefix: {
        value: {},
        error: '',
      },
      phoneNumber: {
        value: '',
        error: '',
      },
      email: {
        value: '',
        error: '',
      },
      who_delivers: {
        value: '',
        error: '',
      },
      contact_channel: {
        value: null,
        error: '',
      },

    });
    const date = ref(null);
    const options = computed(() => store.state.agreement.agree.options);
    const userWorkSchedules = ref([]);
    const emptyWorkSchedules = ref(false);
    const disabledDays = ref([]);
    const modalDay = ref(false);

    const getUserSchedules = async (params) => {
      const response = await UserRepository.workSchedules(params);
      const { work_schedules } = response;
      if (work_schedules && work_schedules.length > 0) {
        emptyWorkSchedules.value = false;
        userWorkSchedules.value = response.work_schedules;
        const weekDays = [1, 2, 3, 4, 5, 6, 7];
        disabledDays.value = weekDays.filter((x) => !userWorkSchedules.value.map((val) => val.day.id).includes(x));
      } else {
        emptyWorkSchedules.value = true;
        visit.day.value = null;
        visit.schedule.value = null;
      }
    };
    const setUser = (data) => {
      visit.user.value = data;
      visit.day.value = null;
      visit.schedule.value = null;
      getUserSchedules(data.id);
    };
    const setAreaCode = (data) => {
      visit.prefix.value = data;
      if (!empty(visit.phoneNumber.value)) {
        const invalid = invalidPhoneNumber(visit.phoneNumber.value);
        if (invalid) {
          visit.phoneNumber.error = invalid;
        } else {
          visit.phoneNumber.error = '';
        }
      }
    };
    const setDay = (data) => {
      const day = new Date(data);
      const realDay = day.getDay() + 2;
      // le sumo uno porque los dias en javascript comienzan desde el lunes => 0, y el formato que tengo yo es lunes => 2, martes => 3, etc...
      const schedule_id = userWorkSchedules.value.filter((val) => val.day_id === realDay)[0];
      if (schedule_id) {
        const [working] = schedule.filter((val) => val.id === schedule_id.schedule_id);
        visit.schedule.value = working;
      } else {
        visit.schedule.value = null;
        visit.day.value = null;
      }
    };
    const openDayModal = () => {
      if (userWorkSchedules.value.length > 0 && !emptyWorkSchedules.value) {
        modalDay.value = !modalDay.value;
      } else {
        visit.user.error = 'Debes elegir el usuario';
      }
    };
    watch(() => visit.day.value, (newVal) => {
      if (userWorkSchedules.value.length > 0 && newVal) {
        setDay(newVal);
        modalDay.value = false;
      }
    });

    const {
      getAreaCodes, areaCodes, contact_channels, getContactChannels,
    } = usualHook();
    getAreaCodes();
    getContactChannels();

    const thirdStep = computed(() => store.state.agreement.agree.thirdStep);
    const getOptions = async () => {
      let params = {};
      // esta condicion es porque cuando se recarga la pagina, puede ser que no este completado aun los datos de los pasos anteriores
      // y si no stan completados, lo pido para asi poder llenar los params que van al backend en busca de opciones disponibles
      const {
        country, locality, province, postalCode, identificacion,
      } = store.state.agreement.agree.firstStep;
      params = {
        country: country.value,
        province: province.value,
        locality: locality.value,
        postalCode: postalCode.value,
        identificacion: identificacion.value,
      };
      const response = await optionsRepo(params);
      store.dispatch('agreement/setPropAgree', { key: 'options', value: response.options });
    };

    const completedSection = computed(() => {
      if (!objEmpty(visit.user.value)
              && visit.day.value
              && !objEmpty(visit.prefix.value)
              && visit.phoneNumber.value
              && !visit.phoneNumber.error
              /* eslint no-use-before-define: ["error", { "variables": false }] */
              && !invalidPhoneNumber(visit.phoneNumber.value)
              && visit.who_delivers.value
              && visit.contact_channel.value
              && visit.schedule.value) {
        return true;
      }
      return false;
    });

    const goConfirm = async () => {
      await store.dispatch('agreement/setFourthStep', visit);
      router.push({ name: `confirmAgree${isEditMode.value ? 'Edit' : ''}` });
    };

    const completeFourthStepFromStore = async () => {
      // ESTE METODO SIMPLEMENTETE COMPLETA AL RECARGAR LA PAGINA O AL MONTAR EL COMPONENTE
      // SOLO ESO
      Object.keys(store.state.agreement.agree.fourthStep).forEach((key) => {
        if (key === 'day' || key === 'user' || key === 'schedule') {
          // empty
        } else if (visit[key] && store.state.agreement.agree.fourthStep[key].value) {
          visit[key].value = store.state.agreement.agree.fourthStep[key].value;
        }
      });
      const fourthdStepLocalStorage = await hasLocalStorage({ key: 'agreeFourthStep' });
      if (thirdStep.value.all) { // eligio todos los recolectores
        if (!objEmpty(store.state.agreement.agree.fourthStep.user.value)) {
          const { user: userLs, schedule: scheduleLs, day: dayLs } = fourthdStepLocalStorage;
          const { user: userStore } = store.state.agreement.agree.fourthStep;
          if (userLs && userLs.value?.id === userStore.value?.id) {
            if (options.value.allCollectors.some((val) => val.id === userLs.value.id)) {
              const { id } = userLs.value;
              await getUserSchedules(id);
              visit.user.value = userLs.value;
              visit.day.value = dayLs.value;
              visit.schedule.value = scheduleLs.value;
            }
          }
        }
      } else if (thirdStep.value.pm) { // eligio pm
        if (fourthdStepLocalStorage) {
          await store.dispatch('agreement/setPropAgree', { key: 'fourthStep', index: 'user', value: { value: store.state.agreement.agree.options.pm } });
          const { user: userLs, schedule: scheduleLs, day: dayLs } = fourthdStepLocalStorage;
          const { user: userStore } = store.state.agreement.agree.fourthStep;
          if (userLs && userLs.value?.id === userStore.value?.id) {
            const { id } = userStore.value;
            await getUserSchedules(id);
            visit.user.value = userLs.value;
            visit.day.value = dayLs.value;
            visit.schedule.value = scheduleLs.value;
          } else {
            const { id } = store.state.agreement.agree.options.pm;
            await getUserSchedules(id);
            visit.user.value = store.state.agreement.agree.options.pm;
          }
        }
      } else if (fourthdStepLocalStorage || !objEmpty(store.state.agreement.agree.options.collector)) { // eligio collector, si llega hasta aca porque no entro en las opciones de arriba
        // ACA COMPLETO EL USUARIO SELECCIONADO COMO RECOLECTOR Y TRATO DE COMPLETAR SUS HORARIOS
        await store.dispatch('agreement/setPropAgree', { key: 'fourthStep', index: 'user', value: { value: store.state.agreement.agree.options.collector } });
        const { user: userLs, schedule: scheduleLs, day: dayLs } = fourthdStepLocalStorage;
        const { user: userStore } = store.state.agreement.agree.fourthStep;
        if (userLs?.value?.id === userStore?.value?.id) {
          const { id } = userStore.value;
          await getUserSchedules(id);
          visit.user.value = userLs.value;
          visit.day.value = dayLs.value;
          visit.schedule.value = scheduleLs.value;
        } else {
          const { id } = store.state.agreement.agree.options.collector;
          await getUserSchedules(id);
          visit.user.value = store.state.agreement.agree.options.collector;
        }
      }
      return visit;
    };

    onMounted(async () => {
      if (!await hasLocalStorage({ key: 'agreeThirdStep' })) {
        router.push({ name: `agreeThirdStep${isEditMode.value ? 'Edit' : ''}` });
      }

      await store.dispatch('agreement/tryCompleteStoreFourthStepFromLs');

      if (!store.getters['agreement/completeFirstStep']) {
        await store.dispatch('agreement/tryCompleteStoreFirstStepFromLs');
        if (objEmpty(options.value)) {
          await getOptions();
        }
      } else if (objEmpty(options.value)) {
        await getOptions();
      }

      // Necesito el store del tercer paso para poder mostrar el tipo de visita escogido
      if (!store.getters['agreement/completeThirdStep']) {
        await store.dispatch('agreement/tryCompleteStoreThirdStepFromLs');
      }

      // necesito completar el cliente para poder ver si tengo que completar el email y el numero de telefono
      if (!isEditMode.value) {
        if (!store.state.agreement.customer) {
          await store.dispatch('agreement/tryCompleteStoreCustomerFromLs');
        }
        if (store.state.agreement.customer && !objEmpty(store.state.agreement.customer)) {
          const {
            email, telefono_cel4, telefono_cel5, telefono_cel6,
          } = store.state.agreement.customer;

          if (email) {
            visit.email.value = email;
          }
          if (telefono_cel4) {
            visit.phoneNumber.value = telefono_cel4;
          } else if (telefono_cel5) {
            visit.phoneNumber.value = telefono_cel5;
          } else if (telefono_cel6) {
            visit.phoneNumber.value = telefono_cel6;
          }
        }
      } else { // aca esta editando
        if (!store.state.agreement.agreement) {
          await store.dispatch('agreement/tryCompleteStoreAgreementFromLs');
        }
        const { email, phoneNumber, prefix } = store.state.agreement.agreement;
        if (email) {
          visit.email.value = email;
        }
        if (phoneNumber) {
          visit.phoneNumber.value = phoneNumber;
        }
        if (prefix) {
          visit.prefix.value = prefix.prefix;
        }
      }
      if ((store.getters['agreement/completeFourthStep'] && !completedSection.value) || (!store.getters['agreement/completeFourthStep'] && !completedSection.value)) {
        // ya completo y seguramente volvio para atras o esta recargando la pagina
        await completeFourthStepFromStore();
      } else if (!thirdStep.value.pm && !thirdStep.value.all) { // eligio collector
        visit.user.value = store.state.agreement.agree.options.collector;
        const { id } = visit.user.value;
        await getUserSchedules(id);
      } else if (thirdStep.value.pm) { // eligio pm
        visit.user.value = store.state.agreement.agree.options.pm;
        const { id } = visit.user.value;
        await getUserSchedules(id);
      }
      // DEBO COMPLETAR LOS CAMPOS
    });

    const backThirdStep = () => {
      router.push({ name: `agreeThirdStep${isEditMode.value ? 'Edit' : ''}` });
    };

    const invalidPhoneNumber = (val) => {
      let invalid = false;
      const { lengthPhoneNumber } = visit.prefix.value;
      if (val) {
        if (val.length !== lengthPhoneNumber && visit.prefix.value.code !== 'BRA') {
          invalid = `El numero debe tener ${lengthPhoneNumber} digitos`;
        } else {
          invalid = false;
        }
      } else {
        invalid = false;
      }
      return invalid;
    };

    return {
      invalidPhoneNumber,
      backThirdStep,
      thirdStep,
      AGREEMENT_TYPES,
      options,
      visit,
      date,
      disabledDays,
      userWorkSchedules,
      objEmpty,
      emptyWorkSchedules,
      modalDay,
      reverseStr,
      areaCodes,
      openDayModal,
      isZoneOperator,
      isAdmin,
      contact_channels,
      completedSection,
      goConfirm,
      setUser,
      setAreaCode,
    };
  },

};
</script>
