'strict mode';

import $http from '@/services/Api/AxiosInstance';
import { appendForm } from '@/services/Utils/Form';

const ImportRepository = {

  async importEquipment(url, file) {
    let result = {};
    const formData = await appendForm(file);
    try {
      const response = await $http.post(`v1/imports/equipments/${url}`, formData);
      result = response.data;
    } catch (err) {
      if (!err.response) {
        result = {
          errors: {
            message: ['No se ha podido leer correctamente el archivo. Recomendaciones: Asegurese que el archivo este delimitado por comas(,) o punto y coma (;). Tambien puedes intentar copiando el contenido de el archivo y pegandolo en un nuevo archivo '],
          },
        };
      } else {
        result = err.response.data;
      }
    }
    return result;
  },
};

export default ImportRepository;
