function removeAccents(str) {
  if (!str) {
    return '';
  }
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function removeSpaces(str) {
  if (!str) {
    return '';
  }
  return str.replace(/\s/g, '');
}

function removeAccentsAndSpaces(str) {
  if (!str) {
    return '';
  }
  let remove = removeAccents(str);
  remove = removeSpaces(remove);
  return remove;
}

function onlyNumber(str) {
  return str.replace(/[^0-9.]/g, '');
}

function normalizeBA(str) {
  let province;
  const BA = ['GBA SUR', 'GBA NORTE', 'GBA ESTE', 'GBA OESTE', 'GRAN BUENOS AIRES', 'GBA SUR INT', 'GBA-NORTE',
    'GBA-OESTE', 'GBA-SUR', 'BA Buenos Aires', 'BUENOS AIRES INT CENTRO', 'BUENOS AIRES INT NORTE', 'BUENOS AIRES INT OESTE', 'BUENOS AIRES INT SUR', 'BUENOS AIRES INTERIOR'];
  if (BA.includes(str)) {
    province = 'buenos aires';
  } else {
    province = str;
  }

  return province;
}

export {
  removeAccents, removeSpaces, removeAccentsAndSpaces, onlyNumber, normalizeBA,
};
