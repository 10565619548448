<template>
  <div >
    <template v-if="hasCoordinates">
      <div class="map">
        <iframe
        :onload="render"
        width="100%"
        :height="height"
        style="border:0"
        loading="lazy"
        allowfullscreen
        class="mx-auto"
        :src="srcMap">
        </iframe>
        <template v-if="loading" >

          <div :class="['flex justify-center items-center', `${textColor}`, 'loader-map']">
            <svg :class="[`${width}`,'animate-spin','']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </template>
      </div>

    </template>
  </div>
</template>

<script setup>
import {
  defineProps, toRefs, computed, ref, watch, defineEmits,
} from 'vue';

const props = defineProps({
  lat: [String, Number],
  lng: [String, Number],
  height: {
    type: String,
    default: '200px',
  },
  width: {
    type: String,
    default: 'w-12',
  },
  textColor: {
    type: String,
    default: 'text-blue-400',
  },
});

const { lat, lng } = toRefs(props);
const baseUrl = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyDasdhwGs_A9SbZUezcx9VhSSGkxl46bko&q=';
const srcMap = ref('');
const loading = ref(true);
const emit = defineEmits(['renderMap']);

const hasCoordinates = computed(() => {
  if (!lat.value || !lng.value) {
    return false;
  }
  return true;
});

const render = () => {
  loading.value = false;
  emit('renderMap', true);
};

const verifyCoordinates = () => {
  if (lat.value !== '' || lng.value !== '') {
    srcMap.value = `${baseUrl}${lat.value},${lng.value}`;
  }
};

verifyCoordinates();
watch(() => props.lat, (newVal) => {
  if (newVal) {
    srcMap.value = `${baseUrl}${lat.value},${lng.value}`;
  }
});

</script>

<style>

.map{
  position:relative;
}

.loader-map{
  position:absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);
}

</style>
