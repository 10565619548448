<template>
    <div  @click="touch" :id="title" :class="[ iconleft ? 'justify-start cursor-pointer' : 'justify-between', ' list-container_item cursor-pointer', `${listHeight}`]">
      <template v-if="iconleft && icon && icon !== ''">
          <font-awesome-icon :class="[`c-text-${iconColor}`, 'mr-3']" :icon="icon" />
      </template>
      <span :class="[`c-text-${colorLabel}`]">{{label}}</span>
      <template v-if="!iconleft && icon && icon !== ''">
          <font-awesome-icon :class="[`c-text-${iconColor}`]" :icon="icon" />
      </template>
      <slot name="notification" ></slot>
    </div>
    <template v-if="underline">
      <hr>
    </template>
</template>

<script>
import { removeAccentsAndSpaces } from '@/services/Utils/Letters';

export default {
  props: {
    // Color del label
    colorLabel: {
      type: String,
      default() {
        return 'black';
      },
    },
    iconColor: {
      type: String,
      default() {
        return 'gray';
      },
    },
    // Nombre del label
    label: String,
    // Linea abajo
    underline: Boolean,
    // Tipo de accion al click
    type: String,
    // Parametros a recibir para rutas u otros
    params: [Object, Array],
    icon: String,
    iconleft: {
      type: Boolean,
      default() {
        return false;
      },
    },
    listHeight: {
      type: String,
      default: 'grandList',
    },
  },
  data() {
    return {
      title: '',
    };
  },
  methods: {
    touch() {
      if (!this.type) {
        console.error('Debes definir el tipo de accion');
        return;
      }
      switch (this.type) {
        case 'link':
          this.redirect();
          break;
        default:
      }
    },
    redirect() {
      if (!this.params) {
        console.error('Debes enviar parametros');
        return;
      }
      this.$router.push(this.params);
    },
  },
  created() {
    const { label } = this;
    const titleComponent = removeAccentsAndSpaces(label);
    this.title = titleComponent;
  },
};
</script>

<style>
.list-container_item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding:0px 12px;
  border-radius:6px;
  background: rgba(255, 255, 255, 0.986);
  transition: all 0.5s;
  /* box-shadow: 0px 3px 10px 2px rgba(0,0,0,0.1); */
}

.list-container_item:hover {
  background:rgb(243, 236, 236);
}

.grandList{
  min-height:4.3rem;
}

.smallList{
  min-height:2.8rem;
}

.justify-c-start {
    justify-content: flex-start;
}

.justify-c-space-between {
    justify-content: space-between;
}

</style>
