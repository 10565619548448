<template>
  <div>
      <Navbar-Section  titleClass="ml-4 " section="Zonas" />
      <div v-show="emptyAreas.length > 0" class=" flex justify-center my-2 mx-4">
        <TableExpress
        :response="emptyAreas"
        :filters="filters"
        :searchFilter="false"
        :repository="tableRepository"
        @setResponse="emptyAreas = $event"
        @removeFilters="removeFilters()"
        @completeFilters="completeFilters($event)"
        >

          <template v-slot:filter>
            <!-- country -->
            <div class="my-2">
              <BaseSelect
              placeholder="Pais"
              outline="blue"
              :options="countries"
              v-model="filters.country.value"
              :remove="true"
              />
            </div>
            <!-- province -->
            <div class="my-2">
              <BaseSelect
              :placeholder="$t('Provincia')"
              :options="provinces"
              outline="blue"
              v-model="filters.province.value"
              :disabled="!filters.country.value"
              :error="filters.province.error"
              :remove="true"
              />
            </div>
            <!-- locality -->
            <div class="my-2">
              <BaseSelect
              :placeholder="$t('Localidad')"
              :options="localities"
              outline="blue"
              v-model="filters.locality.value"
              :disabled="!filters.province.value"
              :error="filters.locality.error"
              :remove="true"
              />

            </div>
            <!-- codigos postales -->
            <!-- desde cp -->
            <div class="my-2">
              <BaseInput
              placeholder="Desde CP"
              outline="blue"
              v-model:modelValue="filters.postalCodeFrom.value"
              :error="filters.postalCodeFrom.error"
              @error-handle="filters.postalCodeFrom.error = $event"
            />
            </div>
            <!-- hasta cp -->
            <div class="my-2">
              <BaseInput
              placeholder="Hasta CP"
              outline="blue"
              v-model:modelValue="filters.postalCodeTo.value"
              :error="filters.postalCodeTo.error"
              @error-handle="filters.postalCodeTo.error = $event"
            />
            </div>
          </template>
          <template v-slot:columns>
            <Column key="postalCode" :title="$t('Codigo postal')">
              <template v-slot:action="{content, index}">
                <div
                @click="openEmptyArea = true; currentEmptyArea = content; currentEmptyArea.index = index"
                class="w-8 mx-auto rounded-full text-white  px-2 py-1 mx-1 bg-gray-400 cursor-pointer">
                  <font-awesome-icon icon="edit" />
                </div>
              </template>
            </Column>
            <Column key="postalCode" :title="$t('Codigo postal')" />
            <Column key="country" title="Pais" />
            <Column key="province" :title="$t('Provincia')" />
            <Column key="locality" :title="$t('Localidad')" />
          </template>
        </TableExpress>
      </div>
      <Base-modal :modalOverlay="true"  @exit="openEmptyArea = false" :modal="openEmptyArea" headingTitle="Zona vacia">
        <template #content>
          <div class="mx-2 flex flex-col justify-center sm:justify-start ">
            <div v-if="!objEmpty(currentEmptyArea)" class="flex flex-col m-2">
                <div class="border border-gray-200 px-2 py-2 rounded-tl-lg rounded-tr-lg">
                    Pais : {{currentEmptyArea.country}}
                </div>
                <div class="border border-gray-200 px-2 py-2">
                    Provincia : {{currentEmptyArea.province}}
                </div>
                <div class="border border-gray-200 px-2 py-2">
                    Localidad : {{currentEmptyArea.locality}}
                </div>
                <div class="border border-gray-200 px-2 py-2 rounded-br-lg rounded-bl-lg" >
                    Cp : {{currentEmptyArea.postalCode}}
                </div>
            </div>
            <!-- type coverage -->
              <div class="m-2" style="max-width: 17.5rem;">
                  <BaseSelect
                  placeholder="Tipo de cobertura"
                  :options="COVERAGE_TYPE"
                  outline="blue"
                  v-model="emptyArea.coverage_type.value"
                  :error="emptyArea.coverage_type.error"
                  />
              </div>
              <div v-if="emptyArea.coverage_type.value" class=" flex flex-row justify-start flex-wrap">
                <!-- users -->
                <div v-if="isUser" class="m-2">
                  <BaseSelect
                  placeholder="Usuario"
                  :options="users"
                  label="firstName"
                  outline="blue"
                  v-model="emptyArea.user.value"
                  :error="emptyArea.user.error"
                  :filters="['firstName','lastName','province.name']"
                  @error-handle="emptyArea.user.error = $event"
                  >
                  <template v-slot:itemList="{item}">
                    <div class="item-select ">

                      {{item.firstName}} {{item.lastName}} {{item.fantasyName ? `- ${item.fantasyName}`: '' }}
                      <strong class="text-sm">{{item.province?.name?.toLowerCase()}}</strong>
                    </div>
                  </template>
                  </BaseSelect>
                </div>
                <!-- correo o terminal -->
                <template v-if="!isUser">
                  <div class="flex flex-col my-4">
                      <div class="font-bold mx-1 mb-2 mx-2">
                        Debes ingresar la direccion y  coordenadas ingresando la direccion en google maps
                      </div>
                      <!-- direccion -->
                      <div class="m-2 max-w-sm">
                          <BaseInput
                          placeholder="Direccion"
                          outline="blue"
                          v-model:modelValue="emptyArea.address.value"
                          :error="emptyArea.address.error"
                          @error-handle="emptyArea.address.error = $event"
                          />
                      </div>
                      <div class="flex flex-row flex-wrap ">
                        <!-- lat -->
                        <div class="m-2">
                          <BaseInput
                          :placeholder="$t('Latitud')"
                          outline="blue"
                          v-model:modelValue="emptyArea.lat.value"
                          :error="emptyArea.lat.error"
                          @error-handle="emptyArea.lat.error = $event"
                          />
                        </div>
                        <!-- lng -->
                        <div class="m-2">
                          <BaseInput
                          :placeholder="$t('Longitud')"
                          outline="blue"
                          v-model:modelValue="emptyArea.lng.value"
                          :error="emptyArea.lng.error"
                          @error-handle="emptyArea.lng.error = $event"
                          />
                        </div>
                      </div>
                  </div>
                </template>
              </div>
              <div v-if="!message" class="m-2  flex flex-row flex-wrap">
                <div class="mr-2 w-28">
                    <BaseButton :loading="loading" :disabled="loading || loading" @click="save()" background="bg-blue-400"  textColor="text-white"  :text="$t('Guardar')" />
                </div>
              </div>
              <!-- simpleAlert message -->
              <div v-if="message" class="m-2 mx-2">
                <SimpleAlert color="green" border="border-b-4">
                  <template v-slot:text>
                    <p class="font-bold">{{message}}</p>
                  </template>
                </SimpleAlert>
              </div>
          </div>
        </template>
      </Base-modal>
  </div>
</template>

<script>
import {
  ref, computed, reactive, watch,
} from 'vue';
// utilidades
import CoverageRepository from '@/repositories/Core/CoverageRepository';
import { usersInfoRepo } from '@/repositories/User/UserRepository';
import locationHook from '@/hooks/core/locationHook';
import { objEmpty } from '@/services/Utils/Objects';
import { COVERAGE_TYPE, COVERAGE_TYPES } from '@/services/Utils/Utils';
import { USER_STATES } from '@/services/Utils/State';
//

export default {
  setup() {
    const emptyAreas = ref([]);
    const tableRepository = CoverageRepository.emptyCoverages;

    // filtros

    const openEmptyArea = ref(false);
    const filters = reactive({
      country: {
        value: null,
      },
      province: {
        value: null,
      },
      locality: {
        value: null,
      },
      user: {
        value: null,
      },
      coverage_type: {
        value: null,
      },
      postalCodeFrom: {
        value: '',
      },
      postalCodeTo: {
        value: '',
      },

    });

    const completeFilters = (data) => {
      // cuando recarga la pagina y la url contiene estos filtros, el componente tableExpress emite este evento
      if (data.country) filters.country.value = data.country;
      if (data.province) filters.province.value = data.province;
      if (data.locality) filters.locality.value = data.locality;
      if (data.postalCodeFrom) filters.postalCodeFrom.value = data.postalCodeFrom;
      if (data.postalCodeTo) filters.postalCodeTo.value = data.postalCodeTo;
    };
    const removeFilters = () => {
      // Este evento lo emite el tableExpress al remover los filtros
      if (filters.country.value) filters.country.value = {};
      if (filters.province.value) filters.province.value = {};
      if (filters.locality.value) filters.locality.value = {};
      if (filters.postalCodeFrom.value) filters.postalCodeFrom.value = '';
      if (filters.postalCodeTo.value) filters.postalCodeTo.value = '';
    };

    const chosenCountry = computed(() => filters.country.value);
    const chosenProvince = computed(() => filters.province.value);
    const {
      countries, getCountries, provinces, localities,
    } = locationHook({ chosenCountry, chosenProvince });
    getCountries();

    const users = ref([]);
    const getUsers = async (params) => {
      const response = await usersInfoRepo(params);
      users.value = response;
    };

    const currentEmptyArea = ref({});
    const emptyArea = reactive({
      coverage_type: {
        value: null,
        error: '',
      },
      user: {
        value: null,
        error: '',
      },
      address: {
        value: '',
        error: '',
      },
      lat: {
        value: '',
        error: '',
      },
      lng: {
        value: '',
        error: '',
      },
    });

    watch(() => openEmptyArea.value, (newVal) => {
      if (newVal) {
        getUsers({ user_state: USER_STATES.ACTIVO, country: currentEmptyArea.value.country_id });
      } else {
        // resteo todo
        currentEmptyArea.value = {};
        emptyArea.coverage_type.value = null;
        emptyArea.user.value = null;
        emptyArea.address.value = '';
        emptyArea.lat.value = '';
        emptyArea.lng.value = '';
      }
    });

    const message = ref('');
    const loading = ref(false);

    const isUser = computed(() => {
      const { value } = emptyArea.coverage_type;
      if (value && (COVERAGE_TYPES.COLLECTOR === value || COVERAGE_TYPES.COMMERCE === value)) {
        return true;
      }
      return false;
    });

    const errorHandle = (errors) => {
      if (errors) {
        Object.entries(errors).forEach((error) => {
          const [key, val] = error;
          if (emptyArea[key]) {
            const [errorMessage] = val;
            emptyArea[key].error = errorMessage;
          }
        });
      } else {
        Object.entries(emptyArea).forEach((values) => {
          const [key] = values;
          if (emptyArea[key]) {
            emptyArea[key].error = '';
          }
        });
      }
    };

    const save = async () => {
      loading.value = true;
      const object = {
        postalCode: [currentEmptyArea.value.postalCode],
        country: currentEmptyArea.value.country_id,
        province: currentEmptyArea.value.province_id,
        locality: currentEmptyArea.value.locality_id,
        coverage_type: emptyArea.coverage_type.value,
      };

      if (isUser.value) {
        object.user = emptyArea.user.value;
      } else {
        object.address = emptyArea.address.value;
        object.lat = emptyArea.lat.value;
        object.lng = emptyArea.lng.value;
      }

      const response = await CoverageRepository.save(object);
      if (response.errors) {
        errorHandle(response.errors);
      } else {
        if (response.length > 0) {
          emptyAreas.value.splice(currentEmptyArea.value.index, 1);
          setTimeout(() => {
            openEmptyArea.value = false;
          }, 1500);
        }

        message.value = 'Guardado correctamente';
        setTimeout(() => {
          message.value = '';
        }, 1500);
      }

      loading.value = false;
    };

    return {
      emptyAreas,
      tableRepository,
      filters,
      countries,
      provinces,
      localities,
      users,
      COVERAGE_TYPE,
      COVERAGE_TYPES,
      objEmpty,
      completeFilters,
      removeFilters,
      openEmptyArea,
      currentEmptyArea,
      emptyArea,
      isUser,
      message,
      loading,
      save,
    };
  },
};
</script>

<style>

</style>
