<template>
  <div>
    <Base-modal :modalOverlay="true"  @exit="backSecondStep()" :modal="true" :headingTitle="$t('Opciones disponbiles')">
        <template #content>
          <div class="flex flex-col justify-center items-center mx-2">
            <!-- loader -->
            <div v-if="loadingOptions" class=" px-2 flex flex-col justify-center ">
                <div  style="height:9rem;" class=" mx-auto my-4">
                    <svg :class="['animate-spin', 'w-24','text-blue-400']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>
            <!-- opciones disponibles -->
            <div v-else class="flex flex-row flex-wrap mt-1 mb-3 justify-center">
              <!-- visita -->
                <div
                @click="setAgreementType(AGREEMENT_TYPES.VISIT, false, false)"
                :class="['rounded option m-2 p-2 w-32 flex flex-col items-center justify-center', agreement_type === AGREEMENT_TYPES.VISIT && !selectedAll && !selectedPm ? 'border border-black' : '']"
                v-if="options && options.collector && !objEmpty(options.collector)">
                    <div>{{ $t('Recolector') }}</div>
                    <div class="mt-2"><font-awesome-icon icon="truck-pickup" /></div>
                </div>

                <!-- todos -->
                <div
                @click="setAgreementType(AGREEMENT_TYPES.VISIT, false,true)"
                :class="['rounded option m-2 p-2 w-32 flex flex-col items-center justify-center', agreement_type === AGREEMENT_TYPES.VISIT && selectedAll ? 'border border-black' : '']"
                v-if="options?.allCollectors?.length > 0">
                    <div>{{ $t('Todos') }}</div>
                    <div class="mt-2"><font-awesome-icon icon="user" /></div>
                </div>
                <!-- pm -->
                <div
                @click="setAgreementType(AGREEMENT_TYPES.VISIT, true,false)"
                :class="['rounded option m-2 p-2 w-32 flex flex-col items-center justify-center', agreement_type === AGREEMENT_TYPES.VISIT && selectedPm ? 'border border-black' : '']"
                v-if="options?.pm && !objEmpty(options.pm)">
                    <div>Pm</div>
                    <div class="mt-2"><font-awesome-icon icon="location-arrow" /></div>
                </div>
                <!-- comercio -->
                <div
                @click="setAgreementType(AGREEMENT_TYPES.COMMERCE)"
                :class="['rounded option m-2 p-2 w-32 flex flex-col items-center justify-center', agreement_type === AGREEMENT_TYPES.COMMERCE ? 'border border-black' : '']"
                v-if="options?.commerces?.length > 0">
                    <div>{{ $t('Comercio') }}</div>
                    <div class="mt-2"><font-awesome-icon icon="store" /></div>
                </div>
                <!-- correo -->
                <div
                @click="setAgreementType(AGREEMENT_TYPES.CORREO)"
                :class="['rounded option m-2 p-2 w-32 flex flex-col items-center justify-center', agreement_type === AGREEMENT_TYPES.CORREO ? 'border border-black' : '']"
                v-if="options?.correo?.length > 0">
                    <div>{{ $t('Correo') }}</div>
                    <div class="mt-2"><font-awesome-icon icon="store-alt" /></div>
                </div>
                <!-- terminal -->
                <div
                @click="setAgreementType(AGREEMENT_TYPES.TERMINAL)"
                :class="['rounded option m-2 p-2 w-32 flex flex-col items-center justify-center', agreement_type === AGREEMENT_TYPES.TERMINAL ? 'border border-black' : '']"
                v-if="options?.terminal?.length > 0">
                    <div>{{ $t('Terminal') }}</div>
                    <div class="mt-2"><font-awesome-icon icon="bus" /></div>
                </div>
            </div>
            <!-- avanzar, atras -->
            <div class="flex flex-row flex-wrap justify-center">
              <div class="my-2 w-44 mr-2">
                <BaseButton
                  @click="goFourthStep()"
                  :disabled="empty(agreement_type)"
                  padding="p-1 px-2"
                  textColor="text-white"
                  background="bg-black"
                  :text="$t('Siguiente')"
                />
              </div>
              <div class="my-2 w-44">
                <BaseButton
                  @click="backSecondStep()"
                  :text="$t('Atras')"
                />
              </div>
            </div>
          </div>
        </template>
    </Base-modal>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted, ref, computed } from 'vue';

// utilididaes
import { objEmpty, empty, hasLocalStorage } from '@/services/Utils/Objects';
import { AGREEMENT_TYPES } from '@/services/Utils/Utils';
import { optionsRepo } from '@/repositories/Core/AgreementRepository';
//
import coreHook from '@/hooks/core/coreHook';

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const { isEditMode } = coreHook();

    const loadingOptions = ref(false);
    const options = ref({});
    const getOptions = async () => {
      loadingOptions.value = true;
      let params = {};
      // esta condicion es porque cuando se recarga la pagina, puede ser que no este completado aun los datos de los pasos anteriores
      // y si no stan completados, lo pido para asi poder llenar los params que van al backend en busca de opciones disponibles
      const {
        country, locality, province, postalCode, identificacion,
      } = store.state.agreement.agree.firstStep;
      params = {
        country: country.value,
        province: province.value,
        locality: locality.value,
        postalCode: postalCode.value,
        identificacion: identificacion.value,
      };
      const response = await optionsRepo(params);
      options.value = response.options;
      loadingOptions.value = false;
      store.dispatch('agreement/setPropAgree', { key: 'options', value: options.value });
    };
    onMounted(async () => {
      if (!await hasLocalStorage({ key: 'agreeSecondStep' })) { // a esta altura debe haber algo en el localstorage,
        if (!isEditMode.value) {
          router.push({ name: 'agreeSecondStep' });
        } else {
          router.push({ name: 'agreeSecondStepEdit' });
        }
      }

      if (!store.getters['agreement/completeFirstStep']) {
        await store.dispatch('agreement/tryCompleteStoreFirstStepFromLs');
        if (objEmpty(options.value)) {
          await getOptions();
        }
      } else if (objEmpty(options.value)) {
        await getOptions();
      }

      await store.dispatch('agreement/tryCompleteStoreThirdStepFromLs');
    });

    const agreement_type = computed(() => store.state.agreement.agree.thirdStep.agreement_type);
    const selectedPm = computed(() => store.state.agreement.agree.thirdStep.pm);
    const selectedAll = computed(() => store.state.agreement.agree.thirdStep.all);
    const setAgreementType = async (type, pm = false, all = false) => {
      await store.dispatch('agreement/setThirdStep', { agreement_type: type, pm, all });
    };

    const goFourthStep = async () => {
      if (agreement_type.value === AGREEMENT_TYPES.VISIT) {
        router.push({ name: `agreeFourthStepVisit${isEditMode.value ? 'Edit' : ''}` });
      } else if (agreement_type.value === AGREEMENT_TYPES.COMMERCE) {
        router.push({ name: `agreeFourthStepCommerce${isEditMode.value ? 'Edit' : ''}` });
      } else if (agreement_type.value === AGREEMENT_TYPES.CORREO || agreement_type.value === AGREEMENT_TYPES.TERMINAL) {
        router.push({ name: `agreeFourthStepPoint${isEditMode.value ? 'Edit' : ''}` });
      }
    };

    const backSecondStep = () => {
      if (!store.getters['agreement/completeFirstStep']) {
        router.push({ name: `agreeFirstStep${isEditMode.value ? 'Edit' : ''}` });
      } else {
        router.push({ name: `agreeSecondStep${isEditMode.value ? 'Edit' : ''}` });
      }
    };
    return {
      backSecondStep, loadingOptions, objEmpty, empty, options, AGREEMENT_TYPES, setAgreementType, agreement_type, goFourthStep, selectedPm, selectedAll,
    };
  },

};
</script>

<style>
.option{
  box-shadow: 1px 1px 4px 2px rgba(0,0,0,0.15);
  cursor: pointer;
}

.option:hover{
  box-shadow: 1px 3px 6px 4px rgba(0,0,0,0.1);

}

</style>
