<template>
  <div>
      <Navbar-Section  titleClass="ml-4 " section="Zonas" />
      <div  class=" flex justify-center my-2 mx-4">
        <TableExpress
        :response="coverages"
        :filters="filters"
        :searchFilter="false"
        :repository="tableRepository"
        :removeDuplicates="true"
        model="coverages"
        @setResponse="coverages = $event"
        @removeFilters="removeFilters()"
        @completeFilters="completeFilters($event)"
        >
          <template v-slot:subContent>
            <div class="flex flex-row flex-wrap my-1 xl:my-0">

            <div class="mx-2">
              <BaseButton type="route" :fullRoute="{name: 'deleteCoverage'}" icon="trash-alt"  :text="$t('Eliminar cobertura')" />
            </div>
            <div class="mx-2">
              <BaseButton type="route" :fullRoute="{name: 'transferCoverage'}" icon="exchange-alt"  :text="$t('Transferir cobertura')" />
            </div>
            <div class="mx-2">
              <BaseButton type="route" :fullRoute="{name: 'copyCoverage'}" icon="copy"  :text="$t('Copiar cobertura')" />
            </div>
            <div class="mx-2">
              <BaseButton type="route" :fullRoute="{name: 'newCoverage'}" icon="plus"  :text="$t('Crear cobertura')" />
            </div>
             </div>
          </template>
          <template v-slot:filter>
                <!-- country -->
                <div class="my-2">
                  <BaseSelect
                  placeholder="Pais"
                  outline="blue"
                  :options="countries"
                  v-model="filters.country.value"
                  :remove="true"
                  />
                </div>
                <!-- province -->
                <div class="my-2">
                  <BaseSelect
                  :placeholder="$t('Provincia')"
                  :options="provinces"
                  outline="blue"
                  v-model="filters.province.value"
                  :disabled="!filters.country.value"
                  :error="filters.province.error"
                  :remove="true"
                  />
                </div>
                <!-- locality -->
                <div class="my-2">
                  <BaseSelect
                  :placeholder="$t('Localidad')"
                  :options="localities"
                  outline="blue"
                  v-model="filters.locality.value"
                  :disabled="!filters.province.value"
                  :error="filters.locality.error"
                  ref="refLocality"
                  :remove="true"
                  />
                </div>
                <!-- users -->
                <div class="my-2">
                  <BaseSelect
                  placeholder="Usuarios"
                  :options="users"
                  label="fullName"
                  outline="blue"
                  v-model="filters.user.value"
                  :error="filters.user.error"
                  ref="refUser"
                  :remove="true"
                  :filters="['fullName','province.name']"
                  >
                  <template v-slot:itemList="{item}">
                    <div class="item-select">
                      {{item.fullName}}  <strong>{{item.province?.name?.toLowerCase()}}</strong>
                    </div>
                  </template>
                  </BaseSelect>
                </div>
                <!-- type coverage -->
                <div class="my-2">
                  <BaseSelect
                  placeholder="Tipo"
                  :options="COVERAGE_TYPE"
                  outline="blue"
                  v-model="filters.coverage_type.value"
                  :error="filters.coverage_type.error"
                  ref="refCoverageType"
                  :remove="true"
                  />
                </div>
                 <!-- codigos postales -->
                <div class="my-2">
                  <BaseInput
                  placeholder="Desde CP"
                  outline="blue"
                  v-model:modelValue="filters.postalCodeFrom.value"
                  :error="filters.postalCodeFrom.error"
                  @error-handle="filters.postalCodeFrom.error = $event"
                />
                </div>
                <div class="my-2">
                  <BaseInput
                  placeholder="Hasta CP"
                  outline="blue"
                  v-model:modelValue="filters.postalCodeTo.value"
                  :error="filters.postalCodeTo.error"
                  @error-handle="filters.postalCodeTo.error = $event"
                />
                </div>
          </template>
          <template v-slot:columns>
            <Column key="id" :title="$t('Accion')">
              <template v-slot:action="{content}">
                <router-link
                :to="{name:'editCoverage', params : { id: content.id}}"
                :class="['w-22 rounded-full text-white  px-2 py-1 mx-1', 'bg-gray-400']">
                  <font-awesome-icon icon="edit" />
                </router-link>
              </template>
            </Column>
            <Column key="postalCode" :title="$t('Cp')" />
            <Column key="country.name" title="Pais" />
            <Column key="province.name" :title="$t('Provincia')" />
            <Column key="locality.name" :title="$t('Localidad')" />
            <Column key="user.firstName" :title="$t('Usuario')">
              <template v-slot:action="{content}">
                <span v-if="content.coverage_type_id === COVERAGE_TYPES.COLLECTOR" >
                  <strong>{{content.user.firstName}} {{content.user.lastName}}</strong>
                </span>
                <span v-else-if="content.coverage_type_id === COVERAGE_TYPES.COMMERCE" >
                  <strong>{{content.user.firstName}} {{content.user.lastName}} {{content.user.fantasyName}}</strong>
                </span>
                <span v-else> </span>
              </template>
            </Column>
            <Column key="coverage_type.name" :title="$t('Tipo')" />
            <Column key="id" title="ID" />
          </template>
        </TableExpress>
      </div>
      <router-view
      @addResponse="addResponse($event)"
      @updateResponse="updateResponse($event)"
      @removeResponse="removeResponse($event)"
       >
      </router-view>
  </div>
</template>

<script>
import {
  ref, computed, reactive,
} from 'vue';

// utilidades
import CoverageRepository from '@/repositories/Core/CoverageRepository';
import { usersInfoRepo } from '@/repositories/User/UserRepository';
import locationHook from '@/hooks/core/locationHook';
import { objEmpty } from '@/services/Utils/Objects';
import { COVERAGE_TYPE, COVERAGE_TYPES } from '@/services/Utils/Utils';
//

export default {
  components: {

  },
  setup() {
    const coverages = ref([]);
    const tableRepository = CoverageRepository.coverages;

    // filtros
    const filters = reactive({
      country: {
        value: null,
      },
      province: {
        value: null,
      },
      locality: {
        value: null,
      },
      user: {
        value: null,
      },
      coverage_type: {
        value: null,
      },
      postalCodeFrom: {
        value: '',
      },
      postalCodeTo: {
        value: '',
      },
    });

    const completeFilters = (data) => {
      // cuando recarga la pagina y la url contiene estos filtros, el componente tableExpress emite este evento
      if (data.country) filters.country.value = data.country;
      if (data.province) filters.province.value = data.province;
      if (data.locality) filters.locality.value = data.locality;
      if (data.user) filters.user.value = data.user;
      if (data.coverage_type) filters.coverage_type.value = data.coverage_type;
      if (data.postalCodeFrom) filters.postalCodeFrom.value = data.postalCodeFrom;
      if (data.postalCodeTo) filters.postalCodeTo.value = data.postalCodeTo;
    };
    const removeFilters = () => {
      // Este evento lo emite el tableExpress al remover los filtros
      if (filters.country.value) filters.country.value = null;
      if (filters.province.value) filters.province.value = null;
      if (filters.locality.value) filters.locality.value = null;
      if (filters.user.value) filters.user.value = null;
      if (filters.coverage_type.value) filters.coverage_type.value = null;
      if (filters.postalCodeFrom.value) filters.postalCodeFrom.value = '';
      if (filters.postalCodeTo.value) filters.postalCodeTo.value = '';
    };

    const chosenCountry = computed(() => filters.country.value);
    const chosenProvince = computed(() => filters.province.value);
    const {
      countries, getCountries, provinces, localities,
    } = locationHook({ chosenCountry, chosenProvince });
    getCountries();

    const users = ref([]);
    const getUsers = async () => {
      const response = await usersInfoRepo();
      users.value = response;
    };
    getUsers();

    const addResponse = (data) => {
      coverages.value = [...data, ...coverages.value];
    };
    const updateResponse = (data) => {
      data.forEach((val) => {
        const index = coverages.value.findIndex((f) => f.id === val.id);
        if (index > -1) {
          coverages.value[index] = val;
        }
      });
    };

    const removeResponse = (data) => {
      let remove;
      if (data.user_id) {
        remove = coverages.value.filter((val) => val.user_id !== data.user_id);
      } else {
        remove = coverages.value.filter((val) => val.id !== data.id);
      }

      if (remove.length > 0) {
        coverages.value = remove;
      }
    };

    return {
      coverages,
      tableRepository,
      filters,
      countries,
      provinces,
      localities,
      users,
      COVERAGE_TYPE,
      COVERAGE_TYPES,
      objEmpty,
      completeFilters,
      removeFilters,
      updateResponse,
      addResponse,
      removeResponse,
    };
  },
};
</script>

<style>

</style>
