<template>
  <div>
    <Base-modal :modalOverlay="true" type="full-screen"   @exit="backThirdStep()" :modal="true" :headingTitle="$t('Datos de visita')">
        <template #content>
            <div class="flex flex-col  m-2">
                <!-- elegir recoelctor -->
                    <div class="flex flex-col mb-2" >
                        <div class="mb-2">
                            <span class="text-gray-500 text-sm">
                            Elige el comercio
                            </span>
                        </div>
                        <div style="max-width:20rem;">
                            <BaseSelect
                              placeholder="Comercio"
                              :options="options.commerces"
                              label="user.firstName"
                              outline="blue"
                              :modelValue="commerce.user.value"
                              trackBy="user_id"
                              @content-change="setUser($event)"
                              :error="commerce.user.error"
                              :filters="['user.firstName','user.lastName']"
                              @error-handle="commerce.user.error = $event"
                              :return-object="true"
                              >
                              <template v-slot:itemList="{item}">
                              <div class="item-select ">
                                  {{item.user.firstName}} {{item.user.lastName}}
                                  <strong class="text-sm">{{item.user.locality.name.toLowerCase()}}</strong>
                              </div>
                              </template>
                            </BaseSelect>
                        </div>
                    </div>

                    <div class="my-4 max-w-md">
                      <GoogleIframeMap :lat="commerce.user.value.user?.lat" :lng="commerce.user.value.user?.lng" />
                    </div>
                    <!-- nro de telefono -->
                    <div class="mt-2 flex flex-col">
                        <span class="text-gray-500 text-sm">
                            {{$t('Nro de telefono')}}
                        </span>
                        <div class="my-2 flex flex-row flex-wrap">
                            <div class="w-28 mr-4 mt-1 sm:mt-0">
                                <BaseSelect
                                :placeholder="$t('Codigo Pais')"
                                outline="blue"
                                :options="areaCodes"
                                label="prefix"
                                trackBy="prefix"
                                :modelValue="commerce.prefix.value"
                                @content-change="setAreaCode($event)"
                                :error="commerce.prefix.error"
                                @error-handle="commerce.prefix.error = $event"
                                :returnObject="true"
                                />
                            </div>
                            <div class="mt-1 sm:mt-0">
                                <BaseInput
                                type='number'
                                :placeholder="$t('Nro Telefono')"
                                outline="blue"
                                v-model:modelValue="commerce.phoneNumber.value"
                                :error="commerce.phoneNumber.error"
                                @error-handle="commerce.phoneNumber.error = $event"
                                :rules="[invalidPhoneNumber]"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- email y quien entrega -->
                    <div class="flex flex-row flex-wrap">
                        <div class="flex flex-col mr-2">
                          <span class="text-gray-500 text-sm">
                              Email
                          </span>
                          <div class="my-2">
                              <BaseInput
                              :placeholder="$t('Email')"
                              outline="blue"
                              v-model:modelValue="commerce.email.value"
                              :error="commerce.email.error"
                              @error-handle="commerce.email.error = $event"
                              />
                          </div>
                        </div>
                    </div>

                    <!-- canal de contacto -->
                    <div class="mt-2 flex flex-col">
                      <span class="text-gray-500 text-sm">
                          Como lo contacaron
                      </span>
                      <div class="my-2 flex flex-row flex-wrap">
                          <div class="mr-4 mt-1 sm:mt-0">
                            <BaseSelect
                            :placeholder="$t('Canal de contacto')"
                            outline="blue"
                            :options="contact_channels"
                            v-model="commerce.contact_channel.value"
                            :error="commerce.contact_channel.error"
                            @error-handle="commerce.contact_channel.error = $event"
                            />
                          </div>
                      </div>
                    </div>

                    <!-- avanzar, atras -->
                    <div class="flex flex-row flex-wrap mt-2">
                      <div class="my-2 w-44 mr-2">
                        <BaseButton
                        @click="goConfirm()"
                        :disabled="!completedSection"
                        textColor="text-white"
                        background="bg-black"
                        :text="$t('Siguiente')"
                        />
                      </div>
                      <div class="my-2 w-44">
                        <BaseButton
                        @click="backThirdStep()"
                        :text="$t('Atras')"
                        />
                      </div>
                    </div>
            </div>
        </template>
    </Base-modal>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  computed, reactive, onMounted, ref,
} from 'vue';

import GoogleIframeMap from '@/components/Google/GoogleIframeMap.vue';

// utilididaes
import { objEmpty, empty } from '@/services/Utils/Objects';
import { AGREEMENT_TYPES } from '@/services/Utils/Utils';
import { optionsRepo } from '@/repositories/Core/AgreementRepository';
import usualHook from '@/hooks/core/usualHook';
import coreHook from '@/hooks/core/coreHook';
import 'v-calendar/dist/style.css';

export default {
  components: {
    GoogleIframeMap,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { isEditMode } = coreHook();

    const commerce = reactive({
      user: {
        value: {},
        error: '',
      },
      prefix: {
        value: {},
        error: '',
      },
      phoneNumber: {
        value: '',
        error: '',
      },
      email: {
        value: '',
        error: '',
      },
      contact_channel: {
        value: null,
        error: '',
      },

    });
    const date = ref(null);
    const options = computed(() => store.state.agreement.agree.options);

    const setUser = (data) => {
      commerce.user.value = data;
    };
    const setAreaCode = (data) => {
      commerce.prefix.value = data;
      if (!empty(commerce.phoneNumber.value)) {
        /* eslint no-use-before-define: ["error", { "variables": false }] */
        const invalid = invalidPhoneNumber(commerce.phoneNumber.value);
        if (invalid) {
          commerce.phoneNumber.error = invalid;
        } else {
          commerce.phoneNumber.error = '';
        }
      }
    };

    const invalidPhoneNumber = (val) => {
      let invalid = false;
      const { lengthPhoneNumber } = commerce.prefix.value;
      if (val) {
        // if (val.length !== lengthPhoneNumber && visit.prefix.value.code !== 'BRA') {
        //   invalid = `El numero debe tener ${lengthPhoneNumber} digitos`;
        // } else {
        //   invalid = false;
        // }
        invalid = false;
      } else {
        invalid = false;
      }
      return invalid;
    };

    const {
      getAreaCodes, areaCodes, contact_channels, getContactChannels,
    } = usualHook();
    getAreaCodes();
    getContactChannels();

    const thirdStep = computed(() => store.state.agreement.agree.thirdStep);
    const getOptions = async () => {
      let params = {};
      // esta condicion es porque cuando se recarga la pagina, puede ser que no este completado aun los datos de los pasos anteriores
      // y si no stan completados, lo pido para asi poder llenar los params que van al backend en busca de opciones disponibles
      const {
        country, locality, province, postalCode, identificacion,
      } = store.state.agreement.agree.firstStep;
      params = {
        country: country.value,
        province: province.value,
        locality: locality.value,
        postalCode: postalCode.value,
        identificacion: identificacion.value,
      };
      const response = await optionsRepo(params);
      store.dispatch('agreement/setPropAgree', { key: 'options', value: response.options });
    };

    const completedSection = computed(() => !objEmpty(commerce.user.value)
              && !objEmpty(commerce.prefix.value)
              && commerce.phoneNumber.value
              && commerce.contact_channel.value);

    const goConfirm = async () => {
      await store.dispatch('agreement/setFourthStep', commerce);
      router.push({ name: `confirmAgree${isEditMode.value ? 'Edit' : ''}` });
    };

    const completeFourthStepFromStore = async () => {
      Object.keys(store.state.agreement.agree.fourthStep).forEach((key) => {
        if (commerce[key]) {
          if (key === 'user') {
            if (options.value.commerces.some((val) => val.user_id === store.state.agreement.agree.fourthStep[key].value.id)) {
              commerce[key].value = store.state.agreement.agree.fourthStep[key].value;
              commerce[key].value.user_id = store.state.agreement.agree.fourthStep[key].value.id;
            }
          } else if (store.state.agreement.agree.fourthStep[key].value) {
            commerce[key].value = store.state.agreement.agree.fourthStep[key].value;
          }
        }
      });
      return commerce;
    };

    onMounted(async () => {
      if (!store.getters['agreement/completeFirstStep']) {
        await store.dispatch('agreement/tryCompleteStoreFirstStepFromLs');
        if (objEmpty(options.value)) {
          await getOptions();
        }
      } else if (objEmpty(options.value)) {
        await getOptions();
      }

      if (!store.getters['agreement/completeFourthStep']) {
        await store.dispatch('agreement/tryCompleteStoreFourthStepFromLs');
      }

      // Necesito el store del tercer paso para poder mostrar el tipo de visita escogido
      if (!store.getters['agreement/completeThirdStep']) {
        await store.dispatch('agreement/tryCompleteStoreThirdStepFromLs');
      }

      // necesito completar el cliente para poder ver si tengo que completar el email y el numero de telefono
      if (!isEditMode.value) {
        if (!store.state.agreement.customer) {
          await store.dispatch('agreement/tryCompleteStoreCustomerFromLs');
        }
        if (store.state.agreement.customer && !objEmpty(store.state.agreement.customer)) {
          const {
            email, telefono_cel4, telefono_cel5, telefono_cel6,
          } = store.state.agreement.customer;
          if (email) {
            commerce.email.value = email;
          }
          if (telefono_cel4) {
            commerce.phoneNumber.value = telefono_cel4;
          } else if (telefono_cel5) {
            commerce.phoneNumber.value = telefono_cel5;
          } else if (telefono_cel6) {
            commerce.phoneNumber.value = telefono_cel6;
          }
        }
      } else { // aca esta editando
        if (!store.state.agreement.agreement) {
          await store.dispatch('agreement/tryCompleteStoreAgreementFromLs');
        }
        const { email, phoneNumber, prefix } = store.state.agreement.agreement;
        if (email) {
          commerce.email.value = email;
        }
        if (phoneNumber) {
          commerce.phoneNumber.value = phoneNumber;
        }
        if (prefix) {
          commerce.prefix.value = prefix.prefix;
        }
      }

      if (store.getters['agreement/completeFourthStep'] && !completedSection.value) {
        await completeFourthStepFromStore();
      }
      // DEBO COMPLETAR LOS CAMPOS
    });

    const backThirdStep = () => {
      router.push({ name: `agreeThirdStep${isEditMode.value ? 'Edit' : ''}` });
    };

    return {
      backThirdStep, thirdStep, AGREEMENT_TYPES, options, commerce, date, objEmpty, areaCodes, contact_channels, completedSection, goConfirm, setUser, setAreaCode, invalidPhoneNumber,
    };
  },

};
</script>
