<template>
  <div>
      <Navbar-Section  titleClass="ml-4 " section="Importar" />
      <div class="flex flex-col mx-2">

          <!-- info -->
          <div class="flex flex-row mt-4 my-1">
              <div class="mr-2">
                  <BaseButton @click="openInfo('states')" text="Estados"/>
              </div>
              <div class="mr-2">
                  <BaseButton @click="openInfo('countries')" text="Paises"/>
              </div>
              <div class="mr-2">
                  <BaseButton @click="openInfo('enterprises')" text="Empresas"/>
              </div>
          </div>
          <!-- texto -->
          <div class="mt-3 ">
              <p><strong>{{ $t('Ingresar') }} </strong>
                <font-awesome-icon @click="ingresarInfo = !ingresarInfo" class="fill-current text-gray-500 w-6 mx-2 cursor-pointer" icon="question-circle" />
              </p>
              <div v-if="ingresarInfo">
                <p class="ml-2">Importara los datos leidos en el archivo y se ingresaran en la tabla principal.</p>
                <p class="ml-2">Debe respetar el <a :href="$apiDevuelvoya+ '/modelos/modelo.csv'" ><strong><u>modelo/plantilla</u></strong></a></p>
                <p class="ml-2">Campos requeridos: id_local (id access), country_id (id del pais), enterprise_id (id de la empresa)</p>
                <p class="ml-2"><strong>Maximo 20.000 filtas</strong></p>
              </div>
          </div>
          <!-- ingresar equipos -->
          <div class="w-full shadow p-2 bg-white my-2 flex flex-row  justify-between items-center">
              <div class="">
                  <span>{{ $t('Ingresar equipos') }}</span>
              </div>
              <div>
                  <BaseButton @eventFile="openConfirm($event, 'ingresarEquipos')" type="file" :text="$t('Elegir archivo')" />
              </div>
          </div>
          <!-- texto -->
          <div class="mt-3 ">
              <p><strong>{{ $t('Actualizar') }} </strong>
                <font-awesome-icon @click="actualizarInfo = !actualizarInfo" class="fill-current text-gray-500 w-6 mx-2 cursor-pointer" icon="question-circle" />
              </p>
              <div v-if="actualizarInfo">
                <p class="ml-2">Debe respetar el <a :href="$apiDevuelvoya+ '/modelos/update.csv'" ><strong><u>modelo/plantilla</u></strong></a></p>
                <p class="ml-2">Unicamente actualiza los campos que no esten vacios.</p>
                <p class="ml-2">Campos requeridos: equipment_id (id equipments),enterprise_id (id empresa), country_id (id del pais)</p>
                <p class="ml-2"><strong>Maximo 20.000 filtas</strong></p>
              </div>
          </div>

          <!-- actualizar equipos -->
          <div class="w-full shadow p-2 bg-white my-2 flex flex-row  justify-between items-center">
              <div class="">
                  <span>{{ $t('Actualizar equipos (Excepto estado)') }}</span>
              </div>
              <div>
                  <BaseButton @eventFile="openConfirm($event, 'actualizarEquipos')" type="file" :text="$t('Elegir archivo')" />
              </div>
          </div>

           <!-- texto -->
          <div class="mt-3 ">
              <p><strong>Estados </strong>
                <font-awesome-icon @click="estadosInfo = !estadosInfo" class="fill-current text-gray-500 w-6 mx-2 cursor-pointer" icon="question-circle" />
              </p>
              <div v-if="estadosInfo">
                <p class="ml-2">Campos requeridos: equipment_id (id equipments), country_id (id del pais), enterprise_id (id de la empresa),
                    equipment_state_id (id del estado)
                </p>
                <p class="ml-2">El id del estado tiene que estar entre los permitidos</p>
                <p class="ml-2">Debe respetar el <a :href="$apiDevuelvoya+ '/modelos/estados.csv'" ><strong><u>modelo/plantilla</u></strong></a></p>
                <p class="ml-2"><strong>Maximo 20.000 filtas</strong></p>
              </div>
          </div>
          <!-- actualizar estados -->
         <div class="w-full shadow p-2 bg-white my-2 flex flex-row  justify-between items-center">
              <div class="">
                  <span>{{ $t('Actualizar estados') }}</span>
              </div>
              <div>
                  <BaseButton @eventFile="openConfirm($event, 'actualizarEstados')" type="file" :text="$t('Elegir archivo')" />
              </div>
          </div>

      </div>

      <Base-modal :modalOverlay="true"  @exit="documentModal.display = false" :modal="documentModal.display" headingTitle="Importar">
        <template #content>
          <div class="mx-2  my-2 flex flex-col justify-center sm:justify-start ">
                <div v-if="document">
                    <div v-if="document" class="my-2 h-12">
                        <span >Nombre archivo: </span> <strong>{{document.name}}</strong>
                    </div>
                    <div v-if="!message">
                        <BaseButton :disabled="loading" :loading="loading" @click="handleImport()"  text="Importar" />
                    </div>
                </div>

                <div v-if="message" class="m-2 mb-3">
                    <SimpleAlert :color="messageColor" border="">
                        <template v-slot:text>
                            <p class="font-bold">{{message}}</p>
                        </template>
                    </SimpleAlert>
                </div>

          </div>
        </template>
      </Base-modal>

      <Base-modal :modalOverlay="true"  @exit="infoDisplay = false" :modal="infoDisplay" headingTitle="Información">
        <template #content>
          <div class="mx-2 w-64 my-2 flex flex-col flex-wrap justify-center sm:justify-start info" >
            <template v-if="info.states">
                <div v-for="(state, index) in equipment_states" :key="index" class="m-1">
                    <div> Nombre <strong> {{state.name}} </strong> ID <strong> {{state.id}}</strong> </div>
                </div>
            </template>
              <template v-if="info.countries">
                <div v-for="(country, index) in countries" :key="index" class="m-1">
                    <div> Nombre <strong> {{country.name}} </strong> ID <strong> {{country.id}}</strong> </div>
                </div>
            </template>
              <template v-if="info.enterprises">
                <div v-for="(enterprise, index) in enterprises" :key="index" class="m-1">
                    <div> Nombre <strong> {{enterprise.name}} </strong> ID <strong> {{enterprise.id}}</strong> </div>
                </div>
            </template>
          </div>
        </template>
      </Base-modal>

  </div>
</template>

<script>

// utilidades

import usualHook from '@/hooks/core/usualHook';
import locationHook from '@/hooks/core/locationHook';
import ImportRepository from '@/repositories/Core/ImportRepository';

export default {
  computed: {
    modalImport() {
      return this.documentModal.display;
    },
  },
  data() {
    return {
      document: null,
      documentModal: {
        display: false,
        key: '',
      },
      loading: false,
      message: '',
      messageColor: '',
      ingresarInfo: false,
      actualizarInfo: false,
      estadosInfo: false,
      infoDisplay: false,
      info: {
        states: true,
        countries: true,
        enterprises: true,
      },

    };
  },
  methods: {
    openConfirm(data, key) {
      this.message = '';
      this.messageColor = '';
      if (data.type === 'text/csv') {
        this.document = data;
      } else {
        this.message = 'El archivo debe tener extension CSV';
        this.messageColor = 'red';
      }
      this.documentModal.display = true;
      this.documentModal.key = key;
    },
    openInfo(info) {
      Object.keys(this.info).forEach((val) => {
        if (val !== info) {
          this.info[val] = false;
        } else {
          this.info[val] = true;
        }
      });
      this.infoDisplay = true;
    },
    async handleImport() {
      this.loading = true;
      let url;
      switch (this.documentModal.key) {
        case 'ingresarEquipos':
          url = 'new';
          break;
        case 'actualizarEquipos':
          url = 'update';
          break;
        case 'actualizarEstados':
          url = 'state';
          break;
        default:
      }
      const file = {
        file: this.document,
      };
      const response = await ImportRepository.importEquipment(url, file);
      this.loading = false;
      if (response.errors) {
        const { message } = response.errors;
        const [msg] = message;
        this.message = msg;
        this.messageColor = 'red';
      } else {
        const { cantidad, lote } = response;
        this.message = `Realizado correctamente ${cantidad} registros con el lote: ${lote}`;
        this.messageColor = 'green';
      }
    },

  },
  watch: {
    modalImport(val) {
      if (!val) {
        this.messageColor = '';
        this.message = '';
        this.document = null;
      }
    },
  },
  setup() {
    const {
      equipment_states, getEquipmentStates, enterprises, getEnterprises,
    } = usualHook();
    getEquipmentStates();
    getEnterprises();

    const chosenCountry = false;
    const chosenProvince = false;
    const { countries, getCountries } = locationHook({ chosenCountry, chosenProvince });
    getCountries();

    return { equipment_states, countries, enterprises };
  },

};
</script>

<style>
.info{
    width: 100%;
}
@media (min-width: 720px){
    .info{
     max-width: 35rem;
    }
}
</style>
