<template>
  <div>
    <Base-modal
      :modalOverlay="true"
      :modal="true"
      type="full-screen"
      :headingTitle="!loading ? $t('Confimar informacion del pactado') : ''"
      @exit="backFourthStep()"
    >
      <template #content>
        <div  class="mx-2">
            <LoaderDoom
            v-if="loading"
            LoaderDoomClass="LoaderDoom"
            spinnerClass="animate-spin w-20 text-blue-400"
            />
            <div class="flex flex-col mt-0">
              <div class="mb-2" v-if="!objEmpty(errors)">
                  <SimpleAlert color="gray" border="border-b-4">
                    <template v-slot:text>
                      <div  v-for="(error, index) in errors" :key="index">
                        {{error.length > 0 && typeof error === 'object'? error[0] : error}}
                      </div>
                    </template>
                  </SimpleAlert>
                </div>
                <div v-if="agreement_type" class="p-4 border border-gray-200 rounded-lg flex flex-col hover:border-gray-400" >
                    <template v-if="agreement_type === AGREEMENT_TYPES.VISIT">
                        <div class=" flex flex-row flex-wrap py-1">
                            <div class="mr-1">
                                <span class="font-normal"> {{ $t('Recolector') }}: </span>
                                <span class="text-gray-600">
                                  {{agree.fourthStep.user.value.firstName}} {{agree.fourthStep.user.value.lastName}}
                                </span>
                            </div>
                        </div>
                        <div class=" flex flex-row flex-wrap py-1">
                            <div class="mr-1">
                                <span class="font-normal"> {{ $t('Dia') }}: </span> <span class="text-gray-600">{{agree.fourthStep.day.value}}</span>
                            </div>
                            <div class="mr-1">
                                <span class="font-normal"> {{ $t('Horario') }}: </span> <span class="text-gray-600">{{agree.fourthStep.schedule.value.name}}</span>
                            </div>
                        </div>
                    </template>
                    <template v-if="agreement_type === AGREEMENT_TYPES.COMMERCE">
                        <div class=" flex flex-row flex-wrap py-1">
                            <div class="mr-1">

                                <span class="font-normal"> {{ t('Comercio') }}: </span>
                                <span v-if="agree.fourthStep.user.value.user?.fantasyName" class="text-gray-600">
                                    {{agree.fourthStep.user.value.user.fantasyName  }}
                                </span>
                                <span class="text-gray-600" v-else> {{agree.fourthStep.user.value.fantasyName}}</span>
                            </div>
                        </div>
                        <div class=" flex flex-row flex-wrap py-1">
                            <div class="mr-1">
                                <span class="font-normal"> {{ $t('Direccion del comercio') }}: </span>
                                <span v-if="!isEditMode" class="text-gray-600">
                                  {{agree.fourthStep.user.value.user?.street}} {{agree.fourthStep.user.value.user?.streetHeight}}
                                </span>
                                <span v-else class="text-gray-600">
                                  {{agree.fourthStep.user.value.street}} {{agree.fourthStep.user.value.streetHeight}}
                                </span>
                            </div>
                        </div>
                    </template>

                    <template v-if="agreement_type === AGREEMENT_TYPES.CORREO || agreement_type === AGREEMENT_TYPES.TERMINAL">
                          <div>
                          <span style="text-underline-offset: 2px;" class="underline text-bold text-lg">
                            {{agreement_type === AGREEMENT_TYPES.CORREO ? 'Correo': 'Terminal'}}
                            </span>
                        </div>
                        <div class=" flex flex-row flex-wrap py-1">
                            <div class="mr-1">
                                <span class="font-normal"> {{ $t('Sucursal') }}: </span> <span class="text-gray-600">{{agree.fourthStep.branch.value.address}}</span>
                            </div>
                            <div class="mr-1">
                                <span class="font-normal"> {{ $t('Localidad') }} : </span> <span class="text-gray-600">{{agree.fourthStep.branch.value.locality?.name}}</span>
                            </div>
                        </div>
                    </template>
                    <div>
                      <span style="text-underline-offset: 2px;" class="underline text-bold text-lg"> {{ $t('Informacion del cliente') }}</span>
                    </div>
                    <!-- ubicacion -->
                    <div class=" flex flex-row flex-wrap py-1">
                        <template v-if="isEditMode">
                          <div class="mr-1">
                            <span class="font-normal"> {{ $t('Provincia') }}: </span> <span class="text-gray-600">{{store.state.agreement.agreement?.province.value?.name}}</span>
                          </div>
                          <div class="mr-1">
                              <span class="font-normal"> {{ $t('Localidad') }}: </span> <span class="text-gray-600">{{store.state.agreement.agreement?.locality.value?.name}}</span>
                          </div>
                        </template>
                        <div class="mr-1">
                            <span class="font-normal"> {{ $t('Codigo postal') }}: </span> <span class="text-gray-600">{{agree.firstStep.postalCode.value}}</span>
                        </div>

                    </div>
                     <div class=" flex flex-row flex-wrap py-1">
                       <!-- direccion -->
                        <div class="mr-1">
                            <span class="font-normal"> {{ $t('Direccion') }}: </span> <span class="text-gray-600">{{agree.firstStep.street.value}}</span>
                        </div>
                        <div class="mr-1">
                            <span class="font-normal"> {{ $t('Direccion') }}: </span> <span class="text-gray-600">{{agree.firstStep.between_streets.value}}</span>
                        </div>
                        <!-- obseracion -->
                        <div class="mr-1">
                            <span class="font-normal"> {{ $t('Observacion') }}: </span> <span class="text-gray-600">{{agree.firstStep.observations.value}}</span>
                        </div>
                    </div>
                    <!-- quien entrega -->
                    <div v-if="agreement_type === AGREEMENT_TYPES.VISIT" class=" flex flex-row flex-wrap py-1">
                        <div class="mr-1">
                            <span class="font-normal"> {{ $t('Entrega') }}: </span> <span class="text-gray-600">{{agree.fourthStep.who_delivers.value}}</span>
                        </div>
                    </div>
                    <!-- contacto -->
                    <div  class=" flex flex-row flex-wrap py-1">
                        <div class="mr-1">
                            <span class="font-normal"> {{ $t('Email') }}: </span> <span class="text-gray-600">{{agree.fourthStep.email?.value}}</span>
                        </div>
                         <div class="mr-1">
                            <span class="font-normal"> {{ $t('Telefono') }}: </span> <span class="text-gray-600">{{agree.fourthStep.phoneNumber.value}}</span>
                        </div>
                    </div>
                     <div  class=" flex flex-row flex-wrap py-1">
                        <div class="mr-1">
                            <span class="font-normal"> {{ $t('Identificacion') }}: </span> <span class="text-gray-600">{{agree.firstStep.identificacion.value}}</span>
                        </div>
                    </div>
                </div>
                <!-- guardar, atras -->
                <div class="flex flex-row flex-wrap justify-center sm:justify-start">
                  <div  class="my-2 w-44 mr-2">
                    <BaseButton
                    @click="save()"
                    :text="$t('Guardar')"
                    />
                  </div>
                  <div class="my-2 w-44 ">
                    <BaseButton
                    @click="backFourthStep()"
                    :text="$t('Atras')"
                    />
                  </div>
                </div>
            </div>
        </div>
      </template>
    </Base-modal>
  </div>
</template>

<script>
import {
  onMounted, computed, ref, getCurrentInstance,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import LoaderDoom from '@/components/General/LoaderDoom.vue';

// utilididaes
import { AGREEMENT_TYPES, OPTION_TYPES } from '@/services/Utils/Utils';
import { AGREEMENT_STATES } from '@/services/Utils/State';
import { agreementSaveRepo, agreementUpdateRepo } from '@/repositories/Core/AgreementRepository';
import { objEmpty, hasLocalStorage } from '@/services/Utils/Objects';
import coreHook from '@/hooks/core/coreHook';
//

export default {
  components: {
    LoaderDoom,
  },
  setup() {
    const router = useRouter();
    const { isEditMode } = coreHook();
    const store = useStore();
    const agreement_type = computed(() => store.state.agreement.agree.thirdStep.agreement_type);
    const agree = computed(() => store.state.agreement.agree);

    const instance = ref({});
    onMounted(async () => {
      instance.value = getCurrentInstance().appContext.config.globalProperties;
      if (!await hasLocalStorage({ key: 'agreeFourthStep' })) {
        router.push({ name: `agreeThirdStep${isEditMode.value ? 'Edit' : ''}` });
      }
      if (!store.getters['agreement/completeFirstStep']) {
        await store.dispatch('agreement/tryCompleteStoreFirstStepFromLs');
      }
      if (!store.getters['agreement/completeSecondStep']) {
        await store.dispatch('agreement/tryCompleteStoreSecondStepFromLs');
      }
      if (!store.getters['agreement/completeThirdStep']) {
        await store.dispatch('agreement/tryCompleteStoreThirdStepFromLs');
      }
      if (!store.getters['agreement/completeFourthStep']) {
        await store.dispatch('agreement/tryCompleteStoreFourthStepFromLs');
      }

      if (!store.getters['agreement/completeFirstStep']
          || !store.getters['agreement/completeSecondStep']
          || !store.getters['agreement/completeThirdStep']
          || !store.getters['agreement/completeFourthStep']) {
        router.push({ name: `agreeCustomer${isEditMode.value ? 'Edit' : ''}` });
      }
    });

    const backFourthStep = () => {
      if (agreement_type.value === AGREEMENT_TYPES.VISIT) {
        router.push({ name: `agreeFourthStepVisit${isEditMode.value ? 'Edit' : ''}` });
      } else if (agreement_type.value === AGREEMENT_TYPES.COMMERCE) {
        router.push({ name: `agreeFourthStepCommerce${isEditMode.value ? 'Edit' : ''}` });
      } else if (agreement_type.value === AGREEMENT_TYPES.CORREO || agreement_type.value === AGREEMENT_TYPES.TERMINAL) {
        router.push({ name: `agreeFourthStepPoint${isEditMode.value ? 'Edit' : ''}` });
      }
    };
    const loading = ref(false);
    const errors = ref({});
    const save = async () => {
      errors.value = {};
      const {
        firstStep, secondStep, thirdStep, fourthStep,
      } = agree.value;
      const {
        country, province, locality, identificacion, lat, lng, observations, postalCode, street, between_streets, original_address,
      } = firstStep;
      const { equipments } = secondStep;
      const { agreement_type: agreement_typeAlias, pm, all } = thirdStep;
      const {
        phoneNumber, email, user, prefix, day, schedule: scheduleAlias, branch, contact_channel, who_delivers,
      } = fourthStep;
      loading.value = true;
      const agreeObject = {
        // firstStep
        country: country.value,
        province: province.value,
        locality: locality.value,
        identificacion: identificacion.value,
        lat: lat.value,
        lng: lng.value,
        observations: observations.value,
        postalCode: postalCode.value,
        street: street.value,
        between_streets: between_streets.value,
        original_address: original_address.value,
        // secondStep
        equipments: equipments.map((val) => val.id),
        // thirdStep
        agreement_type: agreement_typeAlias,
        agreement_state: AGREEMENT_STATES.PACTADO,
        // fourthStep
        phoneNumber: phoneNumber.value,
        prefix: prefix.value.prefix,
        email: email.value,
        contact_channel: contact_channel.value,
      };
      if (agreement_type.value === AGREEMENT_TYPES.VISIT) {
        agreeObject.user = user.value.id;
        agreeObject.day = day.value;
        agreeObject.schedule = scheduleAlias.value.id;
        agreeObject.who_delivers = who_delivers.value;
        if (!pm && !all) {
          agreeObject.option_type = OPTION_TYPES.ASSIGNED; // 1
        } else if (pm) {
          agreeObject.option_type = OPTION_TYPES.PM; // 2
        } else {
          agreeObject.option_type = OPTION_TYPES.ALL; // 3
        }
      } else if (agreement_type.value === AGREEMENT_TYPES.COMMERCE) {
        agreeObject.user = user.value.user_id;
      } else if (agreement_type.value === AGREEMENT_TYPES.CORREO || agreement_type.value === AGREEMENT_TYPES.TERMINAL) {
        agreeObject.branch = branch.value.id;
      }

      let response;
      if (isEditMode.value) {
        agreeObject.agreement = store.state.agreement.agreement.id;
        response = await agreementUpdateRepo(agreeObject);
      } else {
        response = await agreementSaveRepo(agreeObject);
      }

      if (response.errors) {
        instance.value.$toast.error('No se guardo correctamente', {
          duration: 1500,
          dismissible: true,
        });
        const { agree: agreeAlias } = response.errors;
        if (agreeAlias) {
          errors.value = response.errors.message;
        } else {
          errors.value = response.errors;
        }
      } else {
        instance.value.$toast.success('Guardado correctamente', {
          duration: 1500,
          dismissible: true,
        });
        const { agreement: agreeAlias } = response;
        await store.dispatch('agreement/removeAgree');
        const routePath = isEditMode.value ? '/agree/coupon/edit' : '/agree/coupon';
        const routeParams = {
          agreement_id: agreeAlias.id, identificacion: agreeAlias.identificacion, prefix: agreeAlias.prefix, phoneNumber: agreeAlias.phoneNumber,
        };
        if (agreeAlias.email) {
          routeParams.email = agreeAlias.email;
        }
        router.push({
          path: routePath,
          query: routeParams,
        });
      }
      loading.value = false;
    };

    return {
      backFourthStep, save, agreement_type, AGREEMENT_TYPES, agree, loading, errors, objEmpty, isEditMode, store,
    };
  },
};
</script>

<style>

</style>
