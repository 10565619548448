<template>
  <div class="list-container">
      <slot></slot>
  </div>
</template>

<script>
export default {

};
</script>

<style>
.list-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

</style>
