<template>
    <div>
        <Navbar-section  titleClass="ml-4 " :section="$t('Pactados')" />
        <div class="flex flex-col justify-center my-2 mx-4">
            <!-- tabla expres -->
            <div class=" flex justify-center ">
                <TableExpress
                :response="agreements"
                :filters="filters"
                :repository="tableRepository"
                :visibleWithOutResponse="false"
                model="agreements"
                keySearch="identificacion"
                @setResponse="agreements = $event"
                @notFound="notFound($event)"
                @removeFilters="removeFilters()"
                @completeFilters="completeFilters($event)"
                :exportable="true"
                :loaderSearch="true"
                >
                  <template v-slot:filter>
                    <!-- gestionado -->
                    <div class="my-2">
                      <BaseSelect
                      :placeholder="$t('Gestionado')"
                      outline="blue"
                      :options="[{id:1, name: 'Gestionado'},{id:0, name: 'No gestionado'}]"
                      v-model="filters.managed.value"
                      :remove="true"
                      />
                    </div>
                    <!-- estado -->
                    <div class="my-2">
                      <BaseSelect
                      placeholder="Estado"
                      outline="blue"
                      :options="agreement_states_options"
                      v-model="filters.agreement_state.value"
                      :remove="true"
                      />
                    </div>
                    <!-- tipo -->
                    <div class="my-2">
                      <BaseSelect
                      placeholder="Tipo"
                      outline="blue"
                      :options="agreement_types"
                      v-model="filters.agreement_type.value"
                      :remove="true"
                      />
                    </div>
                    <!-- autogestionado -->
                    <div class="my-2">
                      <BaseSelect
                      placeholder="Autogestion"
                      outline="blue"
                      :options="[{id: 1 , name: 'Si' }, { id: 0, name: 'No'}]"
                      v-model="filters.autogestion.value"
                      :remove="true"
                      />
                    </div>
                    <!-- country -->
                    <div class="my-2">
                        <BaseSelect
                        placeholder="Pais"
                        outline="blue"
                        :options="countries"
                        v-model="filters.country.value"
                        :remove="true"
                        />
                    </div>
                    <!-- province -->
                    <div class="my-2">
                        <BaseSelect
                        :placeholder="$t('Provincia')"
                        :options="provinces"
                        outline="blue"
                        v-model="filters.province.value"
                        :disabled="!filters.country.value"
                        :error="filters.province.error"
                        :remove="true"
                        />
                    </div>
                    <!-- locality -->
                    <div class="my-2">
                        <BaseSelect
                        :placeholder="$t('Localidad')"
                        :options="localities"
                        outline="blue"
                        v-model="filters.locality.value"
                        :disabled="!filters.province.value"
                        :error="filters.locality.error"
                        :remove="true"
                        />
                    </div>
                    <!-- users -->
                    <div class="my-2">
                        <BaseSelect
                        placeholder="Usuarios"
                        :options="users"
                        label="fullName"
                        outline="blue"
                        v-model="filters.user.value"
                        :error="filters.user.error"
                        :remove="true"
                        :filters="['fullName','province.name']"
                        >
                        <template v-slot:itemList="{item}">
                            <div class="item-select">
                            {{item.fullName}}  <strong>{{item.province?.name?.toLowerCase()}}</strong>
                            </div>
                        </template>
                        </BaseSelect>
                    </div>
                    <!-- operador -->
                    <div class="my-2">
                        <BaseSelect
                        placeholder="Operador"
                        :options="operators"
                        label="fullName"
                        outline="blue"
                        v-model="filters.operator.value"
                        :error="filters.operator.error"
                        :remove="true"
                        :filters="['fullName','province.name']"
                        >
                        <template v-slot:itemList="{item}">
                            <div class="item-select">
                            {{item.fullName}}  <strong>{{item.province?.name.toLowerCase()}}</strong>
                            </div>
                        </template>
                        </BaseSelect>
                    </div>
                    <!-- dataStart -->
                    <div class="my-2">
                        <BaseInput
                        type='date'
                        placeholder="Coordinado desde"
                        outline="base"
                        :remove="true"
                        v-model:modelValue="filters.dayFrom.value"
                        />
                    </div>
                    <!-- dataEnd -->
                    <div class="my-2">
                        <BaseInput
                        type='date'
                        placeholder="Coordinado hasta"
                        outline="base"
                        :remove="true"
                        v-model:modelValue="filters.dayTo.value"
                        />
                    </div>
                    <!-- codigos postales -->
                    <div class="my-2">
                        <BaseInput
                        placeholder="Desde CP"
                        outline="blue"
                        v-model:modelValue="filters.postalCodeFrom.value"
                        :error="filters.postalCodeFrom.error"
                        @error-handle="filters.postalCodeFrom.error = $event"
                        />
                    </div>
                    <div class="my-2">
                        <BaseInput
                        placeholder="Hasta CP"
                        outline="blue"
                        v-model:modelValue="filters.postalCodeTo.value"
                        :error="filters.postalCodeTo.error"
                        @error-handle="filters.postalCodeTo.error = $event"
                        />
                    </div>
                  </template>
                  <template v-slot:columns>
                      <Column key="''" :title="$t('Accion')">
                          <template v-slot:action="{content}">
                              <div
                              @click="openAgreementInfo(content)"
                              class="w-8 mx-auto rounded-full text-white  px-2 py-1 mx-1 bg-gray-400 cursor-pointer">
                              <font-awesome-icon icon="edit" />
                              </div>
                          </template>
                      </Column>
                      <Column key="identificacion" :title="$t('Identificacion')" />
                      <Column key="agreement_state.name" title="Estado">
                        <template v-slot:action="{content}">
                              <div :style="`background:${content.agreement_state.color};color:white;`"  class="p-1 text-xs rounded-lg">
                              {{content.agreement_state.name}}
                              </div>
                          </template>
                      </Column>

                      <Column key="agreement_type.name" :title="$t('Tipo')" />
                      <Column key="operator.firstName" :title="$t('Operador/a')" />
                      <Column key="user.firstName" :title="$t('Asignado')" />
                      <Column key="created_at" :title="$t('Dia creado')" :format="formatDateTime" :fullText="true"/>
                      <Column key="updated_at" :title="$t('Actualizado')" :format="formatDateTime" :fullText="true"/>
                      <Column key="day" :title="$t('Dia coordinado')" :fullText="true" :format="reverseStr"/>
                      <Column key="schedule.name" title="Horario" />
                      <Column key="notas" :title="$t('Notas')" />
                      <Column key="street" :title="$t('Direccion')" />
                      <Column key="postalCode" :title="$t('Cp')" />
                      <Column key="locality.name" :title="$t('Localidad')" />
                      <Column key="province.name" :title="$t('Provincia')" />
                      <Column key="phoneNumber" :title="$t('Telefono')" />
                      <Column key="email" :title="$t('Email')" />
                      <Column key="autogestion" :title="$t('Autogestion')">
                        <template v-slot:action="{content}">
                            <div>
                              {{ content.autogestion ? 'Si' : 'No' }}
                            </div>
                        </template>
                      </Column>
                      <Column key="id" title="id" />
                  </template>
                </TableExpress>
            </div>
            <!-- sin resultados -->
            <div v-if="emptyResult" class="w-full bg-white h-28 flex flex justify-center items-center">
                <div class="text-lg font-bold flex flex-col">
                    <div>{{messageResult}}</div>
                    <div class="mx-auto my-2">
                        <font-awesome-icon class="fill-current h-8 w-6 text-blue-400" style="font-size:30px;" icon="exclamation-circle" />
                    </div>
                </div>
            </div>
        </div>

      <!-- informacion del pactado -->
      <Base-modal :modalOverlay="true" size="md" @exit="modalAgreementInfo = false" :modal="modalAgreementInfo" :headingTitle="$t('Pactado')">
          <template #content>
              <div class="px-3 py-2 rounded-lg flex flex-col " >
                  <!-- informacion del recolector -->
                  <template v-if="agreementInfo.agreement_type_id === AGREEMENT_TYPES.VISIT">
                      <div class=" flex flex-row flex-wrap py-1">
                          <div class="mr-1">
                              <span class="font-normal"> {{$t('Recolector')}}: </span> <span class="text-gray-600">{{agreementInfo.user?.firstName}} {{agreementInfo.user?.lastName}}</span>
                          </div>
                      </div>
                      <div class=" flex flex-row flex-wrap py-1">
                          <div class="mr-1">
                              <span class="font-normal"> {{$t('Dia')}}: </span> <span class="text-gray-600">{{agreementInfo.day}}</span>
                          </div>
                          <div class="mr-1">
                              <span class="font-normal"> {{$t('Horario')}}: </span> <span class="text-gray-600">{{agreementInfo.schedule.name}}</span>
                          </div>
                      </div>
                  </template>
                  <!-- informacion del comercio -->
                  <template v-if="agreementInfo.agreement_type_id === AGREEMENT_TYPES.COMMERCE">
                      <div>
                        <span style="text-underline-offset: 2px;" class="underline text-bold text-lg">
                          {{$t('Comercio')}}
                        </span>
                      </div>
                      <div class=" flex flex-row flex-wrap">
                          <div class="mr-1">
                              <span class="font-normal"> {{$t('Nombre')}}: </span> <span class="text-gray-600">{{agreementInfo.user?.fantasyName}}</span>
                          </div>
                      </div>
                      <div class=" flex flex-row flex-wrap ">
                          <div class="mr-1">
                              <span class="font-normal"> {{$t('Localidad')}}: </span>
                              <span class="text-gray-600">{{agreementInfo.user?.locality.name}}</span>
                          </div>
                          <div class="mr-1">
                              <span class="font-normal"> {{$t('Direccion del comercio')}}: </span>
                              <span class="text-gray-600">{{agreementInfo.user?.street}} {{agreementInfo.user?.streetHeight}}</span>
                          </div>
                      </div>
                  </template>
                  <!-- informacion de la sucursal -->
                  <template v-if="agreementInfo.agreement_type_id === AGREEMENT_TYPES.CORREO || agreementInfo.agreement_type_id === AGREEMENT_TYPES.TERMINAL">
                      <div>
                        <span style="text-underline-offset: 2px;" class="underline text-bold text-lg">
                        {{agreementInfo.agreement_type_id === AGREEMENT_TYPES.CORREO ? 'Correo': 'Terminal'}}
                        </span>
                      </div>
                      <div class=" flex flex-row flex-wrap py-1">
                          <div class="mr-1">
                              <span class="font-normal"> {{$t('Sucursal')}}: </span> <span class="text-gray-600">{{agreementInfo.branch?.address}}</span>
                          </div>
                          <div class="mr-1">
                              <span class="font-normal"> {{$t('Localidad')}}: </span> <span class="text-gray-600">{{agreementInfo.branch?.locality.name}}</span>
                          </div>
                      </div>
                  </template>
                  <!-- informacion del cliente -->
                  <div class="mt-4">
                    <span style="text-underline-offset: 2px;" class="underline text-bold text-lg"> {{$t('Informacion del cliente')}}</span>
                  </div>
                  <!-- ubicacion -->
                  <div class=" flex flex-row flex-wrap py-1">
                      <div class="mr-1">
                          <span class="font-normal"> {{  $t('Provincia') }}: </span> <span class="text-gray-600">{{agreementInfo.province.name}}</span>
                      </div>
                      <div class="mr-1">
                          <span class="font-normal"> {{$t('Localidad')}}: </span> <span class="text-gray-600">{{agreementInfo.locality.name}}</span>
                      </div>
                      <div class="mr-1">
                          <span class="font-normal"> {{$t('Codigo postal')}}: </span> <span class="text-gray-600">{{agreementInfo.postalCode}}</span>
                      </div>

                  </div>
                    <div class=" flex flex-row flex-wrap py-1">
                      <div class="mr-1">
                          <span class="font-normal"> {{$t('Direccion')}}: </span> <span class="text-gray-600">{{agreementInfo.street}}</span>
                      </div>
                      <div class="mr-1">
                          <span class="font-normal"> {{$t('Observacion')}}: </span> <span class="text-gray-600">{{agreementInfo.observations}}</span>
                      </div>
                  </div>
                  <!-- quien entrega -->
                  <div v-if="agreementInfo.agreement_type_id === AGREEMENT_TYPES.VISIT" class=" flex flex-row flex-wrap py-1">
                      <div class="mr-1">
                          <span class="font-normal"> {{$t('Entrega')}}: </span> <span class="text-gray-600">{{agreementInfo.who_delivers}}</span>
                      </div>
                  </div>
                  <!-- contacto -->
                  <div  class=" flex flex-row flex-wrap py-1">
                      <div class="mr-1">
                          <span class="font-normal"> {{$t('Email')}}: </span> <span class="text-gray-600">{{agreementInfo.email}}</span>
                      </div>
                        <div class="mr-1">
                          <span class="font-normal"> {{$t('Telefono')}}: </span> <span class="text-gray-600">{{agreementInfo.prefix}} {{agreementInfo.phoneNumber}}</span>
                      </div>
                  </div>
                  <!-- acciones -->
                  <div class="flex flex-row flex-wrap">
                    <div class="my-2 w-44 mr-2">
                      <BaseButton
                      @click="goAgreementEdit()"
                      textColor="text-white"
                      background="bg-black"
                      :text="$t('Modificar pactado')" />
                    </div>
                    <div v-if="checkPermission('agreement_update_state')" class="my-2 w-44 mr-2">
                      <BaseButton
                      @click="openAgreementState()"
                      background="bg-gray-400"
                      textColor="text-white"
                      :text="$t('Modificar estado')" />
                    </div>
                    <div   class="my-2  mr-2">
                      <BaseButton
                      @click="openAgreementEquipments()"
                      background="bg-indigo-400"
                      textColor="text-white"
                      :text="$t('Ver equipos pactados')" />
                    </div>
                    <div   class="my-2  mr-2">
                      <BaseButton
                      @click="showManagedEquipmentsAgreed()"
                      background="bg-green-400"
                      textColor="text-white"
                      :text="$t('Equipos gestionados')" />
                    </div>
                  </div>
              </div>
          </template>
      </Base-modal>
      <!-- actualizar estado -->
      <Base-modal :modalOverlay="true" size="md" @exit="modalAgreementUpdate = false" :modal="modalAgreementUpdate" :headingTitle="$t('Estado del pactado')">
          <template #content>
              <div class="px-3 py-2 rounded-lg flex flex-col " >
                 <div>{{$t('Estado actual')}}</div>
                  <BaseSelect
                    :placeholder="$t('Estado del pactado')"
                    outline="blue"
                    :options="agreement_states_options"
                    v-model="agreementForm.agreement_state.value"
                  />
                  <div class="my-2 w-44 mr-2">
                    <BaseButton
                    @click="updateState()"
                    :loading="loadingUpdate"
                    :disabled="loadingUpdate"
                    customClass="border border-gray-200"
                    padding="p-1 px-2"
                    background="bg-black"
                    textColor="text-white"
                    :text="$t('Actualizar')" />
                  </div>
                  <SimpleAlert v-if="errorUpdate" color="red" border="border-b-4">
                    <template v-slot:text>
                      <div>
                        {{$t('No se ha actualizado correctamente')}}
                      </div>
                    </template>
                  </SimpleAlert>

              </div>
          </template>
      </Base-modal>
      <!-- ver equipos del pactado -->
      <Base-modal :modalOverlay="true" size="md" @exit="closeAgreementEquipmentsModal()" :modal="modalAgreementEquipments" :headingTitle="$t('Equipos')">
          <template #content>
              <div class="px-3 py-2 rounded-lg flex flex-col " >
                <div class="flex flex-row flex-wrap">
                  <template v-for="(equipment,index) in equipmentsAgreement" :key="index">
                    <div  class="flex flex-col p-2 m-1 border border-gray-200">
                      <template v-if="mostrandoEquiposGestionadosDelPactado">
                        <div  class="flex flex-row flex-wrap">
                          <span class="font-bold mr-1"> {{$t('Gestionado por')}}: </span>
                          <span>{{ equipment.user.firstName }}  </span>
                        </div>
                        <div  class="flex flex-row flex-wrap">
                          <span class="font-bold mr-1"> {{$t('Fecha')}}: </span>
                          <span>{{ equipment.created_at.substr(0,10) }}  </span>
                        </div>
                      </template>
                      <div v-if="equipment.serie" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{$t('Serie')}}: </span>
                        <span>{{ equipment.serie }} </span>
                      </div>
                      <div v-if="equipment.mac" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{$t('Mac')}}: </span>
                        <span>{{ equipment.mac }} </span>
                      </div>
                      <div v-if="equipment.serial" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{$t('Serial')}}: </span>
                        <span>{{ equipment.serial }} </span>
                      </div>
                      <div v-if="equipment.terminal" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{$t('Terminal')}}: </span>
                        <span>{{ equipment.terminal }} </span>
                      </div>
                      <div v-if="equipment.localidad" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{$t('Localidad')}}: </span>
                        <span>{{ equipment.localidad }} </span>
                      </div>
                      <div v-if="equipment.direccion" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{$t('Direccion')}}: </span>
                        <span>{{ equipment.direccion }} </span>
                      </div>
                      <div v-if="equipment.equipment_state" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{$t('Estado')}}: </span>
                        <span>{{ equipment.equipment_state.name }} </span>
                      </div>
                      <div v-if="equipment.nombre_cliente" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{$t('Nombre')}}: </span>
                        <span>{{ equipment.nombre_cliente }} </span>
                      </div>
                      <template v-if="mostrandoEquiposGestionadosDelPactado">
                        <div class="flex flex-row flex-wrap">
                          <span class="font-bold mr-1"> Reff: </span>
                          <span>{{ equipment.equipment_id }} </span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="flex flex-row flex-wrap">
                          <span class="font-bold mr-1"> Reff: </span>
                          <span>{{ equipment.id }} </span>
                          <span class="font-bold mx-1"> Reff Local: </span>
                          <span>{{ equipment.id_local }} </span>
                        </div>
                      </template>
                     
                    </div>
                  </template>

                </div>
              </div>
          </template>
      </Base-modal>
      <router-view></router-view>

    </div>
</template>

<script>
import {
  ref, computed, reactive,
} from 'vue';
import { useRouter } from 'vue-router';

// utilidades
import { agreementsIndexRepo, agreementUpdateStateRepo, agreementShowRepo, equiposGestionadosPactados } from '@/repositories/Core/AgreementRepository';
import { usersInfoRepo } from '@/repositories/User/UserRepository';

import { AGREEMENT_TYPES } from '@/services/Utils/Utils';
import coreHook from '@/hooks/core/coreHook';
import locationHook from '@/hooks/core/locationHook';
import usualHook from '@/hooks/core/usualHook';
import { objEmpty } from '@/services/Utils/Objects';
import { ROLE } from '@/services/Utils/Role';
import { reverseStr, formatDateTime } from '@/services/Utils/Formatters';
import { useI18n } from 'vue-i18n';
import { nextTick} from 'vue';


export default {
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const agreements = ref([]);
    const tableRepository = agreementsIndexRepo;
    const emptyResult = ref(false);
    const messageResult = ref('No se han encontrado resultados');
    const notFound = (flag) => {
      emptyResult.value = flag;
      messageResult.value = 'No se han encontrado resultados';
    };

    const { checkPermission } = coreHook();

    const filters = reactive({
      managed: {
        value: null,
      },
      agreement_type: {
        value: null,
      },
      agreement_state: {
        value: null,
      },
      country: {
        value: null,
      },
      province: {
        value: null,
      },
      locality: {
        value: null,
      },
      user: {
        value: null,
      },
      operator: {
        value: null,
      },
      postalCodeFrom: {
        value: null,
      },
      postalCodeTo: {
        value: null,
      },
      dayFrom: {
        value: null,
      },
      dayTo: {
        value: null,
      },
      autogestion: {
        value: null
      }

    });

    const completeFilters = (data) => {
      // cuando recarga la pagina y la url contiene estos filtros, el componente tableExpress emite este evento
      if (data.managed) filters.managed.value = data.managed;
      if (data.agreement_type) filters.agreement_type.value = data.agreement_type;
      if (data.agreement_state) filters.agreement_state.value = data.agreement_state;
      if (data.country) filters.country.value = data.country;
      if (data.country) filters.country.value = data.country;
      if (data.province) filters.province.value = data.province;
      if (data.locality) filters.locality.value = data.locality;
      if (data.user) filters.user.value = data.user;
      if (data.operator) filters.operator.value = data.operator;
      if (data.postalCodeFrom) filters.postalCodeFrom.value = data.postalCodeFrom;
      if (data.postalCodeTo) filters.postalCodeTo.value = data.postalCodeTo;
      if (data.dayFrom) filters.dayFrom.value = data.dayFrom;
      if (data.dayTo) filters.dayTo.value = data.dayTo;
      if (data.autogestion) filters.autogestion.value = data.autogestion;
    };
    const removeFilters = () => {
      // Este evento lo emite el tableExpress al remover los filtros
      if (filters.managed.value) filters.managed.value = null;
      if (filters.agreement_type.value) filters.agreement_type.value = null;
      if (filters.agreement_state.value) filters.agreement_state.value = null;
      if (filters.country.value) filters.country.value = null;
      if (filters.province.value) filters.province.value = null;
      if (filters.locality.value) filters.locality.value = null;
      if (filters.user.value) filters.user.value = null;
      if (filters.operator.value) filters.operator.value = null;
      if (filters.postalCodeFrom.value) filters.postalCodeFrom.value = '';
      if (filters.postalCodeTo.value) filters.postalCodeTo.value = '';
      if (filters.dayFrom.value) filters.dayFrom.value = '';
      if (filters.dayTo.value) filters.dayTo.value = '';
      if (filters.autogestion.value) filters.autogestion.value = null;
    };

    // RECURSOS/UTILIDADES
    const chosenCountry = computed(() => filters.country.value);
    const chosenProvince = computed(() => filters.province.value);
    const {
      countries, getCountries, provinces, localities,
    } = locationHook({ chosenCountry, chosenProvince });
    getCountries();

    const users = ref([]);
    const getUsers = async () => {
      const response = await usersInfoRepo();
      users.value = response;
    };
    getUsers();
    const operators = ref([]);
    const getOperators = async () => {
      const response = await usersInfoRepo(
        { role: [ROLE.ADMIN, ROLE.ZONE_OPERATOR, ROLE.OPERATOR].toString(), cancel: ['operative'] },
      );
      operators.value = response;
    };
    getOperators();

    const {
      agreement_states, getAgreementStates, agreement_types, getAgreementTypes,
    } = usualHook();

    const agreement_states_options = computed(() => {
      return agreement_states.value.map((state) => {
        return {
          ...state,
          name: t(state.name),
        }
      });
    });
    getAgreementStates();
    getAgreementTypes();

    const modalAgreementInfo = ref(false);
    const agreementInfo = ref({});
    const openAgreementInfo = (info) => {
      agreementInfo.value = info;
      modalAgreementInfo.value = true;
    };

    const goAgreementEdit = () => {
      const { id, identificacion } = agreementInfo.value;
      // modalAgreementInfo.value = false;
      router.push({ name: 'agreeFirstStepEdit', params: { agreement_id: id, identificacion } });
    };

    const modalAgreementUpdate = ref(false);
    const loadingUpdate = ref(false);
    const errorUpdate = ref(false);
    const agreementForm = ref({
      agreement_state: {
        value: null,
        error: '',
      },
    });
    const openAgreementState = () => {
      modalAgreementUpdate.value = !modalAgreementUpdate.value;
      const { agreement_state_id } = agreementInfo.value;
      agreementForm.value.agreement_state.value = agreement_state_id;
    };

    const updateState = async () => {
      loadingUpdate.value = true;
      const { identificacion, id } = agreementInfo.value;

      const object = {
        agreement_state: agreementForm.value.agreement_state.value,
        agreement: id,
        identificacion,
      };
      const response = await agreementUpdateStateRepo(object);
      if (response.errors) {
        errorUpdate.value = true;
      } else {
        errorUpdate.value = false;
        const { agreement: { agreement_state, agreement_state_id } } = response;

        agreements.value.forEach((val, index) => {
          if (val.id === id) {
            agreements.value[index].agreement_state = agreement_state;
            agreements.value[index].agreement_state_id = agreement_state_id;
          }
        });
      }
      loadingUpdate.value = false;
      modalAgreementUpdate.value = false;
      modalAgreementInfo.value = false;
      agreementInfo.value = {};
    };

    const modalAgreementEquipments = ref(false);
    const equipmentsAgreement = ref([]);

    const openAgreementEquipments = async () => {
      modalAgreementEquipments.value = !modalAgreementEquipments.value;
      const response = await agreementShowRepo(agreementInfo.value.id, agreementInfo.value.identificacion);
      const { agreement: { equipments } } = response;
      equipmentsAgreement.value = equipments;
    };

    const mostrandoEquiposGestionadosDelPactado = ref(false)
    const showManagedEquipmentsAgreed = async () => {
      modalAgreementEquipments.value = !modalAgreementEquipments.value;
      const response = await equiposGestionadosPactados(agreementInfo.value.id, agreementInfo.value.identificacion);
      if(response && response.equipos_gestionados){
        mostrandoEquiposGestionadosDelPactado.value = true
        equipmentsAgreement.value = response.equipos_gestionados;
      }
    }

    const closeAgreementEquipmentsModal = async () => {
      equipmentsAgreement.value = [];
      mostrandoEquiposGestionadosDelPactado.value = false
      await nextTick();
      modalAgreementEquipments.value = false;
    };

    return {
      // eslint-disable-next-line max-len
      tableRepository, 
      agreements, 
      notFound, 
      emptyResult, 
      messageResult, 
      filters, 
      countries, 
      provinces, 
      localities, 
      users, 
      operators, 
      objEmpty, 
      removeFilters, 
      completeFilters, 
      reverseStr, 
      openAgreementInfo, 
      modalAgreementInfo, 
      agreementInfo, 
      agreement_states_options, 
      agreement_types, 
      AGREEMENT_TYPES, 
      goAgreementEdit, 
      openAgreementState, 
      openAgreementEquipments, 
      modalAgreementUpdate, 
      agreementForm, 
      updateState, 
      errorUpdate, 
      loadingUpdate, 
      modalAgreementEquipments, 
      equipmentsAgreement, 
      checkPermission, 
      formatDateTime,
      closeAgreementEquipmentsModal,
      showManagedEquipmentsAgreed,
      mostrandoEquiposGestionadosDelPactado
    };
  },
};
</script>

<style>

.slide-enter-active, .slide-leave-active {
    transition: opacity .5s;
}

.slide-enter-from, .slide-leave-to{
    opacity:0;
}

</style>
