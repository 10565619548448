<template>
    <div>
        <Navbar-Section  titleClass="ml-4 " :section="$t('Pactar')" />
        <div class="flex flex-col justify-center my-2 mx-4">
            <div class=" flex justify-center ">
                <TableExpress
                :response="customers"
                :filter="false"
                :repository="tableRepository"
                :visibleWithOutResponse="false"
                :fetchBooted="false"
                :cacheParams="false"
                :loaderSearch="true"
                keySearch="identificacion"
                model="equipments"
                @setResponse="customers = $event"
                @getResponse="getResponse($event)"
                @notFound="notFound($event)"
                customStyle="width:100%;"
                >
                  <template v-slot:columns>
                      <Column key="''" :title="$t('Accion')">
                          <template v-slot:action="{content}">
                            <button
                            @click="goFirstStep(content)"
                            :class="['w-22  text-white  px-2 py-1 mx-1', 'bg-black']">
                            <font-awesome-icon icon="handshake" />
                            </button>
                          </template>
                      </Column>
                      <Column key="cantidad" :title="$t('Cantidad')" />
                      <Column key="identificacion" :title="$t('Identificacion')" />
                      <Column key="nombre_cliente" :title="$t('Nombre')" />
                      <Column key="codigo_postal" :title="$t('Codigo postal')" />
                      <Column key="localidad" :title="$t('Localidad')" />
                      <Column key="provincia" :title="$t('Provincia')" />
                      <Column key="direccion" :title="$t('Direccion')" />
                      <Column key="''" :title="$t('Ver')">
                          <template v-slot:action="{content}">
                            <button
                            :disabled="modalEquipments"
                            @click="getEquipments(content)"
                            :class="['text-white  px-2 py-1 mx-1 text-sm', 'bg-black']">
                              {{ $t('Equipos') }}
                            </button>
                          </template>
                      </Column>
                  </template>
                  <template v-slot:subContent >
                      <div v-if="pactado" class="w-full bg-red-400 flex flex justify-center items-center p-3 rounded">
                          <div class="text-lg font-bold flex flex-col text-white">
                            <div>{{ $t('Este cliente ya tiene un pactado') }}</div>
                          </div>
                      </div>
                  </template>
                </TableExpress>
            </div>
            <div v-if="emptyResult" class="w-full bg-white h-28 flex flex justify-center items-center">
                <div class="text-lg font-bold flex flex-col">
                  <div>{{messageResult}}</div>
                  <div class="mx-auto my-2">
                      <font-awesome-icon class="fill-current h-8 w-6 text-blue-400" style="font-size:30px;" icon="exclamation-circle" />
                  </div>
                </div>
            </div>
        </div>
        <Base-modal
          :modalOverlay="true"
          @exit="modalEquipments = false"
          :modal="modalEquipments"
          :headingTitle="$t('Equipos')"
          size="lg"
          >
          <template #content>
            <div>
              <LoaderContinue v-if="loading" />
              <div class="mx-2 mb-2 text-gray-500">
                {{equipments.length}} {{$t('Equipos')}}
              </div>
              <div class="mx-2 flex flex-row flex-wrap ">
                <div class=" border border-gray-200 px-2 py-2 flex flex-col mr-1 mb-2 rounded-lg equipment-out-agree"
                  v-for="(equipment, index) in equipments"
                  :key="index"
                >
                  <div class="flex flex-row">
                    <div class="flex flex-col">
                      <div v-if="equipment.serie" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{ $t('Serie') }} : </span>
                        <span>{{ equipment.serie }} </span>
                      </div>
                      <div v-if="equipment.terminal" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{ $t('Terminal') }} : </span>
                        <span>{{ equipment.terminal }} </span>
                      </div>
                      <div v-if="equipment.localidad" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{ $t('Localidad') }}: </span>
                        <span>{{ equipment.localidad }} </span>
                      </div>
                      <div v-if="equipment.direccion" class="flex flex-row flex-wrap">
                        <span class="font-bold mr-1"> {{ $t('Direccion') }}: </span>
                        <span>{{ equipment.direccion }} </span>
                      </div>
                      <div v-if="equipment.nombre_cliente" class="flex flex-row flex">
                        <span class="font-bold mr-1"> {{ $t('Nombre') }} : </span>
                        <span>{{ equipment.nombre_cliente }} </span>
                      </div>
                      <div class="flex flex-row flex">
                        <span class="font-bold mr-1"> Reff: </span>
                        <span>{{ equipment.id }} </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </template>
        </Base-modal>
      <router-view></router-view>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import {
  ref, onMounted, getCurrentInstance,
} from 'vue';
import LoaderContinue from '@/components/General/LoaderContinue.vue';

// utilidades
import { customersToAgreedRepo, equipmentsToAgreedRepo } from '@/repositories/Core/AgreementRepository';

//

export default {
  components: {
    LoaderContinue,
  },
  setup() {
    const router = useRouter();
    const instance = ref({});
    onMounted(async () => {
      instance.value = getCurrentInstance().appContext.config.globalProperties;
    });

    const customers = ref([]);
    const tableRepository = customersToAgreedRepo;
    const emptyResult = ref(false);
    const messageResult = ref('No se han encontrado resultados');
    const notFound = (flag) => {
      emptyResult.value = flag;
      messageResult.value = 'No se han encontrado resultados';
    };

    const goFirstStep = (data) => {
      const { identificacion, direccion } = data;
      if (!direccion) {
        emptyResult.value = true;
        messageResult.value = 'Este cliente no tiene domicilio definido, para poder avanzar debe tener domicilio';
        return false;
      }
      emptyResult.value = false;
      router.push({ name: 'agreeFirstStep', params: { identificacion, matchOriginalAdress: direccion } });
      return true;
    };

    const modalEquipments = ref(false);
    const equipments = ref([]);
    const loading = ref(false);
    const getEquipments = async (data) => {
      if (loading.value) return false;
      modalEquipments.value = true;
      const { direccion, identificacion } = data;
      loading.value = true;
      const response = await equipmentsToAgreedRepo({ identificacion, matchOriginalAdress: direccion });
      const { prepared_equipments } = response;
      if (prepared_equipments && prepared_equipments.length > 0) {
        equipments.value = prepared_equipments;
      } else {
        instance.value.$toast.info('No hay equipos disponibles', {
          duration: 1500,
          dismissible: true,
        });
        modalEquipments.value = false;
      }
      loading.value = false;
      return equipments.value;
    };

    const pactado = ref(false);
    const getResponse = (response) => {
      pactado.value = response.pactado;
    };

    return {
      tableRepository, 
      customers, 
      notFound, 
      emptyResult, 
      goFirstStep, 
      messageResult, 
      modalEquipments, 
      getEquipments, 
      loading, 
      equipments,
      getResponse,
      pactado
    };
  },
};
</script>

<style>

.slide-enter-active, .slide-leave-active {
    transition: opacity .5s;

}

.slide-enter-from, .slide-leave-to{
    opacity:0;
    /* transform:translate(100vw,-50%); */

}

</style>
