<template>
    <div >
      <Navbar-Section   titleClass="ml-4 " section="Configuracion de cuenta" :backPath="{name:'User'}" />
      <BaseItemList>
        <ListItem type="link" :iconleft="true" :params="{name: 'changePassword'}" label="Cambiar contraseña" :underline="true" icon="key"/>
      </BaseItemList>
    </div>
</template>

<script>

import ListItem from '@/components/Core/List/ListItem.vue';
import BaseItemList from '@/components/Core/List/BaseItemList.vue';

export default {

  components: {
    ListItem, BaseItemList,
  },

};
</script>
