<template>
  <div>
      <Base-modal :modalOverlay="true"  @exit="backRoute()" :modal="true" headingTitle="Eliminar cobertura de usuario">
        <template #content>
            <div class="mx-2 flex flex-col justify-center sm:justify-start flex-wrap">
                <div>
                  <!-- country -->
                    <div class="m-2">
                      <BaseSelect
                      placeholder="Pais"
                      outline="blue"
                      :options="countries"
                      v-model:modelValue="coverage.country_id.value"
                      />
                    </div>
                </div>

                <div class=" flex flex-row justify-center sm:justify-start flex-wrap">
                    <!-- users -->
                    <div class="m-2">
                      <BaseSelect
                      placeholder="Usuarios con cobertura"
                      :options="userWithCoverage"
                      label="name"
                      outline="blue"
                      v-model:modelValue="coverage.user_id.value"
                      :error="coverage.user_id.error"
                      :disabled="!coverage.country_id.value"
                      :filters="['name']"
                      @error-handle="coverage.user_id.error = $event"
                      />
                    </div>
                </div>

                <!-- botones accion -->
                <div v-if="coverage.user_id.value && !message" class="m-2  flex justify-center sm:justify-start flex-row flex-wrap">
                    <div class="mr-2 w-28">
                        <BaseButton @click="deleteConfirm = true" background="bg-red-400" :loading="loading" :disabled="loading"  textColor="text-white"  text="Eliminar" />
                    </div>
                </div>

                <!-- simpleAlert message -->
                <div v-if="message" class="m-2 mx-2">
                  <SimpleAlert color="green" border="border-b-4">
                    <template v-slot:text>
                      <p class="font-bold">{{message}}</p>
                    </template>
                  </SimpleAlert>
                </div>

            </div>
        </template>
      </Base-modal>
      <Base-modal
      :footer="true"
      :modalOverlay="true"
      @exit="deleteConfirm = false"
      :modal="deleteConfirm"
      :headingTitle="$t('Eliminar')">
      <template v-slot:content>
        <div class="m-2">
          ¿Estas seguro de eliminar esta cobertura?
        </div>
      </template>
      <template v-slot:footer>
        <div class="m-2 flex flex-row">
          <button @click="destroy()"  class="bg-green-400 rounded text-center p-1 px-2 m-1 "  ><span class="text-sm text-white font-bold">Si</span></button>
          <button @click="deleteConfirm = false" class="bg-red-400 rounded text-center p-1 px-2 m-1 "  ><span class="text-sm text-white font-bold">No</span></button>
        </div>
      </template>
      </Base-modal>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import {
  reactive, ref, watch, onMounted, getCurrentInstance,
} from 'vue';

//

// utilidades
import locationHook from '@/hooks/core/locationHook';
import CoverageRepository from '@/repositories/Core/CoverageRepository';

export default {
  setup(_, { emit }) {
    const router = useRouter();
    const instance = ref(null);

    onMounted(async () => {
      instance.value = getCurrentInstance().appContext.config.globalProperties;
    });

    const backRoute = () => {
      router.push({ name: 'Coverages' });
    };

    const coverage = reactive({
      user_id: {
        value: null,
        error: '',
      },
      country_id: {
        value: null,
        error: '',
      },
    });

    const userWithCoverage = ref([]);
    const getUserWithCoverage = async (params) => {
      const response = await CoverageRepository.userWithCoverage(params);
      userWithCoverage.value = response;
    };

    const chosenCountry = false; const chosenProvince = false;
    const { countries, getCountries } = locationHook({ chosenCountry, chosenProvince });
    getCountries();

    watch(() => coverage.country_id.value, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        getUserWithCoverage({ country_id: newVal });
      }
    });

    const loading = ref(false);
    const message = ref('');
    const deleteConfirm = ref(false);
    const destroy = async () => {
      deleteConfirm.value = false;
      loading.value = true;
      const user_id = coverage.user_id.value;
      const response = await CoverageRepository.deleteUserCoverage(user_id);
      if (response.errors) {
        instance.value.$toast.error('No se elimino correctamente', {
          duration: 1500,
          dismissible: true,
          position: 'top',
        });
      } else {
        message.value = 'Eliminado correctamente';
        emit('removeResponse', { user_id });
        setTimeout(() => {
          message.value = '';
        }, 1500);
        setTimeout(() => {
          backRoute();
        }, 1500);
      }
      loading.value = false;
    };

    return {
      backRoute, coverage, userWithCoverage, countries, deleteConfirm, destroy, message, loading,
    };
  },
};
</script>

<style>

</style>
