'strict mode';

import $http from '@/services/Api/AxiosInstance';

const LocationRepository = {

  async countries() {
    let result = false;
    await $http.get('v1/location/countries')
      .then((res) => { result = res.data.countries; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status === 404) result = false;
      });
    return result;
  },
  async provincesByCountryId(params) {
    let result = false;
    await $http.get('v1/location/provincesByCountryId', { params })
      .then((res) => { result = res.data.provinces; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status === 404) result = false;
      });
    return result;
  },
  async localitiesByProvinceId(params) {
    let result = false;
    await $http.get('v1/location/localitiesByProvinceId', { params })
      .then((res) => { result = res.data.localities; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status === 404) result = false;
      });
    return result;
  },
  async areaCodes() {
    let result = false;
    await $http.get('v1/location/areaCodes')
      .then((res) => { result = res.data.areaCodes; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status === 404) result = false;
      });
    return result;
  },
  async postalCodes(params) {
    let result = false;
    try {
      result = await $http.get('v1/location/postalCodes', { params });
      result = result.data.postalCodes;
    } catch (error) {
      result = error.response;
    }

    return result;
  },

};

export default LocationRepository;
