<template>
  <div>
      <Base-modal :modalOverlay="true"  @exit="backRoute()" :modal="true" headingTitle="Editar cobertura">
        <template #content>
            <div class="mx-2 flex flex-col justify-center sm:justify-start flex-wrap">
                <!-- info -->
                <div v-if="!objEmpty(currentCoverage)" class="flex flex-col m-2">
                    <div class="border border-gray-200 px-2 py-2 rounded-tl-lg rounded-tr-lg">
                        Pais : {{currentCoverage.country.name}}
                    </div>
                    <div class="border border-gray-200 px-2 py-2">
                        Provincia : {{currentCoverage.province.name}}
                    </div>
                    <div class="border border-gray-200 px-2 py-2">
                        Localidad : {{currentCoverage.locality.name}}
                    </div>
                    <div class="border border-gray-200 px-2 py-2 rounded-br-lg rounded-bl-lg" >
                        Cp : {{currentCoverage.postalCode}}
                    </div>
                </div>
                <!-- type coverage -->
                <div class="m-2" style="max-width: 17.5rem;">
                    <BaseSelect
                    placeholder="Tipo de cobertura"
                    :options="COVERAGE_TYPE"
                    outline="blue"
                    v-model="coverage.coverage_type.value"
                    :error="coverage.coverage_type.error"
                    />
                </div>
                <div v-if="!coverage.coverage_type.value" class=" flex flex-row justify-start flex-wrap">
                    <!-- users -->
                    <div v-if="isUser" class="m-2">
                      <BaseSelect
                      placeholder="Usuario"
                      :options="users"
                      label="fullName"
                      outline="blue"
                      v-model="coverage.user.value"
                      :error="coverage.user.error"
                      :filters="['fullName','province.name','role.name']"
                      @error-handle="coverage.user.error = $event"
                      >
                      <template v-slot:itemList="{item}">
                        <div class="item-select ">

                          {{item.fullName}}
                          <strong class="text-sm">{{item.role.name}} {{item.province?.name?.toLowerCase()}}</strong>
                        </div>
                      </template>
                      </BaseSelect>
                    </div>

                    <!-- correo o terminal -->
                    <template v-if="!isUser">
                      <div class="flex flex-col my-4">
                          <div class="font-bold mx-1 mb-2 mx-2">
                            Debes ingresar la direccion y  coordenadas ingresando la direccion en google maps
                          </div>
                          <div class="m-2 max-w-sm">
                              <BaseInput
                              placeholder="Direccion"
                              outline="blue"
                              v-model:modelValue="coverage.address.value"
                              :error="coverage.address.error"
                              @error-handle="coverage.address.error = $event"
                              />
                          </div>
                          <div class="flex flex-row flex-wrap ">
                            <!-- lat -->
                            <div class="m-2">
                              <BaseInput
                              :placeholder="$t('Latitud')"
                              outline="blue"
                              v-model:modelValue="coverage.lat.value"
                              :error="coverage.lat.error"
                              @error-handle="coverage.lat.error = $event"
                              />
                            </div>
                            <!-- lng -->
                            <div class="m-2">
                              <BaseInput
                              :placeholder="$t('Longitud')"
                              outline="blue"
                              v-model:modelValue="coverage.lng.value"
                              :error="coverage.lng.error"
                              @error-handle="coverage.lng.error = $event"
                              />
                            </div>
                          </div>
                      </div>
                    </template>
                </div>
                <!-- postalCodeError -->
                <div v-if="postalCodeError" class="m-2 mx-2">
                <SimpleAlert color="red" border="border-b-4">
                    <template v-slot:text>
                    <p class="font-bold">{{postalCodeError}}</p>
                    </template>
                </SimpleAlert>
                </div>
                <!-- botones accion -->
                <div v-if="!message" class="m-2  flex flex-row flex-wrap">
                    <div class="mr-2 w-28">
                        <BaseButton :loading="loading" :disabled="loading || loading" @click="save()"   :text="$t('Guardar')" />
                    </div>
                    <div class="mx-1 w-28">
                        <BaseButton :loading="deleting" :disabled="deleting || loading" @click="deleteConfirm = true" background="bg-red-400"  textColor="text-white"  text="Eliminar" />
                    </div>
                </div>

                <!-- simpleAlert message -->
                <div v-if="message" class="m-2 mx-2">
                  <SimpleAlert color="green" border="border-b-4">
                    <template v-slot:text>
                      <p class="font-bold">{{message}}</p>
                    </template>
                  </SimpleAlert>
                </div>

            </div>
        </template>
      </Base-modal>
      <Base-modal
      :footer="true"
      :modalOverlay="true"
      @exit="deleteConfirm = false"
      :modal="deleteConfirm"
      :headingTitle="$t('Eliminar')">
      <template v-slot:content>
        <div class="m-2">
          ¿Estas seguro de eliminar esta cobertura?
        </div>
      </template>
      <template v-slot:footer>
        <div class="m-2 flex flex-row">
          <button @click="destroy()"  class="bg-green-400 rounded text-center p-1 px-2 m-1 "  ><span class="text-sm text-white font-bold">Si</span></button>
          <button @click="deleteConfirm = false" class="bg-red-400 rounded text-center p-1 px-2 m-1 "  ><span class="text-sm text-white font-bold">No</span></button>
        </div>
      </template>
      </Base-modal>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import {
  reactive, computed, ref,
} from 'vue';

// utilidades
import CoverageRepository from '@/repositories/Core/CoverageRepository';
import { usersInfoRepo } from '@/repositories/User/UserRepository';
import { COVERAGE_TYPE, COVERAGE_TYPES } from '@/services/Utils/Utils';
import { objEmpty } from '@/services/Utils/Objects';
import { USER_STATES } from '@/services/Utils/State';

export default {
  setup(_, { emit }) {
    const router = useRouter();
    const route = useRoute();

    const backRoute = () => {
      router.push({ name: 'Coverages' });
    };

    const coverage = reactive({
      coverage_type: {
        value: null,
        error: '',
      },
      user: {
        value: null,
        error: '',
      },
      address: {
        value: '',
        error: '',
      },
      lat: {
        value: '',
        error: '',
      },
      lng: {
        value: '',
        error: '',
      },

    });
    const currentCoverage = ref({});

    const postalCodeError = ref('');
    const errorHandle = (errors) => {
      if (errors) {
        Object.entries(errors).forEach((error) => {
          const [key, val] = error;
          if (coverage[key]) {
            const [message] = val;
            coverage[key].error = message;
          }
        });
        const { postalCode } = errors;
        if (postalCode) {
          const [message] = postalCode;
          postalCodeError.value = message;
        }
      } else {
        Object.entries(coverage).forEach((values) => {
          const [key] = values;
          if (coverage[key]) {
            coverage[key].error = '';
          }
        });
        postalCodeError.value = '';
      }
    };

    const isUser = computed(() => {
      const { value } = coverage.coverage_type;
      if (value && (COVERAGE_TYPES.COLLECTOR === value || COVERAGE_TYPES.COMMERCE === value)) {
        return true;
      }
      return false;
    });

    const selectedPostalCodes = ref([]);

    const users = ref([]);
    const getUsers = async (params) => {
      const response = await usersInfoRepo(params);
      users.value = response;
    };

    const loading = ref(false);
    const deleting = ref(false);
    const message = ref('');

    const save = async () => {
      loading.value = true;
      const object = {
        id: currentCoverage.value,
        postalCode: selectedPostalCodes.value,
        country: currentCoverage.value.country,
        province: currentCoverage.value.province,
        locality: currentCoverage.value.locality,
        coverage_type: coverage.coverage_type.value,
      };

      if (isUser.value) {
        object.user = coverage.user.value;
      } else {
        object.address = coverage.address.value;
        object.lat = coverage.lat.value;
        object.lng = coverage.lng.value;
      }

      const response = await CoverageRepository.save(object);
      if (response.errors) {
        errorHandle(response.errors);
      } else {
        if (response.length > 0) {
          emit('updateResponse', response);
          setTimeout(() => {
            backRoute();
          }, 1500);
        }

        message.value = 'Guardado correctamente';
        setTimeout(() => {
          message.value = '';
        }, 2500);
      }

      loading.value = false;
    };

    const deleteConfirm = ref(false);
    const destroy = async () => {
      deleteConfirm.value = false;
      deleting.value = true;
      const { id } = currentCoverage.value;
      const response = await CoverageRepository.delete(id);
      deleting.value = false;
      if (response.errors) {
        postalCodeError.value = 'No se realizo correctamente';
        setTimeout(() => {
          postalCodeError.value = '';
        }, 2000);
      } else {
        emit('removeResponse', { id });
        message.value = 'Eliminado correctamente';
        setTimeout(() => {
          message.value = '';
        }, 1500);
        setTimeout(() => {
          backRoute();
        }, 1500);
      }
    };

    const getCoverage = async () => {
      const response = await CoverageRepository.coverage(route.params.id);
      const { coverage: coverageResponse } = response;
      if (coverageResponse) {
        const {
          country, coverage_type_id, user_id, lat, lng, address, postalCode,
        } = coverageResponse;
        coverage.coverage_type.value = coverage_type_id;

        if (user_id) {
          coverage.user.value = user_id;
        }

        coverage.lat.value = lat;
        coverage.lng.value = lng;
        coverage.address.value = address;
        currentCoverage.value = coverageResponse;
        selectedPostalCodes.value.push(postalCode);
        getUsers({ user_state: USER_STATES.ACTIVO, country: country.id, operative: true });
      }
    };
    getCoverage();

    return {
      backRoute, COVERAGE_TYPE, coverage, objEmpty, selectedPostalCodes, users, isUser, loading, deleting, save, destroy, postalCodeError, message, currentCoverage, deleteConfirm,
    };
  },
};
</script>

<style>

</style>
