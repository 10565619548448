'strict mode';

import $http from '@/services/Api/AxiosInstance';

const CoverageRepository = {

  async coverages(params = {}) {
    let result = {};
    try {
      const response = await $http.get('v1/coverages', { params });
      result = response.data;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async coverage(id) {
    let result = {};
    try {
      const response = await $http.get(`v1/coverage/${id}`);
      result = response.data;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async emptyCoverages(params = {}) {
    let result = {};
    try {
      const response = await $http.get('v1/coverages/empty', { params });
      result = response.data;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async userWithCoverage(params = {}) {
    let result = {};
    try {
      const response = await $http.get('v1/coverages/users', { params });
      result = response.data.users;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async cleanCp(params = {}) {
    let result = {};
    try {
      const response = await $http.get('v1/coverages/cleanCp', { params });
      result = response.data.postalCodes;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async save(params) {
    let result = {};
    try {
      const response = await $http.post('v1/coverages', params);
      result = response.data.coverages;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async transfer(params) {
    let result = {};
    try {
      const response = await $http.post('v1/coverages/transfer', params);
      result = response.data.coverages;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async copy(params) {
    let result = {};
    try {
      const response = await $http.post('v1/coverages/copy', params);
      result = response.data.coverages;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async delete(id) {
    let result = false;
    try {
      const response = await $http.delete(`v1/coverage/${id}`);
      result = response.data;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
  async deleteUserCoverage(user_id) {
    let result = false;
    try {
      const response = await $http.delete(`v1/coverage/user/${user_id}`);
      result = response.data;
    } catch (err) {
      result = err.response.data;
    }
    return result;
  },
};

export default CoverageRepository;
