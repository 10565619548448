<template>
  <div>
    <Base-modal
      :modalOverlay="true"
      :modal="true"
      type="full-screen"
      :headingTitle="$t('Enviar')"
      @exit="exit()"
    >
      <template #content>
        <div class="main-coupon">
          <SendMessage
          :title="$t('Enviar recordatorio/cupon')"
          :loading="loading"
          :email="coupon.email.value"
          :prefix="coupon.prefix.value"
          :phoneNumber="coupon.phoneNumber.value"
          @sendEmail="sendEmail($event)"
          @sendWhatsapp="sendWhatsapp($event)"
          @exit="exit()"
          >
          <template v-slot:message>
            <div class="mb-2 my-4" v-if="message">
              <SimpleAlert :color="messageColor" border="border-b-4">
                <template v-slot:text>
                  <p class="font-bold"> {{message}}</p>
                </template>
              </SimpleAlert>
            </div>
          </template>
          </SendMessage>
        </div>
      </template>
    </Base-modal>
  </div>
</template>

<script>
import {
  ref, reactive, onMounted, computed,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SendMessage from '@/components/Form/SendMessage.vue';

// utilididaes
import { AGREEMENT_TYPES } from '@/services/Utils/Utils';
import { agreementReminderRepo } from '@/repositories/Core/AgreementRepository';
import coreHook from '@/hooks/core/coreHook';
import baseUrl from '@/services/Api/BaseUrl';
import { useI18n } from 'vue-i18n';


export default {
  components: {
    SendMessage,
  },
  props: {
    title: {
      type: String,
      default: 'Enviar coupon',
    },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { isEditMode } = coreHook();

    const loading = ref(false);
    // const { title } = toRefs(props);
    const agreement_id = ref(null);
    const message = ref('');
    const messageColor = ref('');
    // tine que auqnusea tocar alguna vez para enviar el el cupon
    const tryTouch = ref(false);

    const coupon = reactive({
      email: {
        value: '',
        error: '',
      },
      phoneNumber: {
        value: '',
        error: '',
      },
      prefix: {
        value: '',
        error: '',
      },
    });
    const dataComplete = computed(() => {
      if (coupon.email.value && coupon.phoneNumber.value && coupon.prefix.value && agreement_id.value) {
        return true;
      }
      return false;
    });

    const sendEmail = async (data) => {
      loading.value = true;
      const send = {
        email: data.email,
        type: 2,
        id: agreement_id.value,
      };
      const response = await agreementReminderRepo(send);
      if (response.errors) {
        message.value = 'No fue enviado correctamente';
        messageColor.value = 'red';
      } else {
        tryTouch.value = true;
        message.value = 'Enviado correctamente';
        messageColor.value = 'green';
      }
      setTimeout(() => {
        message.value = '';
        messageColor.value = 'r';
      }, 5000);
      loading.value = false;
    };

    const sendWhatsapp = async (data) => {
      tryTouch.value = true;
      const realPhoneNumber = data.phoneNumber;
      const send = {
        type: 1,
        id: agreement_id.value,
      };
      const response = await agreementReminderRepo(send);
      const {
        id, identificacion, equipment, agreement_type_id, street, day, schedule, branch, user,
      } = response.reminder;
      const { enterprise: { name: enterpriseAlias }, nombre_cliente } = equipment;

      let urlencodedtext;
      if (agreement_type_id === AGREEMENT_TYPES.VISIT) {
        const { name: nameAlias } = schedule;
        urlencodedtext = t('whatsapp.visit', {
          nombre_cliente,
          identificacion,
          enterpriseAlias,
          street,
          day,
          nameAlias
        });
      } else if (agreement_type_id === AGREEMENT_TYPES.COMMERCE) {
        const {
          lat: latUser, lng: lngUser, street: streetUser, streetHeight: streetHeightUser, locality: localityUser, province: provinceUser,
        } = user;

        const urlGeocoding = `https://www.google.com/maps?q=${latUser},${lngUser}`;
        urlencodedtext = t('whatsapp.commerce', {
          nombre_cliente,
          identificacion,
          enterpriseAlias,
          streetUser,
          streetHeightUser,
          localityUser: localityUser.name,
          provinceUser: provinceUser.name,
          urlGeocoding
        });
      } else if (agreement_type_id === AGREEMENT_TYPES.CORREO || agreement_type_id === AGREEMENT_TYPES.TERMINAL) {
        const {
          lat: latBranch, lng: lngBranch, address: addressBranch, locality: localityBranch, province: provinceBranch,
        } = branch;
        const urlGeocoding = `https://www.google.com/maps?q=${latBranch},${lngBranch}`;
        const nameBranch = agreement_type_id === AGREEMENT_TYPES.CORREO ? 'el Correo' : 'la terminal';
        const urlCupon = `${baseUrl.apiDevuelvoya}/v1/coupon/${id}/${identificacion}`;
        urlencodedtext = t('whatsapp.branch', {
          nombre_cliente,
          identificacion,
          enterpriseAlias,
          nameBranch,
          addressBranch,
          localityBranch: localityBranch.name,
          provinceBranch: provinceBranch.name,
          urlCupon,
          urlGeocoding
        });
      }

      window.open(`https://api.whatsapp.com/send?phone=${realPhoneNumber}&text=${encodeURIComponent(urlencodedtext)}`, '_blank');
    };

    const exit = () => {
      if (tryTouch.value) {
        if (!isEditMode.value) {
          router.push({ name: 'agreeCustomer' });
        } else {
          router.push({ name: 'agreements' });
        }
      } else {
        message.value = 'Debes enviar el cupon/recordatorio al cliente';
      }
    };

    onMounted(() => {
      const {
        email, prefix, phoneNumber, agreement_id: agreement_idAlias,
      } = route.query;
      if (email) {
        coupon.email.value = email;
      }

      coupon.phoneNumber.value = phoneNumber;
      coupon.prefix.value = prefix;
      agreement_id.value = agreement_idAlias;
    });

    return {
      AGREEMENT_TYPES, loading, route, coupon, sendEmail, messageColor, message, exit, sendWhatsapp, tryTouch, dataComplete,
    };
  },
};
</script>

<style>
.main-coupon{

    display: flex;
    align-items: center;

}

@media (min-width:720px){

    .main-coupon{
        height:100%;
    justify-content: center;
    }

}
.send-coupon{
    height: 70vh;
}

</style>
